import { PatentTrademarkTopicModel } from 'src/app/core/laboratory/_models/option.model';
import {
  PaymentProjectModel,
  ReviewProjectModel,
  SubmissionLabModel,
} from '../_models/task.model';
import { UserModel } from '../../authen';
export class PatentsTrademarkModel {
  id: number;
  operationNo: string;

  paymentProjects: PaymentProjectModel[];
  no: string;
  patentTrademarkTopicId: number;
  patentTrademarkTopic: PatentTrademarkTopicModel;
  modelName: string;
  otherDetails: string;
  reason: string;
  topicOthers: string;

  detail: string;
  patent: string;
  attachFileImage: string;
  attachFile: string;

  file1: File;
  attachFile1: string;
  file2: File;
  attachFile2: string;
  file3: File;
  attachFile3: string;
  file4: File;
  attachFile4: string;
  file5: File;
  attachFile5: string;

  creationDateTime: string;
  lastUpdateDateTime: string;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isStart: boolean;
  userStartId: number;
  userStart: UserModel;
  StartDateTime: string;
  isConfirm: boolean;
  isApplyReview: boolean;
  applyReviewDateTime: string;
  isInProcessRecord: boolean;
  inProcessRecordDateTime: string;
  isTestCertificate: boolean;
  testCertificateDateTime: string;
  isTaskSubmission: boolean;
  taskSubmissionDateTime: string;
  isPayment: boolean;
  paymentDateTime: string;

  patentTrademarkOldId: number;

  userApplyReviewId: number;
  userApplyReview: UserModel;
  userInProcessRecordId: number;
  userInProcessRecord: UserModel;
  userTestCertificateId: number;
  userTestCertificate: UserModel;
  userTaskSubmissionId: number;
  userTaskSubmission: UserModel;
  userPaymentId: number;
  userPayment: UserModel;

  reviewProject: ReviewProjectModel;
  submission: SubmissionLabModel;

  propertiesTotal: number;
  propertiesReturned: number;
  propertiesDiscard: number;
  propertiesFReturned: number;
  propertiesFDiscard: number;

  file_arr: Array<File> = [];

  clear() {
    this.id = 0;
    this.operationNo = '';
    this.no = '';
    this.patentTrademarkTopicId = 0;
    this.patentTrademarkTopic = new PatentTrademarkTopicModel();
    this.modelName = '';
    this.otherDetails = '';
    this.topicOthers = '';

    this.detail = '';
    this.patent = '';
    this.attachFileImage = '';
    this.attachFile = '';

    this.file1 = null;
    this.attachFile1 = '';
    this.file2 = null;
    this.attachFile2 = '';
    this.file3 = null;
    this.attachFile3 = '';
    this.file4 = null;
    this.attachFile4 = '';
    this.file5 = null;
    this.attachFile5 = '';

    this.creationDateTime = '2021-01-01';
    this.lastUpdateDateTime = '2021-01-01';

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isConfirm = false;
    this.isApplyReview = false;
    this.applyReviewDateTime = '';
    this.isInProcessRecord = false;
    this.inProcessRecordDateTime = '';
    this.isTestCertificate = false;
    this.testCertificateDateTime = '';
    this.isTaskSubmission = false;
    this.taskSubmissionDateTime = '';
    this.isPayment = false;
    this.paymentDateTime = '';

    this.patentTrademarkOldId = 0;

    this.userApplyReviewId = 0;
    this.userApplyReview = new UserModel();
    this.userInProcessRecordId = 0;
    this.userInProcessRecord = new UserModel();
    this.userTestCertificateId = 0;
    this.userTestCertificate = new UserModel();
    this.userTaskSubmissionId = 0;
    this.userTaskSubmission = new UserModel();
    this.userPaymentId = 0;
    this.userPayment = new UserModel();

    this.reviewProject = new ReviewProjectModel();
    this.submission = new SubmissionLabModel();

    this.propertiesTotal = null;
    this.propertiesReturned = null;
    this.propertiesDiscard = null;
    this.propertiesFReturned = null;
    this.propertiesFDiscard = null;
  }
}
