import { ProjectManagementModel } from '../_models/project-management.model';
import {
  PatentsTrademarkModel,
  RapidPrototypeModel,
  DesignSimulationModel,
  OtherServiceModel,
  ReviewTestMethodTopicModel,
} from 'src/app/core/laboratory';
import { LaboratoryModel, ProductRegistraionSupportModel } from '..';
import { UserModel } from '../../authen';

export class TaskModel {
  id: number;
  operationNo: number;
  no: string;
  servicesName: string;
  servicesId: number;
  topic: string;
  modelName: string;
  status: string;
  payment: string;
  customer: string;
  company: string;
  operator: string;
  lastActivity: string;
  isApplyReview: boolean;
  isPayment: boolean;
  isInProcessRecord: boolean;
  isTestCertificate: boolean;
  isTaskSubmission: boolean;
  userCreate: UserModel;
  userEdit: UserModel;
  creationDateTime: string;
  lastUpdateDateTime: string;
  testStandardId: number;
  testStandardOperatorNo: number;
  taskTotal: number;
  reviewCustomerPropertiyId: number;
  plannedDate: string;
  reportLang: boolean;
  duration: number;
  estEndDate: string;
  startDate: string;
  endDate: string;
  planStatus: boolean;

  // clear() {
  //   this.id = 0;
  //   this.operationNo = null;
  //   this.servicesName = '';
  //   this.servicesId = 0;
  //   this.topic = '';
  //   this.modelName = '';
  //   this.status = '';
  //   this.payment = '';
  //   this.customer = '';
  //   this.company = '';
  //   this.operator = '';
  //   this.lastActivity = '';
  //   this.no = '';
  //   this.isApplyReview = false;
  //   this.isPayment = false;
  //   this.isInProcessRecord = false;
  //   this.isTestCertificate = false;
  //   this.isTaskSubmission = false;
  //   // this.userCreate = new UserModel();
  //   // this.userEdit = new UserModel();
  //   this.creationDateTime = '2021-01-01';
  //   this.lastUpdateDateTime = '2021-01-01';
  //   this.reviewCustomerPropertiyId = 0;
  //   this.testStandardId = 0;
  //   this.plannedDate = '2021-01-01';
  //   this.duration = null;
  //   this.estEndDate = '2021-01-01';
  //   this.startDate = '2021-01-01';
  //   this.endDate = '2021-01-01';
  //   this.planStatus = false;
  // }
}

export class ReviewProjectModel {
  id: number;
  sampleReceivedDate: string;
  accreditation: number;
  diameter: number;
  width: number;
  length: number;
  height: number;
  weight: number;
  model1: string;
  model2: string;
  model3: string;
  model4: string;
  model5: string;
  reviewTestMethodTopicId: number;
  reviewTestMethodTopic: ReviewTestMethodTopicModel;
  integrityTest: boolean;
  integrityTestDetail: string;
  operatorReadiness: boolean;
  operatorReadinessDetail: string;
  toolReadiness: boolean;
  toolReadinessDetail: string;
  environmentReadiness: boolean;
  environmentReadinessDetail: string;
  operatorId: number;
  operator: UserModel;
  reviewSummary: string;
  details: string;
  plannerDate: string;
  durationDay: number;
  reviewCustomerProperties: Array<ReviewCustomerPropertieModel> = [];
  preComment: string;
  preResult: number;
  preResultDetail: string;
  postComment: string;
  postResult: number;
  postResultDetail: string;
  note: string;
  laboratoryId: number;
  laboratory: LaboratoryModel;
  rapidPrototyId: number;
  rapidPrototy: RapidPrototypeModel;
  designSimulationId: number;
  designSimulation: DesignSimulationModel;
  patentTrademarkId: number;
  patentTrademark: PatentsTrademarkModel;
  productRegistrationSupportId: number;
  productRegistrationSupport: ProductRegistraionSupportModel;
  otherServiceId: number;
  otherService: OtherServiceModel;
  projectManagementId: number;
  projectManagement: ProjectManagementModel;
  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;
  reviewTestMethodTopicDetail: string;
  creationDateTime: string;
  lastUpdateDateTime: string;

  clear() {
    this.id = 0;
    this.sampleReceivedDate = '2021-01-01';
    this.accreditation = 0;
    this.diameter = 0;
    this.width = 0;
    this.length = 0;
    this.height = 0;
    this.weight = 0;
    this.model1 = '';
    this.model2 = '';
    this.model3 = '';
    this.model4 = '';
    this.model5 = '';
    this.reviewTestMethodTopicId = 0;
    this.reviewTestMethodTopicDetail = '';
    this.integrityTest = false;
    this.integrityTestDetail = '';
    this.operatorReadiness = false;
    this.operatorReadinessDetail = '';
    this.toolReadiness = false;
    this.toolReadinessDetail = '';
    this.environmentReadiness = false;
    this.environmentReadinessDetail = '';
    this.operatorId = 0;
    this.reviewSummary = '';
    this.details = '';
    this.plannerDate = '2021-01-01';
    this.durationDay = 0;
    this.reviewCustomerProperties = [];
    this.preComment = '';
    this.preResult = 0;
    this.preResultDetail = '';
    this.postComment = '';
    this.postResult = 0;
    this.postResultDetail = '';
    this.note = '';
    this.laboratoryId = 0;
    this.rapidPrototyId = 0;
    this.designSimulationId = 0;
    this.patentTrademarkId = 0;
    this.productRegistrationSupportId = 0;
    this.otherServiceId = 0;
    this.projectManagementId = 0;
    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();
    this.creationDateTime = '';
    this.lastUpdateDateTime = '';
  }
}

export class ReviewCustomerPropertieModel {
  id: number;
  model: string;
  quantity: number;
  productCode: string;
  doNo: string;
  reviewProjectId: number;
  customerDate: string;

  clear() {
    this.id = 0;
    this.model = '';
    this.quantity = 0;
    this.productCode = '';
    this.doNo = '';
    this.reviewProjectId = 0;
    this.customerDate = '2021-01-01';
  }
}

export class TestCertificateModel {
  id: number;
  certNo: string;
  certTest: string;
  certTestDate: string;
  certDetail: string;
  certMethod: string;
  certCondition: string;
  certResult: string;
  testName: string;
  testNameDate: string;
  testNameApproved: boolean;
  certifierName: string;
  inspectorName: string;
  inspectorNameDate: string;
  inspectorNameApproved: boolean;
  inspectorNamePosition: string;
  page: string;
  pageOf: string;
  serviceApplicant: string;
  pickupDateTime: string;
  testDateTime: string;
  reportDateTime: string;
  userCreateId: number;
  userEditId: number;
  laboratoryId: number;
  testNameDateString: string;
  certTestDateString: string;
  inspectorNameDateString: string;

  clear() {
    this.id = 0;
    this.certNo = null;
    this.certTest = null;
    this.certDetail = null;
    this.certMethod = null;
    this.certCondition = null;
    this.certResult = null;
    this.testName = null;
    this.certifierName = null;
    this.inspectorName = null;
    this.inspectorNamePosition = 'หัวหน้าห้องปฏิบัติการ';
    this.page = null;
    this.pageOf = null;
    this.serviceApplicant = null;
    this.pickupDateTime = '';
    this.testDateTime = '';
    this.reportDateTime = '';
    this.userCreateId = 0;
    this.userEditId = 0;
    this.laboratoryId = 0;
  }
}

export class SubmissionLabModel {
  id: number;
  resultTransmission: string;
  resultRecipient: string;
  resultRecipientDate: string;
  reportSubmission: string;
  reportRecipient: string;
  reportRecipientDate: string;
  userCreateId: number;
  pickupDateTime: string;
  testDateTime: string;
  reportDateTime: string;
  userEditId: number;
  laboratoryId: number;
  rapidPrototyId: number;
  designSimulationId: number;
  patentTrademarkId: number;
  productRegistrationSupportId: number;
  otherServiceId: number;
  projectManagementId: number;
  file1: File;
  attachFile1: string;
  file2: File;
  attachFile2: string;
  test1Id: number;
  testName1: number;
  testDate1: string;
  isApprove1: boolean;
  test2Id: number;
  testName2: number;
  testDate2: string;
  isApprove2: boolean;

  clear() {
    this.id = 0;
    this.resultTransmission = '';
    this.resultRecipient = '';
    this.resultRecipientDate = '';
    this.reportSubmission = '';
    this.reportRecipient = '';
    this.reportRecipientDate = '';
    this.userCreateId = 0;
    this.pickupDateTime = '';
    this.testDateTime = '';
    this.reportDateTime = '';
    this.userEditId = 0;
    this.laboratoryId = 0;
    this.rapidPrototyId = 0;
    this.designSimulationId = 0;
    this.patentTrademarkId = 0;
    this.productRegistrationSupportId = 0;
    this.otherServiceId = 0;
    this.projectManagementId = 0;
  }
}

export class ReviewCustomerPropertyModel {
  id: number;
  model: string;
  quantity: number;
  productCode: string;
  doNo: string;
  reviewProjectId: number;
  customerDate: string;
  reviewProject: ReviewProjectModel;
  arrangement: number;
  grnNo: string;
  remark: string;
  customerPropertieyLastUpdateDateTime: string;
  arrangementDate: string;
  arrangementUserId: number;
  arrangementUser: UserModel;
  isFinish: boolean;
  serviceDate: string;
  status: string;
  customerName: string;
  customerCompany: string;
  url: string;
  no: string;
  creationDateTime: string;
  file: File;
  attachFile: string;
  serviceName: string;

  clear() {
    this.id = 0;
    this.model = '';
    this.quantity = null;
    this.productCode = '';
    this.doNo = '';
    this.reviewProjectId = 0;
    this.reviewProject = new ReviewProjectModel();
    this.customerDate = '';
    this.arrangement = 0;
    this.grnNo = '';
    this.remark = '';
    this.customerPropertieyLastUpdateDateTime = '';
    this.arrangementDate = '';
    this.arrangementUserId = 0;
    this.arrangementUser = new UserModel();
    this.isFinish = false;
  }
}

export class PaymentProjectModel {
  id: number;
  to: string;
  companyName: string;
  companyAddress: string;
  phoneNumber: string;
  email: string;
  subject: string;
  subtotal: number;
  discountPer: number;
  discount: number;
  total: number;
  vat: number;
  grandTotal: number;
  totalQuantity: number;
  termOfPayment: string;
  priceValidity: number;
  remark: string;
  paymentDetails: Array<PaymentDetailModel> = [];
  proposedId: number;
  authorizedId: number;
  quotationNo: string;
  recipient: string;
  recipientDateTime: string;
  recipientAttachFile1: string;
  recipientAttachFile2: string;
  recipientAttachFile3: string;
  recipientAttachFile4: string;
  recipientAttachFile5: string;
  isConfirm: boolean;
  confirmDateTime: string;
  recipientSaveDateTime: string;
  userSaveRecipientId: number;
  userConfirmRecipientId: number;
  method: number;
  methodNo: string;
  receviver: string;
  methodAttachFile2: string;
  methodAttachFile1: string;
  methodAttachFile3: string;
  methodAttachFile4: string;
  methodAttachFile5: string;
  invoiceNo: string;
  otherDetail: string;
  file1: File;
  file2: File;
  file3: File;
  file4: File;
  file5: File;
  isFinish: boolean;
  finishDateTime: string;
  methodSaveDateTime: string;
  userSaveMethodId: number;
  userFinishMethodId: number;
  laboratoryId: number;
  laboratory: LaboratoryModel;
  rapidPrototyId: number;
  rapidPrototy: RapidPrototypeModel;
  designSimulationId: number;
  designSimulation: DesignSimulationModel;
  patentTrademarkId: number;
  patentTrademark: PatentsTrademarkModel;
  productRegistrationSupportId: number;
  productRegistrationSupport: ProductRegistraionSupportModel;
  otherServiceId: number;
  otherService: OtherServiceModel;
  projectManagementId: number;
  projectManagement: ProjectManagementModel;
  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;
  creationDateTime: string;
  isPrint: boolean;
  printDateTime: string;
  lastUpdateDateTime: string;
  status: string;
  topic: string;

  file_arr: Array<File> = [];

  clear() {
    this.id = 0;
    this.to = '';
    this.companyName = '';
    this.companyAddress = '';
    this.phoneNumber = '';
    this.email = '';
    this.subject = '';
    this.subtotal = null;
    this.discountPer = null;
    this.discount = null;
    this.total = null;
    this.vat = null;
    this.grandTotal = null;
    this.totalQuantity = null;
    this.termOfPayment = '';
    this.priceValidity = null;
    this.remark = '';
    this.paymentDetails = [];
    this.proposedId = 0;
    this.authorizedId = 0;
    this.quotationNo = '';
    this.recipient = '';
    this.recipientDateTime = '';
    this.recipientAttachFile1 = '';
    this.recipientAttachFile2 = '';
    this.recipientAttachFile3 = '';
    this.recipientAttachFile4 = '';
    this.recipientAttachFile5 = '';
    this.isConfirm = false;
    this.confirmDateTime = '';
    this.recipientSaveDateTime = '';
    this.userSaveRecipientId = 0;
    this.userConfirmRecipientId = 0;
    this.method = null;
    this.methodNo = '';
    this.receviver = '';
    this.methodAttachFile2 = '';
    this.methodAttachFile1 = '';
    this.methodAttachFile3 = '';
    this.methodAttachFile4 = '';
    this.methodAttachFile5 = '';
    this.invoiceNo = '';
    this.otherDetail = '';
    this.file1 = null;
    this.file2 = null;
    this.file3 = null;
    this.file4 = null;
    this.file5 = null;
    this.isFinish = false;
    this.finishDateTime = '';
    this.methodSaveDateTime = '';
    this.userSaveMethodId = 0;
    this.userFinishMethodId = 0;
    this.laboratoryId = 0;
    this.laboratory = new LaboratoryModel();
    this.rapidPrototyId = 0;
    this.rapidPrototy = new RapidPrototypeModel();
    this.designSimulationId = 0;
    this.designSimulation = new DesignSimulationModel();
    this.patentTrademarkId = 0;
    this.patentTrademark = new PatentsTrademarkModel();
    this.productRegistrationSupportId = 0;
    this.productRegistrationSupport = new ProductRegistraionSupportModel();
    this.otherServiceId = 0;
    this.otherService = new OtherServiceModel();
    this.projectManagementId = 0;
    this.projectManagement = new ProjectManagementModel();
    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();
    this.creationDateTime = '2021-01-01';
    this.lastUpdateDateTime = '2021-01-01';
  }
}

export class PaymentDetailModel {
  id: number;
  no: number;
  quantity: number;
  unit: string;
  description: string;
  operationNo: string;
  unitPrice: number;
  amount: number;
  paymentProjectId: number;

  clear() {
    this.id = 0;
    this.no = null;
    this.quantity = null;
    this.unit = '';
    this.description = '';
    this.operationNo = '';
    this.unitPrice = null;
    this.amount = null;
    this.paymentProjectId = 0;
  }
}
export class SearchModel {
  search: string;
  startDate: string;
  endDate: string;
}
