import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
// Model
import { AuthenModel, UserModel } from '../index';
import { JwtHelperService } from '@auth0/angular-jwt';

const API_AUTH_URL = environment.API_BASE_URL + '/api/User';

@Injectable({
  providedIn: 'root',
})
export class AuthenService {
  constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {}

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  signIn(user: AuthenModel): Observable<UserModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _params = {
      username: user.email,
      password: user.password,
    };

    const _url = API_AUTH_URL + `/authenticate`;
    return this.http.post<UserModel>(_url, _params, { headers: httpHeaders });
  }

  signUp(user: UserModel): Observable<UserModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _params = {
      id: user.id,
      fName: user.fName,
      lName: user.lName,
      email: user.email,
      username: user.username,
      empoyleeNo: user.empoyleeNo,
      phoneNumber: user.phoneNumber,
      company: user.company,
      companyAddress: user.companyAddress,
      password: user.password,
      role: user.role,
      userUserGroups: user.userUserGroups,
      creationDateTime: user.creationDateTime,
      lastUpdateDateTime: user.lastUpdateDateTime,
    };

    const _url = API_AUTH_URL;
    return this.http.post<UserModel>(_url, _params, { headers: httpHeaders });
  }

  logout() {
    localStorage.removeItem(environment.authTokenKey);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem(environment.authTokenKey);
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
}
