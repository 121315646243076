<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Authorization Code</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <label class="textColor label-inline topic-to-left">Authorization Code<br />
        <span class="nav-thai-font"> รหัสผ่าน </span>
      </label>
      <input [type]="isHidePass ? 'password': 'text'" (keydown.enter)="onSubmit()" minlength="6" required class="form-control mb-4" placeholder="Authorization Code" [(ngModel)]="isInputCode" (ngModelChange)="onChangeInput()" />
      <i class="fa icon cursor__point" style="color: black;" [ngClass]="isHidePass ? 'fa-eye' : 'fa-eye-slash'" (click)="isShow()"></i>
      <small *ngIf="isNotPass" class="text-danger">The code you entered is incorrect.</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="dismissModal('cancel click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSubmit()">Confirm</button>
</div>
