import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { OtherServiceModel } from '../index';

const API_SERVICE_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class OtherServiceService {
  constructor(private http: HttpClient) {}

  // READ
  getAllOtherServices(): Observable<OtherServiceModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/OtherService`;
    return this.http.get<OtherServiceModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getOtherServiceById(id: number): Observable<OtherServiceModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/OtherService/${id}`;
    return this.http.get<OtherServiceModel>(_url, { headers: httpHeaders });
  }

  // READ
  getOtherServiceOldById(id: number): Observable<OtherServiceModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/OtherServiceOld/${id}`;
    return this.http.get<OtherServiceModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createOtherService(model: OtherServiceModel): Observable<OtherServiceModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('othersTopic', model.othersTopic);
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/OtherService`;
    return this.http.post<OtherServiceModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateOtherService(model: OtherServiceModel): Observable<OtherServiceModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('othersTopic', model.othersTopic);
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    // formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/OtherService`;
    return this.http.put<OtherServiceModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteOtherService(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_SERVICE_URL + `/OtherService/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // READ
  updateInProcessRecord(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url =
      API_SERVICE_URL + `/InProcessRecord/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }
}
