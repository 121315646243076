<div class="background-blue gap-between-button ">
  <div class="marginContainer">
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-color-white">Planning<br />
          <p class="text-color-white margin-text-sub font-size-22 ">การวางแผน</p>
        </h1>
      </div>
      <div class="col text-align-right margin-top-28px">
        <h5 class="text-color-white font-size-15rem">Task Plan<br /><span class="nav-thai-font text-color-white font-size-18">รายการแผนงาน</span></h5>
      </div>
    </div>
  </div>
</div>
<div class="marginContainer2">
  <div class="outerDiv row justify-content-center mt-3 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Request No.</th>
          <th scope="col">Operation No.</th>
          <th scope="col">Operator</th>
          <th scope="col">Customer</th>
          <th scope="col" style="
    width: 200px !important;
">Company
          </th>
          <th scope="col">Planned Date
          </th>
          <th scope="col">Duration
          </th>
          <th scope="col">Est. End Date</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col" style="width: 100px !important;">Plan Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of servicesSearch">
          <th scope="row">
            <a class="cursor__point">{{ element.no }}</a>
            <!-- <p class="color-blue">{{ element.no }}</p> -->
          </th>
          <td>{{ element.operationNo }}</td>
          <td>{{ element.operator }}</td>
          <td>{{ element.customer }}</td>
          <td>{{ element.company }}</td>
          <td>{{ element.plannedDate | date:'dd-MM-yyyy' }}</td>
          <td>{{ element.duration }}</td>
          <td>{{ element.estEndDate | date:'dd-MM-yyyy' }}</td>
          <td>{{ element.startDate | date:'dd-MM-yyyy' }}</td>
          <td>{{ element.endDate | date:'dd-MM-yyyy' }}</td>
          <td [innerHTML]="element.planStatus"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
