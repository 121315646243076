<div>
  <div class="background-blue">
    <div class="marginContainer">
      <div class="row">
        <div class="col-sm-9">
          <h1 class="text-color-white">
            Task<br />
            <p class="text-color-white margin-text-sub font-size-22">งาน</p>
          </h1>
        </div>
        <div class="col text-align-right margin-top-28px">
          <h5 class="text-color-white font-size-15rem">
            Customer Task Log<br /><span class="nav-thai-font text-color-white font-size-18">รายการงานลูกค้า</span>
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="marginContainer">
    <div class="outerDiv row mt-3 table-responsive-sm">
      <div class="form-group form-inline">
        <span style="padding-left: 10px; padding-right: 5px;">ค้นหา</span>
        <div class="form-group hidden">
          <div class="input-group">
            <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="4" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
            <ng-template #t let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
        <span style="padding-left: 10px; padding-right: 5px;">ถึง</span>
        <div class="form-group ml-2">
          <div class="input-group">
            <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-inline">
        <span style="padding-left: 10px; padding-right: 5px;">คำค้น</span>
        <input (change)="getDataTable()" class="form-control ml-2" type="text" [(ngModel)]="filter" />

        <span class="input-group-text border-0 cursor__point" id="search-addon" style="height: 38px" (click)="getDataTable()">
          <i class="fas fa-search"></i>
        </span>

      </div>


      <table class="table table-striped">
        <thead class="sticky-top">
          <tr>
            <th scope="col" (sort)="onSort($event)" sortable="no">
              Request No.<br /><span class="nav-thai-font text-data" style="font-weight: 500">หมายเลขขอรับบริการ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="creationDateTime">
              Request Date/Time<br /><span class="nav-thai-font text-data" style="font-weight: 500">เวลา และวันที่ขอรับบริการ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="servicesName">
              Services<br /><span class="nav-thai-font text-data" style="font-weight: 500">บริการ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="topic">
              Test Standard/Topic <br /><span class="nav-thai-font text-data" style="font-weight: 500">มาตรฐานทดสอบ/หัวข้อ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="modelName">
              Model/Task Name<br /><span class="nav-thai-font text-data" style="font-weight: 500">แบบรุ่น/ชื่องาน</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="customer">
              Customer<br /><span class="nav-thai-font text-data" style="font-weight: 500">ชื่อลูกค้า</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="company">
              Company<br /><span class="nav-thai-font text-data" style="font-weight: 500">บริษัท</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="operationNo">
              Operation No.<br /><span class="nav-thai-font text-data" style="font-weight: 500">หมายเลขดำเนินการ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="customer">
              Operator<br /><span class="nav-thai-font text-data" style="font-weight: 500">ผู้ดำเนินการ</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="status">
              Task Status<br /><span class="nav-thai-font text-data" style="font-weight: 500">สถานะ</span>
            </th>
            <th scope="col">
              Activity<br /><span class="nav-thai-font text-data" style="font-weight: 500">งาน</span>
            </th>
            <th scope="col" (sort)="onSort($event)" sortable="lastUpdateDateTime">
              Last Activity<br /><span class="nav-thai-font text-data" style="font-weight: 500">การใช้งานล่าสุด</span>
            </th>
          </tr>
        </thead>

        <tbody>

          <tr *ngFor="let element of tasks">
            <th scope="row">
              <a (click)="reviewService(element)" class="cursor__point">{{
                element.no
              }}</a>
            </th>
            <td>
              {{ element.creationDateTime | date: "dd-MM-yyyy HH:mm:ss" }}
            </td>
            <td>{{ element.servicesName }}</td>
            <td>{{ element.topic }}</td>
            <td>{{ element.modelName }}</td>
            <td>{{ element.customer }}</td>
            <td>{{ element.company }}</td>
            <td>{{ element.isApplyReview ? element.operationNo : "" }}</td>
            <td>{{ element.operator }}</td>
            <td> <span [ngClass]="{
              'green-text':element.status == 'Task  Finished',
              'red-text': element.status=='Cancelled'
              }">{{ element.status }}</span></td>
            <td class="display-grid grid__content__center">
              <button class="btn btn-blue-basic btn-sm" (click)="reviewService(element)">
                review
              </button>
              <button class="btn btn-blue-basic btn-sm" (click)="routeInprocess(element)" *ngIf="element.isApplyReview && element.status != 'Cancelled'">
                In Process Record
              </button>
              <button class="btn btn-blue-basic btn-sm" *ngIf="
                  element.isApplyReview &&
                  element.isInProcessRecord &&
                  checkDisplayMenu(element) &&
                  element.status != 'Cancelled'
                " (click)="routeTestCertificate(element)">
                Test Certificate
              </button>
              <button class="btn btn-blue-basic btn-sm" *ngIf="
                  element.isApplyReview &&
                  element.isInProcessRecord &&
                  element.status != 'Cancelled'
                " (click)="routeSubmissionLab(element)">
                Task Submission
              </button>
            </td>
            <td>
              {{ element.lastUpdateDateTime | date: "dd/MM/yyyy" }}
              <!-- -MM- HH:mm:ss -->
            </td>
          </tr>
        </tbody>
      </table>
      <div class="justify-content-between p-2" style="text-align: right">
        <ngb-pagination style="display: inline-block" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
        </ngb-pagination>

        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
          <option [ngValue]="10">10 items per page</option>
          <option [ngValue]="30">30 items per page</option>
          <option [ngValue]="40">40 items per page</option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop" tabindex="-1" [ngStyle]="{ display: display }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCloseHandled()"></button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Understood</button>
      </div>
    </div>
  </div>
</div>

<!-- <button type="button" class="btn btn-info btn-lg" (click)="openModal()">click to open</button> -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: display }">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content" style="width: 800px">
      <div class="modal-header">
        <h4 class="modal-title">2106XXX</h4>
        <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div disabled>
          <!-- <app-lab-job-detail-pending></app-lab-job-detail-pending> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="onCloseHandled()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
