import { UserGroupModel } from './../_models/authen.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { AuthenModel, UserModel } from '../index';

const API_USER_URL = environment.API_BASE_URL + '/api/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  getByEmailPhone(email: string, phone: string): Observable<UserModel> {
    // Note: Add headers if needed (tokens/bearer)

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');
    const _params = {
      email: email,
      phone: phone,
    };

    const _url = API_USER_URL + `/getByEmailPhone`;
    return this.http.post<UserModel>(_url, _params, { headers: httpHeaders });
  }
  // READ
  getAllUsers(): Observable<UserModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL;
    return this.http.get<UserModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getUserById(id: number): Observable<UserModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/${id}`;
    return this.http.get<UserModel>(_url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  updateUser(model: UserModel): Observable<UserModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL;
    return this.http.put<UserModel>(_url, model, { headers: httpHeaders });
  }

  // READ
  getAllUserByGroupId(groupId: number): Observable<UserModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/userGroup/${groupId}`;
    return this.http.get<UserModel[]>(_url, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteUser(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_USER_URL + `/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteUserAll(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_USER_URL + `/delete/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  changePasswordUser2(model: UserModel): Observable<UserModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/password2`;
    return this.http.put<UserModel>(_url, model, { headers: httpHeaders });
  }
  // UPDATE =>  PUT: add a new to the server
  changePasswordUser(model: UserModel): Observable<UserModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/password`;
    return this.http.put<UserModel>(_url, model, { headers: httpHeaders });
  }

  // READ
  getAllUserGroupModels(): Observable<UserGroupModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/UserGroups`;
    return this.http.get<UserGroupModel[]>(_url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  updateUserGroup(
    userId: number,
    userGroupIds: number[]
  ): Observable<UserModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_USER_URL + `/UserGroups/${userId}`;
    return this.http.put<UserModel>(_url, userGroupIds, {
      headers: httpHeaders,
    });
  }
}
