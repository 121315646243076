import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  MaintenanceRepairModel,
  MaintenanceRepairOptionModel,
  MaintenanceRepairRecordRes,
} from '../_models/maintenance-repair.model';

const API_RQUIPMENT_URL = environment.API_BASE_URL + '/api/Equipments';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceRepairService {
  constructor(private http: HttpClient) {}

  // READ
  getAllMaintenanceRepairRecordByEquipmentId(
    equipmentId: number
  ): Observable<MaintenanceRepairRecordRes[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url =
      API_RQUIPMENT_URL +
      `/MaintenanceRepairRecordByEquipmentId/${equipmentId}`;
    return this.http.get<MaintenanceRepairRecordRes[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getAllEquipmentMaintenancesByEquipmentId(
    equipmentId: number
  ): Observable<MaintenanceRepairOptionModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/EquipmentMaintenances/${equipmentId}`;
    return this.http.get<MaintenanceRepairOptionModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getMaintenanceRepairRecordById(
    id: number
  ): Observable<MaintenanceRepairModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/MaintenanceRepairRecord/${id}`;
    return this.http.get<MaintenanceRepairModel>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  createMaintenanceRepairRecord(
    model: MaintenanceRepairModel
  ): Observable<MaintenanceRepairModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/MaintenanceRepairRecord`;
    return this.http.post<MaintenanceRepairModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateMaintenanceRepairRecord(
    model: MaintenanceRepairModel
  ): Observable<MaintenanceRepairModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/MaintenanceRepairRecord`;
    return this.http.put<MaintenanceRepairModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteMaintenanceRepairRecord(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_RQUIPMENT_URL + `/MaintenanceRepairRecord/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  completeMaintenanceRepairRecord(
    MaintenanceRepairRecordId: number
  ): Observable<MaintenanceRepairModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = new MaintenanceRepairModel();

    const _url =
      API_RQUIPMENT_URL +
      `/MaintenanceRepairRecord/Complete/${MaintenanceRepairRecordId}`;
    return this.http.put<MaintenanceRepairModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
  // UPDATE =>  PUT: add a new to the server
  approveMaintenanceRepairRecord(
    MaintenanceRepairRecordId: number,
    model: MaintenanceRepairModel
  ): Observable<MaintenanceRepairModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = model;

    const _url =
      API_RQUIPMENT_URL +
      `/MaintenanceRepairRecord/approve/${MaintenanceRepairRecordId}`;
    return this.http.put<MaintenanceRepairModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
