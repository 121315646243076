import { EquipmentModel } from 'src/app/core/management-system/_models/equipment.model';
import { UserModel } from '../../authen/_models/authen.model';
export interface MaintenanceRepairRecordRes {
  id: number;
  topic: string;
  no: number;
  maintenanceRepairDateTime: string;
  operatorName: string;
  status: string;
  lastUpdateDateTime: string;
  isComplete: boolean;
}
export class MaintenanceRepairModel {
  id: number;
  topic: number;
  detail: string;
  anomaly: string;
  anomalyDetail: string;
  maintenanceRepairDateTime: string;
  userMaintenanceRepairId: number;
  userMaintenanceRepair: UserModel;
  equipmentMaintenanceRepairs: Array<EquipmentMaintenanceRepairModel> = [];

  result: string;
  equipmentId: number;
  equipment: EquipmentModel;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isComplete: boolean;
  completeDateTime: string;
  userCompleteId: number;
  userComplete: UserModel;
  userDeleteId: number;
  userDelete: UserModel;
  deleteDateTime: string;

  creationDateTime: string;
  lastUpdateDateTime: string;
  testId: number;
  testName: number;
  testDate: string;
  isApprove: boolean;

  clear() {
    this.id = 0;
    this.topic = 0;
    this.detail = '';
    this.anomaly = '';
    this.anomalyDetail = '';
    this.maintenanceRepairDateTime = '';
    this.userMaintenanceRepairId = 0;
    this.userMaintenanceRepair = new UserModel();
    this.equipmentMaintenanceRepairs = [];

    this.result = '';
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isComplete = false;
    this.completeDateTime = '';
    this.userCompleteId = 0;
    this.userComplete = new UserModel();
    this.userDeleteId = 0;
    this.userDelete = new UserModel();
    this.deleteDateTime = '';

    this.creationDateTime = '';
    this.lastUpdateDateTime = '';
  }
}

export class EquipmentMaintenanceRepairModel {
  maintenanceRepairRecordId: number;
  maintenanceId: number;
  maintenance: MaintenanceOptionModel;
  isCheck: boolean;
  detail: string;

  clear() {
    this.maintenanceRepairRecordId = 0;
    this.maintenanceId = 0;
    this.isCheck = false;
    this.detail = '';
  }
}

export class MaintenanceRepairOptionModel {
  equipmentId: number;
  maintenanceId: number;
  equipment: EquipmentModel;
  maintenance: MaintenanceOptionModel;

  clear() {
    this.equipmentId = 0;
    this.maintenanceId = 0;
    this.equipment = new EquipmentModel();
    this.maintenance = new MaintenanceOptionModel();
  }
}

export class MaintenanceOptionModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.detail = '';
    this.creationDateTime = '2021-01-01';
  }
}
