import { UserModel } from 'src/app/core/authen/_models/authen.model';
import { EquipmentModel } from '../_models/equipment.model';

export class CalibrationModel {
  equipment: EquipmentModel;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isComplete: boolean;
  completeDateTime: string;
  userCompleteId: number;
  userComplete: UserModel;
  userDeleteId: number;
  userDelete: UserModel;
  deleteDateTime: string;

  creationDateTime: string;
  lastUpdateDateTime: string;
  evaluationRecord: EvaluationRecordModel;

  id: number;
  topic: string;
  detail: string;
  calibrationDateTime: string;
  calibrationDateTimeString: string;
  calibrationNextDateTime: string;
  calibrationNo: string;
  calibrationAgency: string;
  equipmentId: number;
  fileCalibration1: File;
  attachFileCalibration1: string;
  fileCalibration2: File;
  attachFileCalibration2: string;
  fileCalibration3: File;
  attachFileCalibration3: string;
  fileCalibration4: File;
  attachFileCalibration4: string;
  fileCalibration5: File;
  attachFileCalibration5: string;
}

export class EvaluationRecordModel {
  id: number;
  evaluationDateTime: string;
  userEvaluationId: number;
  userEvaluation: UserModel;
  result: boolean;
  correctionFactor: string;
  resultDetail: string;
  calibrationRecordId: number;
  calibrationRecord: CalibrationModel;
  evaluationRecordLogs: Array<EvaluationRecordLogModel> = [];

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isComplete: boolean;
  completeDateTime: string;
  userCompleteId: number;
  userComplete: UserModel;
  userDeleteId: number;
  userDelete: UserModel;
  deleteDateTime: string;

  creationDateTime: string;
  lastUpdateDateTime: string;
  attachFile1: string;
  attachFile2: string;
  attachFile3: string;
  attachFile4: string;
  attachFile5: string;
  testId: number;
  testName: number;
  testDate: string;
  isApprove: boolean;

  clear() {
    this.id = 0;
    this.evaluationDateTime = '';
    this.userEvaluationId = 0;
    this.userEvaluation = new UserModel();
    this.result = false;
    this.correctionFactor = '';
    this.resultDetail = '';
    this.calibrationRecordId = 0;
    this.calibrationRecord = new CalibrationModel();
    this.evaluationRecordLogs = [];

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isComplete = false;
    this.completeDateTime = '';
    this.userCompleteId = 0;
    this.userComplete = new UserModel();
    this.userDeleteId = 0;
    this.userDelete = new UserModel();
    this.deleteDateTime = '';

    this.creationDateTime = '';
    this.lastUpdateDateTime = '';
    this.attachFile1 = '';
  }
}

export class EvaluationRecordLogModel {
  id: number;
  cal: number;
  std: number;
  tool: number;
  error: number;
  uncer: number;
  pError: string;
  nError: string;
  mpe: string;
  result: string;
  evaluationDetail: string;
  comment: string;
  evaluationRecordId: number;

  clear() {
    this.id = 0;
    this.pError = '';
    this.nError = '';
    this.mpe = '';
    this.result = '';
    this.evaluationDetail = '';
    this.comment = '';
    this.evaluationRecordId = 0;
  }
}

export interface EvaluationRecordUploadFile {
  id: number;
  fileManual1: File;
  fileManual2: File;
  fileManual3: File;
  fileManual4: File;
  fileManual5: File;
}
export interface VerificationUploadFile {
  id: number;
  fileManual1: File;
  fileManual2: File;
  fileManual3: File;
  fileManual4: File;
  fileManual5: File;
}
