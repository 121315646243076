import { VerificationInspectionIcModel } from './../_models/verification-inspection-ic.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  CalibrationModel,
  EvaluationRecordModel,
  EvaluationRecordUploadFile,
  VerificationUploadFile,
} from '../_models/calibration.model';

const API_RQUIPMENT_URL = environment.API_BASE_URL + '/api/Equipments';

@Injectable({
  providedIn: 'root',
})
export class CalibrationService {
  constructor(private http: HttpClient) {}

  // READ
  getAllCalibrationsByEquipmentId(
    equipmentId: number
  ): Observable<CalibrationModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/CalibrationByEquipmentId/${equipmentId}`;
    return this.http.get<CalibrationModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getCalibrationById(id: number): Observable<CalibrationModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Calibration/${id}`;
    return this.http.get<CalibrationModel>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createCalibration(model: CalibrationModel): Observable<CalibrationModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('topic', model.topic ? model.topic.toString() : null);
    formData.append('detail', model.detail);
    formData.append('calibrationDateTime', model.calibrationDateTime);
    formData.append('calibrationNextDateTime', model.calibrationNextDateTime);
    formData.append('calibrationNo', model.calibrationNo);
    formData.append('calibrationAgency', model.calibrationAgency);
    formData.append(
      'equipmentId',
      model.equipmentId ? model.equipmentId.toString() : null
    );

    formData.append('fileCalibration1', model.fileCalibration1);
    formData.append('attachFileCalibration1', model.attachFileCalibration1);
    formData.append('fileCalibration2', model.fileCalibration2);
    formData.append('attachFileCalibration2', model.attachFileCalibration2);
    formData.append('fileCalibration3', model.fileCalibration3);
    formData.append('attachFileCalibration3', model.attachFileCalibration3);
    formData.append('fileCalibration4', model.fileCalibration4);
    formData.append('attachFileCalibration4', model.attachFileCalibration4);
    formData.append('fileCalibration5', model.fileCalibration5);
    formData.append('attachFileCalibration5', model.attachFileCalibration5);

    const _url = API_RQUIPMENT_URL + `/Calibration`;
    return this.http.post<CalibrationModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateCalibration(model: CalibrationModel): Observable<CalibrationModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('topic', model.topic ? model.topic.toString() : null);
    formData.append('detail', model.detail);
    formData.append('calibrationDateTime', model.calibrationDateTime);
    formData.append('calibrationNextDateTime', model.calibrationNextDateTime);
    formData.append('calibrationNo', model.calibrationNo);
    formData.append('calibrationAgency', model.calibrationAgency);
    formData.append(
      'equipmentId',
      model.equipmentId ? model.equipmentId.toString() : null
    );

    formData.append('fileCalibration1', model.fileCalibration1);
    formData.append('attachFileCalibration1', model.attachFileCalibration1);
    formData.append('fileCalibration2', model.fileCalibration2);
    formData.append('attachFileCalibration2', model.attachFileCalibration2);
    formData.append('fileCalibration3', model.fileCalibration3);
    formData.append('attachFileCalibration3', model.attachFileCalibration3);
    formData.append('fileCalibration4', model.fileCalibration4);
    formData.append('attachFileCalibration4', model.attachFileCalibration4);
    formData.append('fileCalibration5', model.fileCalibration5);
    formData.append('attachFileCalibration5', model.attachFileCalibration5);

    const _url = API_RQUIPMENT_URL + `/Calibration`;
    return this.http.put<CalibrationModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteCalibration(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_RQUIPMENT_URL + `/Calibration/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  completeCalibration(calibrationId: number): Observable<CalibrationModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = new CalibrationModel();

    const _url = API_RQUIPMENT_URL + `/Calibration/Complete/${calibrationId}`;
    return this.http.put<CalibrationModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // READ
  getEvaluationByEquipmentId(
    calibrationId: number
  ): Observable<EvaluationRecordModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url =
      API_RQUIPMENT_URL + `/EvaluationByEquipmentId/${calibrationId}`;
    return this.http.get<EvaluationRecordModel>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createEvaluation(
    model: EvaluationRecordModel
  ): Observable<EvaluationRecordModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Evaluation`;
    return this.http.post<EvaluationRecordModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  updateEvaluation(
    model: EvaluationRecordModel
  ): Observable<EvaluationRecordModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Evaluation`;
    return this.http.put<EvaluationRecordModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  completeEvaluation(evaluationId: number): Observable<EvaluationRecordModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = new EvaluationRecordModel();

    const _url = API_RQUIPMENT_URL + `/Evaluation/Complete/${evaluationId}`;
    return this.http.put<EvaluationRecordModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteEvaluation(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_RQUIPMENT_URL + `/Evaluation/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  uploadEvaluation(
    model: EvaluationRecordUploadFile
  ): Observable<EvaluationRecordModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());

    formData.append('fileManual1', model.fileManual1);
    formData.append('fileManual2', model.fileManual2);
    formData.append('fileManual3', model.fileManual3);
    formData.append('fileManual4', model.fileManual4);
    formData.append('fileManual5', model.fileManual5);

    const _url = API_RQUIPMENT_URL + `/Evaluation/Upload`;
    return this.http.post<EvaluationRecordModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server

  // UPDATE =>  PUT: add a new to the server
  approveEvaluationRecord(
    MaintenanceRepairRecordId: number,
    model: EvaluationRecordModel
  ): Observable<EvaluationRecordModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = model;

    const _url =
      API_RQUIPMENT_URL +
      `/EvaluationRecord/approve/${MaintenanceRepairRecordId}`;
    return this.http.put<EvaluationRecordModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
