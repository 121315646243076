import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { BannerModel } from '../index';

const API_BANNER_URL = environment.API_BASE_URL + '/api/Banner';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient) {}

  // READ
  getAllBanners(): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL;
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getShowAllBanners(): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL + '/show';
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }
  // READ
  getBannerById(id: number): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL + `/${id}`;
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createBanner(model: BannerModel): Observable<BannerModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('file', model.file);
    formData.append('pathName', model.pathName);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('isShow', model.isShow.toString());
    formData.append('isAdmin', model.isAdmin.toString());
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_BANNER_URL;
    return this.http.post<BannerModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateBanner(model: BannerModel): Observable<BannerModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('file', model.file);
    formData.append('pathName', model.pathName);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('isShow', model.isShow.toString());
    formData.append('isAdmin', model.isAdmin.toString());
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_BANNER_URL;
    return this.http.put<BannerModel>(_url, formData, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteBanner(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_BANNER_URL + `/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }
  // READ
  getAdminAllBanners(): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL + '/Admin';
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }
  // READ
  getAdminShowAllBanners(): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL + '/Admin/show';
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAdminBannerById(id: number): Observable<BannerModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_BANNER_URL + `/Admin/${id}`;
    return this.http.get<BannerModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createAdminBanner(model: BannerModel): Observable<BannerModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('file', model.file);
    formData.append('pathName', model.pathName);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('isShow', model.isShow.toString());
    formData.append('isAdmin', model.isAdmin.toString());
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_BANNER_URL + '/Admin';
    return this.http.post<BannerModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateAdminBanner(model: BannerModel): Observable<BannerModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('file', model.file);
    formData.append('pathName', model.pathName);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('isShow', model.isShow.toString());
    formData.append('isAdmin', model.isAdmin.toString());
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_BANNER_URL + '/Admin';
    return this.http.put<BannerModel>(_url, formData, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteAdminBanner(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_BANNER_URL + `/Admin/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }
}
