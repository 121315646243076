import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LaboratoryModel,
  LaboratoryService,
  TestCertificateModel,
} from 'src/app/core/laboratory';

@Component({
  selector: 'app-crtificate-lab-paper-eng',
  templateUrl: './crtificate-lab-paper-eng.component.html',
  styleUrls: ['./crtificate-lab-paper-eng.component.scss'],
})
export class CrtificateLabPaperEngComponent implements OnInit {
  projectId: number = 0;
  dateNow: string = '00E/09/61';

  certificateLab: TestCertificateModel = <TestCertificateModel>{};
  lab: LaboratoryModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private laboratoryService: LaboratoryService,
    private cd: ChangeDetectorRef
  ) {
    //this.dateNow = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const _projectId = params['projectId'];
      if (_projectId) {
        this.projectId = Number(_projectId);
      }
    });

    this.certificateLab = new TestCertificateModel();
    this.certificateLab.clear();

    this.getDataTable();
  }

  /**
   * get data table
   */
  getDataTable() {
    this.laboratoryService
      .getLaboratoryById(this.projectId)
      .subscribe((res: LaboratoryModel) => {
        if (res) {
          this.lab = res;
          console.log(res);
          this.certificateLab = res.testCertificate;
          this.cd.detectChanges();
          setTimeout(function () {
            window.print();
          }, 500);
        }
      });
  }
  nameTested(name: string): string {
    console.log(name);
    if (name.toLocaleLowerCase().includes('borimasporn')) {
      return '/assets/images/updatesign/1. sign korn.png';
    } else if (name.toLocaleLowerCase().includes('saeyong')) {
      return '/assets/images/updatesign/2. sign aew.png';
    } else if (name.toLocaleLowerCase().includes('kaewnongsaeng')) {
      return '/assets/images/updatesign/3. sign suwichai.png';
    } else if (name.toLocaleLowerCase().includes('kruamuang')) {
      return '/assets/images/updatesign/4. sign panusak.png';
    } else if (name.toLocaleLowerCase().includes('srikeawsai')) {
      return '/assets/images/updatesign/5. sign Mongkol.png';
    } else if (name.toLocaleLowerCase().includes('janchaiyot')) {
      return '/assets/images/updatesign/6. sign kwan.png';
    } else if (name.toLocaleLowerCase().includes('changklungmhok')) {
      return '/assets/images/updatesign/7. sign kNong.png';
    } else if (name.toLocaleLowerCase().includes('kakarndee')) {
      return '/assets/images/updatesign/8. sign pkig (ผู้เสนอราคา).png';
    } else if (name.toLocaleLowerCase().includes('julakar')) {
      return '/assets/images/updatesign/9. Sign Sithikorn (แทนหัวหน้าห้อง).png';
    } else {
      return '';
    }
  }
}
