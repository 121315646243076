import { ForgotPasswordComponent } from './authen/forgot-password/forgot-password.component';
import { ContactComponent } from './components/contact/contact.component';
import { SignUpComponent } from './authen/sign-up/sign-up.component';
import { SignInComponent } from './authen/sign-in/sign-in.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrtificateLabPaperComponent } from './pages/exports/crtificate-lab-paper/crtificate-lab-paper.component';
import { CrtificateLabPaperEngComponent } from './pages/exports/crtificate-lab-paper-eng/crtificate-lab-paper-eng.component';
import { PaymentProjectComponent } from './pages/exports/payment-project/payment-project.component';
import { PrintEPlanComponent } from './pages/exports/print-e-plan/print-e-plan.component';
import { PrintTaskPlanningListComponent } from './pages/exports/print-task-planning-list/print-task-planning-list.component';
import { PrintCustomerPropertiesListComponent } from './pages/exports/print-customer-properties-list/print-customer-properties-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/pages', pathMatch: 'full' },
  { path: 'signIn', component: SignInComponent },
  { path: 'signUp', component: SignUpComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  {
    path: 'certificate-lab/:projectId',
    component: CrtificateLabPaperComponent,
  },
  {
    path: 'equipment/print-equipment-plan',
    component: PrintEPlanComponent,
  },
  {
    path: 'planning/task-planning-list',
    component: PrintTaskPlanningListComponent,
  },
  {
    path: 'certificate-lab-eng/:projectId',
    component: CrtificateLabPaperEngComponent,
  },
  {
    path: 'customer-properties/customer-properties-list',
    component: PrintCustomerPropertiesListComponent,
  },
  {
    path: 'payment-project/:serviceId/:projectId',
    component: PaymentProjectComponent,
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  //{ path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
  { path: '**', redirectTo: '/pages' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
