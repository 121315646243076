import { LabEquipmentModel } from './../_models/equipment.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { EquipmentModel } from '../_models/equipment.model';

const API_RQUIPMENT_URL = environment.API_BASE_URL + '/api/Equipments';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(private http: HttpClient) {}

  // READ
  getAllEquipments(): Observable<EquipmentModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL;
    return this.http.get<EquipmentModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getEquipmentById(id: number): Observable<EquipmentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/${id}`;
    return this.http.get<EquipmentModel>(_url, { headers: httpHeaders });
  }

  // READ
  getEquipmentUsingById(id: number): Observable<LabEquipmentModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/EquipmentUsing/${id}`;
    return this.http.get<LabEquipmentModel[]>(_url, { headers: httpHeaders });
  }
  // CREATE => POST: add a new to the server
  createEquipment(model: EquipmentModel): Observable<EquipmentModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('no', model.no);
    formData.append('name', model.name);
    formData.append('model', model.model);
    formData.append('distributor', model.distributor);
    formData.append('sn', model.sn);
    formData.append('code', model.code);
    formData.append('detail', model.detail);
    formData.append('skill', model.skill);

    formData.append('fileManual1', model.fileManual1);
    formData.append('attachFileManual1', model.attachFileManual1);
    formData.append('fileManual2', model.fileManual2);
    formData.append('attachFileManual2', model.attachFileManual2);
    formData.append('fileManual3', model.fileManual3);
    formData.append('attachFileManual3', model.attachFileManual3);
    formData.append('fileManual4', model.fileManual4);
    formData.append('attachFileManual4', model.attachFileManual4);
    formData.append('fileManual5', model.fileManual5);
    formData.append('attachFileManual5', model.attachFileManual5);

    formData.append('filePO1', model.filePO1);
    formData.append('attachFilePO1', model.attachFilePO1);
    formData.append('filePO2', model.filePO2);
    formData.append('attachFilePO2', model.attachFilePO2);
    formData.append('filePO3', model.filePO3);
    formData.append('attachFilePO3', model.attachFilePO3);
    formData.append('filePO4', model.filePO4);
    formData.append('attachFilePO4', model.attachFilePO4);
    formData.append('filePO5', model.filePO5);
    formData.append('attachFilePO5', model.attachFilePO5);

    formData.append(
      'workAreaId',
      model.workAreaId ? model.workAreaId.toString() : null
    );
    formData.append('division', model.division);
    formData.append('responsiblePersonName', model.responsiblePersonName);
    formData.append(
      'responsiblePersonId',
      model.responsiblePersonId ? model.responsiblePersonId.toString() : null
    );
    formData.append('action', model.action ? model.action.toString() : null);
    formData.append(
      'standardId',
      model.standardId ? model.standardId.toString() : null
    );
    formData.append('c', model.c ? model.c.toString() : null);
    formData.append('v', model.v ? model.v.toString() : null);
    formData.append('iC', model.ic ? model.ic.toString() : null);
    formData.append('detail2', model.detail2);
    formData.append('range1', model.range1);
    formData.append('range2', model.range2);
    formData.append('range3', model.range3);
    formData.append('range4', model.range4);
    formData.append('range5', model.range5);
    formData.append('range6', model.range6);
    formData.append('range7', model.range7);
    formData.append('range8', model.range8);
    formData.append('range9', model.range9);
    formData.append('range10', model.range10);
    formData.append('range11', model.range11);
    formData.append('range12', model.range12);
    formData.append('criterion1', model.criterion1);
    formData.append('criterion2', model.criterion2);
    formData.append('criterion3', model.criterion3);
    formData.append('criterion4', model.criterion4);
    formData.append('criterion5', model.criterion5);
    formData.append('criterion6', model.criterion6);
    formData.append('criterion7', model.criterion7);
    formData.append('criterion8', model.criterion8);
    formData.append('criterion9', model.criterion9);
    formData.append('criterion10', model.criterion10);
    formData.append('criterion11', model.criterion11);
    formData.append('criterion12', model.criterion12);
    formData.append(
      'equipmentTestStandardIds',
      JSON.stringify(model.equipmentTestStandardIds)
    );
    formData.append(
      'equipmentMaintenanceIds',
      JSON.stringify(model.equipmentMaintenanceIds)
    );
    formData.append('maintenance1', model.maintenance1);
    formData.append('maintenance2', model.maintenance2);
    formData.append('maintenance3', model.maintenance3);
    formData.append('maintenance4', model.maintenance4);
    formData.append('maintenance5', model.maintenance5);
    formData.append('maintenance6', model.maintenance6);
    formData.append('maintenance7', model.maintenance7);
    formData.append('maintenance8', model.maintenance8);
    formData.append('maintenance9', model.maintenance9);
    formData.append('maintenance10', model.maintenance10);
    formData.append('maintenance11', model.maintenance11);
    formData.append('maintenance12', model.maintenance12);
    formData.append('maintenance13', model.maintenance13);
    formData.append('maintenance14', model.maintenance14);
    formData.append('maintenance15', model.maintenance15);
    formData.append('maintenance16', model.maintenance16);
    formData.append('maintenance17', model.maintenance17);
    formData.append('maintenance18', model.maintenance18);
    formData.append('maintenance19', model.maintenance19);
    formData.append('maintenance20', model.maintenance20);
    formData.append(
      'maintenanceFrequency1',
      model.maintenanceFrequency1
        ? model.maintenanceFrequency1.toString()
        : null
    );
    formData.append(
      'maintenanceFrequency2',
      model.maintenanceFrequency2
        ? model.maintenanceFrequency2.toString()
        : null
    );
    formData.append(
      'maintenanceFrequency3',
      model.maintenanceFrequency3
        ? model.maintenanceFrequency3.toString()
        : null
    );
    formData.append('isDiscarded', model.isDiscarded.toString());
    formData.append('discardedDetail', model.discardedDetail);

    formData.append('fileImage', model.fileImage);
    formData.append('attachFileImage', model.attachFileImage);
    formData.append('receiveDate', model.receiveDate);

    const _url = API_RQUIPMENT_URL;
    return this.http.post<EquipmentModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateEquipment(model: EquipmentModel): Observable<EquipmentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('no', model.no);
    formData.append('name', model.name);
    formData.append('model', model.model);
    formData.append('distributor', model.distributor);
    formData.append('sn', model.sn);
    formData.append('code', model.code);
    formData.append('detail', model.detail);
    formData.append('skill', model.skill);

    formData.append('fileManual1', model.fileManual1);
    formData.append('attachFileManual1', model.attachFileManual1);
    formData.append('fileManual2', model.fileManual2);
    formData.append('attachFileManual2', model.attachFileManual2);
    formData.append('fileManual3', model.fileManual3);
    formData.append('attachFileManual3', model.attachFileManual3);
    formData.append('fileManual4', model.fileManual4);
    formData.append('attachFileManual4', model.attachFileManual4);
    formData.append('fileManual5', model.fileManual5);
    formData.append('attachFileManual5', model.attachFileManual5);

    formData.append('filePO1', model.filePO1);
    formData.append('attachFilePO1', model.attachFilePO1);
    formData.append('filePO2', model.filePO2);
    formData.append('attachFilePO2', model.attachFilePO2);
    formData.append('filePO3', model.filePO3);
    formData.append('attachFilePO3', model.attachFilePO3);
    formData.append('filePO4', model.filePO4);
    formData.append('attachFilePO4', model.attachFilePO4);
    formData.append('filePO5', model.filePO5);
    formData.append('attachFilePO5', model.attachFilePO5);

    formData.append(
      'workAreaId',
      model.workAreaId ? model.workAreaId.toString() : null
    );
    formData.append('division', model.division);
    formData.append('responsiblePersonName', model.responsiblePersonName);
    formData.append(
      'responsiblePersonId',
      model.responsiblePersonId ? model.responsiblePersonId.toString() : null
    );
    formData.append('action', model.action ? model.action.toString() : null);
    formData.append(
      'standardId',
      model.standardId ? model.standardId.toString() : null
    );
    formData.append('c', model.c ? model.c.toString() : null);
    formData.append('v', model.v ? model.v.toString() : null);
    formData.append('iC', model.ic ? model.ic.toString() : null);
    formData.append('detail2', model.detail2);

    formData.append('range1', model.range1);
    formData.append('range2', model.range2);
    formData.append('range3', model.range3);
    formData.append('range4', model.range4);
    formData.append('range5', model.range5);
    formData.append('range6', model.range6);
    formData.append('range7', model.range7);
    formData.append('range8', model.range8);
    formData.append('range9', model.range9);
    formData.append('range10', model.range10);
    formData.append('range11', model.range11);
    formData.append('range12', model.range12);
    formData.append('criterion1', model.criterion1);
    formData.append('criterion2', model.criterion2);
    formData.append('criterion3', model.criterion3);
    formData.append('criterion4', model.criterion4);
    formData.append('criterion5', model.criterion5);
    formData.append('criterion6', model.criterion6);
    formData.append('criterion7', model.criterion7);
    formData.append('criterion8', model.criterion8);
    formData.append('criterion9', model.criterion9);
    formData.append('criterion10', model.criterion10);
    formData.append('criterion11', model.criterion11);
    formData.append('criterion12', model.criterion12);
    formData.append(
      'equipmentTestStandardIds',
      JSON.stringify(model.equipmentTestStandardIds)
    );
    formData.append(
      'equipmentMaintenanceIds',
      JSON.stringify(model.equipmentMaintenanceIds)
    );
    formData.append('maintenance1', model.maintenance1);
    formData.append('maintenance2', model.maintenance2);
    formData.append('maintenance3', model.maintenance3);
    formData.append('maintenance4', model.maintenance4);
    formData.append('maintenance5', model.maintenance5);
    formData.append('maintenance6', model.maintenance6);
    formData.append('maintenance7', model.maintenance7);
    formData.append('maintenance8', model.maintenance8);
    formData.append('maintenance9', model.maintenance9);
    formData.append('maintenance10', model.maintenance10);
    formData.append('maintenance11', model.maintenance11);
    formData.append('maintenance12', model.maintenance12);
    formData.append('maintenance13', model.maintenance13);
    formData.append('maintenance14', model.maintenance14);
    formData.append('maintenance15', model.maintenance15);
    formData.append('maintenance16', model.maintenance16);
    formData.append('maintenance17', model.maintenance17);
    formData.append('maintenance18', model.maintenance18);
    formData.append('maintenance19', model.maintenance19);
    formData.append('maintenance20', model.maintenance20);
    formData.append(
      'maintenanceFrequency1',
      model.maintenanceFrequency1
        ? model.maintenanceFrequency1.toString()
        : null
    );
    formData.append(
      'maintenanceFrequency2',
      model.maintenanceFrequency2
        ? model.maintenanceFrequency2.toString()
        : null
    );
    formData.append(
      'maintenanceFrequency3',
      model.maintenanceFrequency3
        ? model.maintenanceFrequency3.toString()
        : null
    );
    formData.append('isDiscarded', model.isDiscarded.toString());
    formData.append('discardedDetail', model.discardedDetail);

    formData.append('fileImage', model.fileImage);
    formData.append('attachFileImage', model.attachFileImage);
    formData.append('receiveDate', model.receiveDate);

    const _url = API_RQUIPMENT_URL;
    return this.http.put<EquipmentModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteEquipment(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_RQUIPMENT_URL + `/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  completeCalibration(calibrationId: number): Observable<EquipmentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = new EquipmentModel();

    const _url = API_RQUIPMENT_URL + `/Complete/${calibrationId}`;
    return this.http.put<EquipmentModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
