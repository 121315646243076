import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { LaboratoryModel } from '../index';

const API_LABORATORY_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryService {
  constructor(private http: HttpClient) {}

  // READ
  getServiceIsTaskSubmission(): Observable<LaboratoryModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_LABORATORY_URL + `/Laboratory/IsTaskSubmission`;
    return this.http.get<LaboratoryModel[]>(_url, { headers: httpHeaders });
  }
  // READ
  getAllLaboratorys(): Observable<LaboratoryModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_LABORATORY_URL + `/Laboratory`;
    return this.http.get<LaboratoryModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getLaboratoryById(id: number): Observable<LaboratoryModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_LABORATORY_URL + `/Laboratory/${id}`;
    return this.http.get<LaboratoryModel>(_url, { headers: httpHeaders });
  }

  // READ
  getLaboratoryOldById(id: number): Observable<LaboratoryModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_LABORATORY_URL + `/LaboratoryOld/${id}`;
    return this.http.get<LaboratoryModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createLaboratory(model: LaboratoryModel): Observable<LaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append(
      'testStandardId',
      model.testStandardId == null ? null : model.testStandardId.toString()
    );
    formData.append('testStandardOthers', model.testStandardOthers);
    formData.append(
      'testTopicId',
      model.testTopicId == null ? null : model.testTopicId.toString()
    );
    formData.append(
      'sampleTypeId',
      model.sampleTypeId == null ? null : model.sampleTypeId.toString()
    );
    formData.append('sampleTypeOthers', model.sampleTypeOthers);
    formData.append('trademark', model.trademark);
    formData.append('model', model.model);
    formData.append('quantity', model.quantity);
    formData.append('inputVoltage', model.inputVoltage);
    formData.append('acdc', model.acdc);
    formData.append('frequency', model.frequency);
    formData.append('power', model.power);
    formData.append('current', model.current);
    formData.append('productCode', model.productCode);

    formData.append(
      'lightsourceTypeId',
      model.lightsourceTypeId == null
        ? null
        : model.lightsourceTypeId.toString()
    );
    formData.append('lightsourceTypeOthers', model.lightsourceTypeOthers);
    formData.append('lightsourceTrademark', model.lightsourceTrademark);
    formData.append('lightsourceModel', model.lightsourceModel);
    formData.append(
      'lightsourceQuantity',
      model.lightsourceQuantity.toString()
    );
    formData.append('lightsourceProductCode', model.lightsourceProductCode);

    formData.append(
      'controlGearTypeId',
      model.controlGearTypeId == null
        ? null
        : model.controlGearTypeId.toString()
    );
    formData.append('controlGearTypeOthers', model.controlGearTypeOthers);
    formData.append('controlGearTrademark', model.controlGearTrademark);
    formData.append('controlGearModel', model.controlGearModel);
    formData.append(
      'controlGearQuantity',
      model.controlGearQuantity.toString()
    );
    formData.append('controlGearProductCode', model.controlGearProductCode);

    formData.append('othersDescription', model.othersDescription);
    formData.append('othersTrademark', model.othersTrademark);
    formData.append('othersModel', model.othersModel);
    formData.append('othersQuantity', model.othersQuantity.toString());
    formData.append('othersProductCode', model.othersProductCode);

    formData.append('wantReport', model.wantReport.toString());
    formData.append('reportLang', model.reportLang.toString());
    formData.append('uncertainty', model.uncertainty.toString());
    formData.append('decisionRule', model.decisionRule);
    formData.append('wantReturned', model.wantReturned.toString());
    formData.append('receiveTestResult', model.receiveTestResult);
    formData.append('doNumber', model.doNumber);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    // formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    // formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    // formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    // formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    // formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append(
      'isApplyReview',
      model.isApplyReview == undefined
        ? 'false'
        : model.isApplyReview.toString()
    );

    const _url = API_LABORATORY_URL + `/Laboratory`;
    return this.http.post<LaboratoryModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateLaboratory(model: LaboratoryModel): Observable<LaboratoryModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id == null ? null : model.id.toString());
    formData.append(
      'testStandardId',
      model.testStandardId == null ? null : model.testStandardId.toString()
    );
    formData.append('testStandardOthers', model.testStandardOthers);
    formData.append(
      'testTopicId',
      model.testTopicId == null ? null : model.testTopicId.toString()
    );
    formData.append(
      'sampleTypeId',
      model.sampleTypeId == null ? null : model.sampleTypeId.toString()
    );
    formData.append('sampleTypeOthers', model.sampleTypeOthers);
    formData.append('trademark', model.trademark);
    formData.append('model', model.model);
    formData.append('quantity', model.quantity);
    formData.append('inputVoltage', model.inputVoltage);
    formData.append('acdc', model.acdc);
    formData.append('frequency', model.frequency);
    formData.append('power', model.power);
    formData.append('current', model.current);
    formData.append('productCode', model.productCode);

    formData.append(
      'lightsourceTypeId',
      model.lightsourceTypeId == null
        ? null
        : model.lightsourceTypeId.toString()
    );
    formData.append('lightsourceTypeOthers', model.lightsourceTypeOthers);
    formData.append('lightsourceTrademark', model.lightsourceTrademark);
    formData.append('lightsourceModel', model.lightsourceModel);
    formData.append('lightsourceDONo', model.lightsourceDONo);
    formData.append(
      'lightsourceQuantity',
      model.lightsourceQuantity.toString()
    );
    formData.append('lightsourceProductCode', model.lightsourceProductCode);

    formData.append(
      'controlGearTypeId',
      model.controlGearTypeId == null
        ? null
        : model.controlGearTypeId.toString()
    );
    formData.append('controlGearTypeOthers', model.controlGearTypeOthers);
    formData.append('controlGearTrademark', model.controlGearTrademark);
    formData.append('controlGearModel', model.controlGearModel);
    formData.append('controlGearDONo', model.controlGearDONo);
    formData.append(
      'controlGearQuantity',
      model.controlGearQuantity.toString()
    );
    formData.append('controlGearProductCode', model.controlGearProductCode);

    formData.append('othersDescription', model.othersDescription);
    formData.append('othersTrademark', model.othersTrademark);
    formData.append('othersModel', model.othersModel);
    formData.append('othersDONo', model.othersDONo);
    formData.append('othersQuantity', model.othersQuantity.toString());
    formData.append('othersProductCode', model.othersProductCode);

    formData.append('wantReport', model.wantReport.toString());
    formData.append('reportLang', model.reportLang.toString());
    formData.append('uncertainty', model.uncertainty.toString());
    formData.append('decisionRule', model.decisionRule);
    formData.append('wantReturned', model.wantReturned.toString());
    formData.append('receiveTestResult', model.receiveTestResult);
    formData.append('doNumber', model.doNumber);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append(
      'isConfirm',
      model.isConfirm == undefined ? 'false' : model.isConfirm.toString()
    );

    const _url = API_LABORATORY_URL + `/Laboratory`;
    console.log(formData);
    return this.http.put<LaboratoryModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteLaboratory(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_LABORATORY_URL + `/Laboratory/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }
}
