<div class="background-blue gap-between-button ">
  <div class="marginContainer">
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-color-white">Customer Properties<br />
          <p class="text-color-white margin-text-sub font-size-22 ">ทรัพย์สินลูกค้า</p>
        </h1>
      </div>
      <div class="col text-align-right margin-top-28px">
        <h5 class="text-color-white font-size-15rem">Customer Properties Log<br /><span class="nav-thai-font text-color-white font-size-18">รายการทรัพย์สินลูกค้า</span></h5>
      </div>
    </div>
  </div>
</div>

<div class="marginContainer2">
  <div class="outerDiv row justify-content-center mt-3 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Request No.</th>
          <th scope="col" style="width: 100px;">Services</th>
          <th scope="col" style="width: 100px;">Product Code</th>
          <th scope="col">DO No.ค้า</th>
          <th scope="col" style="width: 100px;">Customer</th>
          <th scope="col" style="width: 150px;">Company</th>
          <th scope="col">Received Date
          </th>
          <th scope="col" style="width: 50px;">Task Status</th>
          <th scope="col">Properties Status</th>
          <th scope="col">GRN No.สินค้า</th>
          <th scope="col">Arranged Date</th>
          <th scope="col">Last Activity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of reviewCustomerPropertys">
          <th scope="row">
            <a class="cursor__point">
              {{ element.no }}
            </a>
          </th>
          <td>
            {{ element.serviceDate | date:'dd-MM-yyyy'  }}
            <br>
            {{ element.serviceDate | date:'HH:mm:ss'  }}
          </td>
          <!-- <td>{{ getProjectTypeName(element) }}</td> -->
          <td>{{ element.productCode }}</td>
          <td>{{ element.doNo }}</td>
          <td>{{ element.customerName }}</td>
          <td>{{ element.customerCompany }}</td>
          <td>
            {{ element.creationDateTime | date:'dd-MM-yyyy'  }}
            <br>
            {{ element.creationDateTime | date:'HH:mm:ss'  }}
          </td>
          <td> <span [ngClass]="{
              'green-text':element.status == 'Task  Finished',
              'red-text': element.status=='Cancelled'
              }">{{ element.status }}</span></td>
          <!-- <td> <span [ngClass]="{'green-text':element.isFinish}">{{ getProjectStatus(element) }}</span></td> -->
          <td>{{ getArrangement(element.arrangement) }}</td>
          <td>{{ element.grnNo }}</td>
          <td>{{ element.arrangementDate | date:'dd-MM-yyyy' }}</td>
          <td>{{ element.customerPropertieyLastUpdateDateTime | date:'dd-MM-yyyy' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
