import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';

// Models
import {
  AuthenModel,
  AuthenService,
  UserModel,
  UserService,
} from 'src/app/core/authen';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public loginState: boolean = false;

  // submit(login){
  //   console.log("form submitted", login)

  // }
  public urlApi: string =
    'https://jsonplaceholder.typicode.com/posts?fbclid=IwAR19xkKNN1KoqMBCajut1bxZe_ZfLoTtPMSurIfDQvT8-6aVj2SnXAXsvrM';
  // public urlApi:string = "http://localhost/api/login";
  public responseValue: any;
  // check status
  LoginStatus$ = new BehaviorSubject<boolean>(null);
  email = '';
  phone = '';
  status = true;
  user: UserModel;
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.userService
      .getByEmailPhone(this.email, this.phone)
      .subscribe((res: UserModel) => {
        this.user = res;
        if (res) {
          this.status = true;
        } else {
          this.status = false;
        }
      });
  }

  // prepare form
  // prepareForm(data: any): AuthenModel {
  //   const _auth = new AuthenModel();
  //   _auth.email = data.email;
  //   _auth.password = data.password;

  //   return _auth;
  // }

  onSubmitPassword() {
    this.userService
      .changePasswordUser2(this.user)
      .subscribe((res: UserModel) => {
        this.user = null;

        this.status = true;
        this.router.navigate(['/signIn']);
        this.notifier.notify('success', 'Welcome LICThailand');
      });
  }
}
