import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { ContentModel } from '../index';

const API_CONTENT_URL = environment.API_BASE_URL + '/api/Content';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private http: HttpClient) {}

  // READ
  getContents(): Observable<ContentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_CONTENT_URL;
    return this.http.get<ContentModel>(_url, { headers: httpHeaders });
  }

  // READ
  getContentById(id: number): Observable<ContentModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_CONTENT_URL + `/${id}`;
    return this.http.get<ContentModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createContent(model: ContentModel): Observable<ContentModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('mainContent', model.mainContent);
    formData.append('detailContent', model.detailContent);
    formData.append('fileImageBlock1', model.fileImageBlock1);
    formData.append('imageBlock1', model.imageBlock1);
    formData.append('titleBlock1', model.titleBlock1);
    formData.append('detailBlock1', model.detailBlock1);
    formData.append('fileImageBlock2', model.fileImageBlock2);
    formData.append('imageBlock2', model.imageBlock2);
    formData.append('titleBlock2', model.titleBlock2);
    formData.append('detailBlock2', model.detailBlock2);
    formData.append('fileImageBlock3', model.fileImageBlock3);
    formData.append('imageBlock3', model.imageBlock3);
    formData.append('titleBlock3', model.titleBlock3);
    formData.append('detailBlock3', model.detailBlock3);
    formData.append('consent', model.consent);
    formData.append('fileDownload', model.fileDownload);
    formData.append('consent', model.consent);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('adminContent', model.adminContent);
    formData.append('isShow', model.isShow.toString());
    formData.append('isAdmin', model.isAdmin.toString());
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_CONTENT_URL;
    return this.http.post<ContentModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateContent(model: ContentModel): Observable<ContentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    // const formData = new FormData();
    // formData.append('id', model.id.toString());
    // formData.append('mainContent', model.mainContent);
    // formData.append('detailContent', model.detailContent);
    // formData.append('fileImageBlock1', model.fileImageBlock1);
    // formData.append('imageBlock1', model.imageBlock1);
    // formData.append('titleBlock1', model.titleBlock1);
    // formData.append('detailBlock1', model.detailBlock1);
    // formData.append('fileImageBlock2', model.fileImageBlock2);
    // formData.append('imageBlock2', model.imageBlock2);
    // formData.append('titleBlock2', model.titleBlock2);
    // formData.append('detailBlock2', model.detailBlock2);
    // formData.append('fileImageBlock3', model.fileImageBlock3);
    // formData.append('imageBlock3', model.imageBlock3);
    // formData.append('titleBlock3', model.titleBlock3);
    // formData.append('detailBlock3', model.detailBlock3);
    // formData.append('consent', model.consent);
    // formData.append('fileDownload', model.fileDownload);
    // formData.append('pathDownload', model.pathDownload);
    // formData.append('startDate', model.startDate);
    // formData.append('endDate', model.endDate);
    // formData.append('isShow', model.isShow.toString());
    // formData.append('isAdmin', model.isAdmin.toString());
    // formData.append('creationDateTime', model.creationDateTime);
    // formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_CONTENT_URL;
    return this.http.put<ContentModel>(_url, model, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteContent(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_CONTENT_URL + `/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // READ
  getAdminContents(): Observable<ContentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_CONTENT_URL + '/Admin';
    return this.http.get<ContentModel>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createAdminContent(model: ContentModel): Observable<ContentModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id == undefined ? '0' : model.id.toString());
    formData.append('mainContent', model.mainContent);
    formData.append('detailContent', model.detailContent);
    formData.append('fileImageBlock1', model.fileImageBlock1);
    formData.append('imageBlock1', model.imageBlock1);
    formData.append('titleBlock1', model.titleBlock1);
    formData.append('detailBlock1', model.detailBlock1);
    formData.append('fileImageBlock2', model.fileImageBlock2);
    formData.append('imageBlock2', model.imageBlock2);
    formData.append('titleBlock2', model.titleBlock2);
    formData.append('detailBlock2', model.detailBlock2);
    formData.append('fileImageBlock3', model.fileImageBlock3);
    formData.append('imageBlock3', model.imageBlock3);
    formData.append('titleBlock3', model.titleBlock3);
    formData.append('detailBlock3', model.detailBlock3);
    formData.append('consent', model.consent);
    formData.append('fileDownload', model.fileDownload);
    formData.append('pathDownload', model.pathDownload);
    formData.append('startDate', model.startDate);
    formData.append('endDate', model.endDate);
    formData.append('adminContent', model.adminContent);
    formData.append('isShow', '1');
    formData.append('isAdmin', '1');
    formData.append('creationDateTime', model.creationDateTime);
    formData.append('lastUpdateDateTime', model.lastUpdateDateTime);

    const _url = API_CONTENT_URL + '/Admin';
    return this.http.post<ContentModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateAdminContent(model: ContentModel): Observable<ContentModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_CONTENT_URL + '/Admin';
    return this.http.put<ContentModel>(_url, model, { headers: httpHeaders });
  }
}
