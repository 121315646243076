import { LaboratoryModel } from '../_models/laboratory.model';
import { EquipmentModel } from '../../management-system/_models/equipment.model';
import { UserModel } from '../../authen';

export class InprocessLaboratoryModel {
  id: number;
  laboratoryId: number;
  laboratory: LaboratoryModel;
  goniophotometers: Array<GoniophotometerModel> = [];
  sphere: Array<SphereModel> = [];
  fstNumericals: Array<FSTNumericalModel> = [];
  sstNumericals: Array<SSTNumericalModel> = [];
  shockProtectionTests: Array<ShockProtectionTestModel> = [];
  insulationElectricalResistanceTests: Array<InsulationElectricalResistanceTestModel> =
    [];
  humidityTests: Array<HumidityTestModel> = [];
  currentTests: Array<CurrentTestModel> = [];
  durabilityTests: Array<DurabilityTestModel> = [];
  heatResistanceTests: Array<HeatResistanceTestModel> = [];
  istmtTests: Array<IstmtTestModel> = [];
  rainTests: Array<RainTestModel> = [];
  otherTests: Array<OtherTestModel> = [];
  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;
  isFinish: boolean;
  userFinishId: number;
  userFinish: UserModel;
  finishDateTime: string;
  creationDateTime: string;
  lastUpdateDateTime: string;
  labOperatorElseRefId: number;
  detailElse: string;
  imageElse: string;
  fileElse: string;

  clear() {
    this.id = 0;
    this.laboratoryId = 0;
    this.laboratory = new LaboratoryModel();
    this.goniophotometers = [];
    this.sphere = [];
    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();
    this.isFinish = false;
    this.userFinishId = 0;
    this.userFinish = new UserModel();
    this.finishDateTime = '';
    this.creationDateTime = '';
    this.lastUpdateDateTime = '';
  }
}

export class GoniophotometerModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  goniophotometerLabTools: Array<GoniophotometerLabToolModel> = [];
  goniophotometerEquipments: Array<GoniophotometerEquipmentModel> = [];
  goniophotometereInprocess: GoniophotometeresInprocessModel;
  preTestRoomTemperature: string;
  preTestRoomVelocity: string;
  preControlRoomTemperature: string;
  preControlRoomHumidity: string;
  testConditionOthers: Array<TestConditionOthersModel> = [];
  testFile: File;
  testImageFile: string;
  lightsource: boolean;
  photometric: boolean;
  colorimetric: boolean;
  luminousShapeId: number;
  luminousShape: LuminousShapeModel;
  testDistance: number;
  postTestRoomTemperature: string;
  postTestRoomVelocity: string;
  postControlRoomTemperature: string;
  postControlRoomHumidity: string;
  goniophotometerElectricalLightsourceDatas: Array<ElectricalLightsourceDataModel> =
    [];
  goniophotometeElectricalGoniophotometeDatas: Array<ElectricalGoniophotometeDataModel> =
    [];
  // startTimeH: number;
  // endTimeH: number;
  // startTimeM: number;
  // endTimeM: number;
  lightsourceStartTime: string;
  lightsourceEndTimeH: string;
  photometricStartTime: string;
  photometricEndTimeM: string;

  lightsourceStartTemp: string;
  lightsourceEndTemp: string;
  photometricStartTemp: string;
  photometricEndTemp: string;

  summary: string;
  lumenPerLamp: string;
  comparison: string;
  catalog: string;
  startTemp: number;
  endTemp: number;

  cct: number;
  cri: number;
  xCoord: number;
  yCoord: number;
  uCoord: number;
  vCoord: number;
  duv: number;
  duv2: number;

  stabilizationTime: string;
  cPlane0Field: string;
  cPlane90Field: string;
  cPlane0Beam: string;
  cPlane90Beam: string;
  ps: string;
  lor: string;
  le: string;

  ppf: string;
  sdcm: string;
  ansi: string;
  rf: string;
  rg: string;

  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = null;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.goniophotometerLabTools = [];
    this.goniophotometerEquipments = [];
    this.goniophotometereInprocess = new GoniophotometeresInprocessModel();
    this.preTestRoomTemperature = '';
    this.preTestRoomVelocity = '';
    this.preControlRoomTemperature = '';
    this.preControlRoomHumidity = '';
    this.testConditionOthers = [];
    this.testFile = null;
    this.testImageFile = '';
    this.lightsource = false;
    this.photometric = false;
    this.colorimetric = false;
    this.luminousShapeId = 0;
    this.luminousShape = new LuminousShapeModel();
    this.testDistance = null;
    this.postTestRoomTemperature = '';
    this.postTestRoomVelocity = '';
    this.postControlRoomTemperature = '';
    this.postControlRoomHumidity = '';
    this.goniophotometerElectricalLightsourceDatas = [];
    this.goniophotometeElectricalGoniophotometeDatas = [];

    this.lightsourceStartTime = '';
    this.lightsourceEndTimeH = '';
    this.photometricStartTime = '';
    this.photometricEndTimeM = '';

    this.lightsourceStartTemp = '';
    this.lightsourceEndTemp = '';
    this.photometricStartTemp = '';
    this.photometricEndTemp = '';

    this.summary = '';
    this.lumenPerLamp = '';
    this.comparison = '';
    this.catalog = '';
    this.startTemp = null;
    this.endTemp = null;
    this.cct = null;
    this.cri = null;
    this.xCoord = null;
    this.yCoord = null;
    this.uCoord = null;
    this.vCoord = null;
    this.duv = null;
    this.duv2 = null;
    this.stabilizationTime = '';
    this.cPlane0Field = '';
    this.cPlane90Field = '';
    this.cPlane0Beam = '';
    this.cPlane90Beam = '';
    this.ps = '';
    this.lor = '';
    this.le = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultFile = null;
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class GoniophotometerLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  goniophotometerId: number;

  clear() {
    this.id = 0;
    this.qty = null;
    this.row = 0;
    this.topic = '';
    this.no = '';
    this.goniophotometerId = 0;
  }
}

export class GoniophotometerEquipmentModel {
  goniophotometerId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.goniophotometerId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class GoniophotometeresInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value7: string;
  value8: string;
  value9: string;
  isNormal7: boolean;
  isNormal8: boolean;
  isNormal9: boolean;
  goniophotometerId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value7 = '';
    this.value8 = '';
    this.value9 = '';
    this.isNormal7 = false;
    this.isNormal8 = false;
    this.isNormal9 = false;
    this.goniophotometerId = 0;
  }
}

export class TestInprocessModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.detail = '';
    this.creationDateTime = '';
  }
}

export class TestConditionOthersModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  goniophotometerId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.goniophotometerId = 0;
  }
}

export class TestSphereConditionOthersModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  sphereId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.sphereId = 0;
  }
}

export class LuminousShapeModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.detail = '';
    this.creationDateTime = '';
  }
}

export class ElectricalLightsourceDataModel {
  id: number;
  name: string;
  voltage: string;
  current: string;
  power: string;
  pf: string;
  frequency: string;
  thDv: string;
  thDi: string;
  lf: string;
  ps: string;
  orderItem: number;
  goniophotometerId: number;

  clear() {
    this.id = 0;
    this.name = '';
    this.voltage = '';
    this.current = '';
    this.power = '';
    this.pf = '';
    this.frequency = '';
    this.thDv = '';
    this.thDi = '';
    this.lf = '';
    this.ps = '';
    this.goniophotometerId = 0;
  }
}

export class ElectricalGoniophotometeDataModel {
  id: number;
  name: string;
  voltage: string;
  current: string;
  power: string;
  pf: string;
  frequency: string;
  thDv: string;
  thDi: string;
  lf: string;
  orderItem: number;
  goniophotometerId: number;

  clear() {
    this.id = 0;
    this.name = '';
    this.voltage = '';
    this.current = '';
    this.power = '';
    this.pf = '';
    this.frequency = '';
    this.thDv = '';
    this.thDi = '';
    this.lf = '';
    this.orderItem = 0;
    this.goniophotometerId = 0;
  }
}

export class SphereModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  sphereLabTools: Array<GoniophotometerToolModel> = [];
  sphereEquipments: Array<SphereEquipmentModel> = [];
  sphereInprocess: TestStandardTestInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  testConditionOthers: Array<TestSphereConditionOthersModel> = [];
  testFile: File;
  testImageFile: string;
  measurementType: number;
  geometry: number;
  testMethod: number;
  temperatureProbe: number;
  operatingTimePrior: number;
  stabilization: number;
  totalTime: number;
  electricalDatas: Array<ElectricalDataModel> = [];
  photometricMeasurements: Array<PhotometricMeasurementModel> = [];
  chromaticityCoordinates: Array<ChromaticityCoordinateModel> = [];
  postRoomTemperature: string;
  postRoomHumidity: string;
  ppF1: string;
  ppF2: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.sphereLabTools = [];
    this.sphereEquipments = [];
    this.sphereInprocess = new TestStandardTestInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.testConditionOthers = [];
    this.testFile = null;
    this.testImageFile = '';
    this.measurementType = null;
    this.geometry = null;
    this.testMethod = null;
    this.temperatureProbe = null;
    this.operatingTimePrior = null;
    this.stabilization = null;
    this.totalTime = null;
    this.electricalDatas = [];
    this.photometricMeasurements = [];
    this.chromaticityCoordinates = [];
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultFile = null;
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class GoniophotometerToolModel {
  id: number;
  topic: string;
  row: number;
  no: string;
  qty: number;
  sphereId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.sphereId = 0;
  }
}

export class SphereEquipmentModel {
  sphereId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.sphereId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class TestStandardTestInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value7: string;
  value8: string;
  value9: string;
  isNormal7: boolean;
  isNormal8: boolean;
  isNormal9: boolean;
  sphereId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value7 = '';
    this.value8 = '';
    this.value9 = '';
    this.isNormal7 = false;
    this.isNormal8 = false;
    this.isNormal9 = false;
    this.sphereId = 0;
  }
}

export class ElectricalDataModel {
  id: number;
  voltage: string;
  current: string;
  power: string;
  pf: string;
  frequency: string;
  thDv: string;
  thDi: string;
  sphereId: number;

  clear() {
    this.id = 0;
    this.voltage = '';
    this.current = '';
    this.power = '';
    this.pf = '';
    this.frequency = '';
    this.thDv = '';
    this.thDi = '';
    this.sphereId = 0;
  }
}

export class PhotometricMeasurementModel {
  id: number;
  liminousFlux: string;
  luminousEfficacy: string;
  cct: string;
  cri: string;
  xCoord: string;
  yCoord: string;
  uCoord: string;
  vCoord: string;
  sphereId: number;

  clear() {
    this.id = 0;
    this.liminousFlux = '';
    this.luminousEfficacy = '';
    this.cct = '';
    this.cri = '';
    this.xCoord = '';
    this.yCoord = '';
    this.uCoord = '';
    this.vCoord = '';
    this.sphereId = 0;
  }
}

export class ChromaticityCoordinateModel {
  id: number;
  totalRadiantPower: string;
  peakWavelength: string;
  sRatio: string;
  duV: string;
  dominantWavelength: string;
  uCoord: string;
  vCoord: string;
  sphereId: number;

  clear() {
    this.id = 0;
    this.totalRadiantPower = '';
    this.peakWavelength = '';
    this.sRatio = '';
    this.duV = '';
    this.dominantWavelength = '';
    this.uCoord = '';
    this.vCoord = '';
    this.sphereId = 0;
  }
}

export class FSTNumericalModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  fstNumericalLabTools: Array<FSTNumericalLabToolModel> = [];
  fstNumericalEquipments: Array<FSTNumericalEquipmentModel> = [];
  fstNumericalInprocess: FSTNumericalInprocessModel;
  preTestRoomTemperature: string;
  preTestRoomVelocity: string;
  preControlRoomTemperature: string;
  preControlRoomHumidity: string;
  fstNumericalTestConditionOthers: Array<FSTNumericalTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  accProtectionTest: boolean;
  bodyIP2XIP3XIP4XTest: boolean;
  bodyIP5XIP6XTest: boolean;
  postTestRoomTemperature: string;
  postTestRoomVelocity: string;
  postControlRoomTemperature: string;
  postControlRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  accProtectionTestVACInput: string;
  accProtectionTestVACOutput: string;
  accProtectionTestN10: string;
  accProtectionTestTouch: number;
  accProtectionTestResult: number;
  accProtectionTestComment: string;

  bodyIP2XIP3XIP4XTestVACInput: string;
  bodyIP2XIP3XIP4XTestVACOutput: string;
  bodyIP2XIP3XIP4XTestN10: string;
  bodyIP2XIP3XIP4XTestTouch: number;
  bodyIP2XIP3XIP4XTestResult: number;
  bodyIP2XIP3XIP4XTestComment: string;

  bodyIP5XIP6XTestTypePattern: number;
  bodyIP5XIP6XTestCheckPattern: number;
  bodyIP5XIP6XTestTncubateTime: number;
  bodyIP5XIP6XTestTemp: number;
  bodyIP5XIP6XTestTime: number;
  bodyIP5XIP6XTestCondition: string;
  bodyIP5XIP6XTestResult: number;
  bodyIP5XIP6XTestComment: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.fstNumericalLabTools = [];
    this.fstNumericalEquipments = [];
    this.fstNumericalInprocess = new FSTNumericalInprocessModel();
    this.preTestRoomTemperature = '';
    this.preTestRoomVelocity = '';
    this.preControlRoomTemperature = '';
    this.preControlRoomHumidity = '';
    this.fstNumericalTestConditionOthers = [];
    this.testImageFile = '';
    this.accProtectionTest = false;
    this.bodyIP2XIP3XIP4XTest = false;
    this.bodyIP5XIP6XTest = false;
    this.postTestRoomTemperature = '';
    this.postTestRoomVelocity = '';
    this.postControlRoomTemperature = '';
    this.postControlRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';

    this.accProtectionTestVACInput = '';
    this.accProtectionTestVACOutput = '';
    this.accProtectionTestN10 = '';
    this.accProtectionTestTouch = null;
    this.accProtectionTestResult = null;
    this.accProtectionTestComment = '';
    this.bodyIP2XIP3XIP4XTestVACInput = '';
    this.bodyIP2XIP3XIP4XTestVACOutput = '';
    this.bodyIP2XIP3XIP4XTestN10 = '';
    this.bodyIP2XIP3XIP4XTestTouch = null;
    this.bodyIP2XIP3XIP4XTestResult = null;
    this.bodyIP2XIP3XIP4XTestComment = '';
    this.bodyIP5XIP6XTestTypePattern = null;
    this.bodyIP5XIP6XTestCheckPattern = null;
    this.bodyIP5XIP6XTestTncubateTime = null;
    this.bodyIP5XIP6XTestTemp = null;
    this.bodyIP5XIP6XTestTime = null;
    this.bodyIP5XIP6XTestCondition = '';
    this.bodyIP5XIP6XTestResult = null;
    this.bodyIP5XIP6XTestComment = '';
  }
}

export class FSTNumericalLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  fstNumericalId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.fstNumericalId = 0;
  }
}

export class FSTNumericalEquipmentModel {
  fstNumericalId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;
  orderNo: number;

  clear() {
    this.fstNumericalId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class FSTNumericalInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  isNormal7: boolean;
  value5: string;
  value6: string;
  value7: string;
  fstNumericalId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.isNormal7 = false;
    this.value5 = '';
    this.value6 = '';
    this.value7 = '';
    this.fstNumericalId = 0;
  }
}

export class FSTNumericalTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  fstNumericalId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.fstNumericalId = 0;
  }
}

export class SSTNumericalModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  sstNumericalLabTools: Array<SSTNumericalLabToolModel> = [];
  sstNumericalEquipments: Array<SSTNumericalEquipmentModel> = [];
  sstNumericalInprocess: SSTNumericalInprocessModel;
  preTestRoomTemperature: string;
  preTestRoomVelocity: string;
  preControlRoomTemperature: string;
  preControlRoomHumidity: string;
  sstNumericalTestConditionOthers: Array<SSTNumericalTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  waterTestIPX3IPX4: boolean;
  waterTestIPX5IPX6: boolean;
  waterTestIPX7IPX8: boolean;
  waterTestIPX9K: boolean;
  dielectricTest: boolean;
  postTestRoomTemperature: string;
  postTestRoomVelocity: string;
  postControlRoomTemperature: string;
  postControlRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  waterTestIPX3IPX4SprayDist: number;
  waterTestIPX3IPX4FlowRate: string;
  waterTestIPX3IPX4Time: number;
  waterTestIPX3IPX4Result: number;
  waterTestIPX3IPX4Comment: string;

  waterTestIPX5IPX6SampleTemp: number;
  waterTestIPX5IPX6Temp: string;
  waterTestIPX5IPX6Time: number;
  waterTestIPX5IPX6FlowRate: string;
  waterTestIPX5IPX6Pressure: string;
  waterTestIPX5IPX6Result: number;
  waterTestIPX5IPX6Comment: string;

  waterTestIPX7IPX8Speed: string;
  waterTestIPX7IPX8Position: string;
  waterTestIPX7IPX8SprayDistant: string;
  waterTestIPX7IPX8Pressure: string;
  waterTestIPX7IPX8Temp: string;
  waterTestIPX7IPX8FlowRate: number;
  waterTestIPX7IPX8Time: number;
  waterTestIPX7IPX8Result: number;
  waterTestIPX7IPX8Comment: string;

  waterTestIDielectricSampleType: number;
  waterTestIDielectricSampleVoltage: string;
  waterTestIDielectricVoltage: string;
  waterTestIDielectricResult: number;
  waterTestIDielectricComment: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.sstNumericalLabTools = [];
    this.sstNumericalEquipments = [];
    this.sstNumericalInprocess = new SSTNumericalInprocessModel();
    this.preTestRoomTemperature = '';
    this.preTestRoomVelocity = '';
    this.preControlRoomTemperature = '';
    this.preControlRoomHumidity = '';
    this.sstNumericalTestConditionOthers = [];
    this.testImageFile = '';
    this.waterTestIPX3IPX4 = false;
    this.waterTestIPX5IPX6 = false;
    this.waterTestIPX7IPX8 = false;
    this.waterTestIPX9K = false;
    this.dielectricTest = false;
    this.postTestRoomTemperature = '';
    this.postTestRoomVelocity = '';
    this.postControlRoomTemperature = '';
    this.postControlRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';

    this.waterTestIPX3IPX4SprayDist = null;
    this.waterTestIPX3IPX4FlowRate = '';
    this.waterTestIPX3IPX4Time = null;
    this.waterTestIPX3IPX4Result = null;
    this.waterTestIPX3IPX4Comment = '';
    this.waterTestIPX5IPX6SampleTemp = null;
    this.waterTestIPX5IPX6Temp = '';
    this.waterTestIPX5IPX6Time = null;
    this.waterTestIPX5IPX6FlowRate = '';
    this.waterTestIPX5IPX6Pressure = '';
    this.waterTestIPX5IPX6Result = null;
    this.waterTestIPX5IPX6Comment = '';
    this.waterTestIPX7IPX8Speed = '';
    this.waterTestIPX7IPX8Position = '';
    this.waterTestIPX7IPX8SprayDistant = '';
    this.waterTestIPX7IPX8Pressure = '';
    this.waterTestIPX7IPX8Temp = '';
    this.waterTestIPX7IPX8FlowRate = null;
    this.waterTestIPX7IPX8Time = null;
    this.waterTestIPX7IPX8Result = null;
    this.waterTestIPX7IPX8Comment = '';
    this.waterTestIDielectricSampleType = null;
    this.waterTestIDielectricSampleVoltage = '';
    this.waterTestIDielectricVoltage = '';
    this.waterTestIDielectricResult = null;
    this.waterTestIDielectricComment = '';
  }
}

export class SSTNumericalLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  sstNumericalId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.sstNumericalId = 0;
  }
}

export class SSTNumericalEquipmentModel {
  sstNumericalId: number;
  equipmentId: number;
  orderNo: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.sstNumericalId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class SSTNumericalInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  isNormal7: boolean;
  isNormal8: boolean;
  isNormal9: boolean;
  isNormal10: boolean;
  value8: string;
  value9: string;
  value10: string;
  sstNumericalId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.isNormal7 = false;
    this.isNormal8 = false;
    this.isNormal9 = false;
    this.isNormal10 = false;
    this.value8 = '';
    this.value9 = '';
    this.value10 = '';
    this.sstNumericalId = 0;
  }
}

export class SSTNumericalTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  sstNumericalId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.sstNumericalId = 0;
  }
}

export class ShockProtectionTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  shockProtectionTestLabTools: Array<ShockProtectionTestLabToolModel> = [];
  shockProtectionTestEquipments: Array<ShockProtectionTestEquipmentModel> = [];
  shockProtectionTestInprocess: ShockProtectionTestInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  shockProtectionTestTestConditionOthers: Array<ShockProtectionTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  shockProtectionTestResult: ShockProtectionTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.shockProtectionTestLabTools = [];
    this.shockProtectionTestEquipments = [];
    this.shockProtectionTestInprocess = new ShockProtectionTestInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.shockProtectionTestTestConditionOthers = [];
    this.testImageFile = '';
    this.shockProtectionTestResult = new ShockProtectionTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class ShockProtectionTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  shockProtectionTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.shockProtectionTestId = null;
  }
}

export class ShockProtectionTestEquipmentModel {
  shockProtectionTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.shockProtectionTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class ShockProtectionTestInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  isNormal7: boolean;
  value4: string;
  value5: string;
  value6: string;
  value7: string;
  shockProtectionTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.isNormal7 = false;
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.value7 = '';
    this.shockProtectionTestId = 0;
  }
}

export class ShockProtectionTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  shockProtectionTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.shockProtectionTestId = 0;
  }
}

export class ShockProtectionTestResultModel {
  id: number;
  resultNote1: string;
  result1: number;
  resultNote2: string;
  result2: number;
  resultNote3: string;
  result3: number;
  resultNote4: string;
  result4: number;
  resultNote5: string;
  result5: number;
  resultNote6: string;
  result6: number;
  resultNote7: string;
  result7: number;
  resultNote8: string;
  result8: number;
  resultNote9: string;
  result9: number;
  resultNote10: string;
  result10: number;
  resultNote11: string;
  result11: number;
  resultNote12: string;
  result12: number;
  resultNote13: string;
  result13: number;
  resultNote14: string;
  result14: number;
  resultNote15: string;
  result15: number;
  resultNote16: string;
  result16: number;
  resultNote17: string;
  result17: number;
  resultNote18: string;
  result18: number;
  resultNote19: string;
  result19: number;
  resultNote20: string;
  result20: number;
  resultNote21: string;
  result21: number;
  resultNote22: string;
  result22: number;
  resultNote23: string;
  result23: number;
  resultNote24: string;
  result24: number;
  resultNote25: string;
  result25: number;
  resultNote26: string;
  result26: number;
  resultNote27: string;
  result27: number;
  resultNote28: string;
  result28: number;
  shockProtectionTestId: number;

  clear() {
    this.id = 0;
    this.resultNote1 = '';
    this.result1 = null;
    this.resultNote2 = '';
    this.result2 = null;
    this.resultNote3 = '';
    this.result3 = null;
    this.resultNote4 = '';
    this.result4 = null;
    this.resultNote5 = '';
    this.result5 = null;
    this.resultNote6 = '';
    this.result6 = null;
    this.resultNote7 = '';
    this.result7 = null;
    this.resultNote8 = '';
    this.result8 = null;
    this.resultNote9 = '';
    this.result9 = null;
    this.resultNote10 = '';
    this.result10 = null;
    this.resultNote11 = '';
    this.result11 = null;
    this.resultNote12 = '';
    this.result12 = null;
    this.resultNote13 = '';
    this.result13 = null;
    this.resultNote14 = '';
    this.result14 = null;
    this.resultNote15 = '';
    this.result15 = null;
    this.resultNote16 = '';
    this.result16 = null;
    this.resultNote17 = '';
    this.result17 = null;
    this.resultNote18 = '';
    this.result18 = null;
    this.resultNote19 = '';
    this.result19 = null;
    this.resultNote20 = '';
    this.result20 = null;
    this.resultNote21 = '';
    this.result21 = null;
    this.resultNote22 = '';
    this.result22 = null;
    this.resultNote23 = '';
    this.result23 = null;
    this.resultNote24 = '';
    this.result24 = null;
    this.resultNote25 = '';
    this.result25 = null;
    this.resultNote26 = '';
    this.result26 = null;
    this.resultNote27 = '';
    this.result27 = null;
    this.resultNote28 = '';
    this.result28 = null;
    this.shockProtectionTestId = 0;
  }
}

export class InsulationElectricalResistanceTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  insulationElectricalResistanceTestLabTools: Array<InsulationElectricalResistanceTestLabToolModel> =
    [];
  insulationElectricalResistanceTestEquipments: Array<InsulationElectricalResistanceTestEquipmentModel> =
    [];
  insulationElectricalResistanceTestInprocess: InsulationElectricalResistanceTestInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  insulationElectricalResistanceTestTestConditionOthers: Array<InsulationElectricalResistanceTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  insulationElectricalResistanceTestResult: InsulationElectricalResistanceTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.insulationElectricalResistanceTestLabTools = [];
    this.insulationElectricalResistanceTestEquipments = [];
    this.insulationElectricalResistanceTestInprocess =
      new InsulationElectricalResistanceTestInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.insulationElectricalResistanceTestTestConditionOthers = [];
    this.testImageFile = '';
    this.insulationElectricalResistanceTestResult =
      new InsulationElectricalResistanceTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class InsulationElectricalResistanceTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  insulationElectricalResistanceTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.insulationElectricalResistanceTestId = 0;
  }
}

export class InsulationElectricalResistanceTestEquipmentModel {
  insulationElectricalResistanceTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.insulationElectricalResistanceTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class InsulationElectricalResistanceTestInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  value3: string;
  value4: string;
  value5: string;
  insulationElectricalResistanceTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.value3 = '';
    this.value4 = '';
    this.value5 = '';
    this.insulationElectricalResistanceTestId = 0;
  }
}

export class InsulationElectricalResistanceTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  insulationElectricalResistanceTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.insulationElectricalResistanceTestId = 0;
  }
}

export class InsulationElectricalResistanceTestResultModel {
  id: number;
  insulationResultNote1: string;
  insulationResult1: number;
  insulationResultNote2: string;
  insulationResult2: number;
  insulationResultNote3: string;
  insulationResult3: number;
  insulationResultNote4: string;
  insulationResult4: number;
  insulationResultNote5: string;
  insulationResult5: number;
  insulationResultNote6: string;
  insulationResult6: number;
  insulationResultNote7: string;
  insulationResult7: number;
  insulationResultNote8: string;
  insulationResult8: number;
  insulationResultNote9: string;
  insulationResult9: number;
  electricalResultNote1: string;
  electricalResult1: number;
  electricalResultNote2: string;
  electricalResult2: number;
  electricalResultNote3: string;
  electricalResult3: number;
  electricalResultNote4: string;
  electricalResult4: number;
  electricalResultNote5: string;
  electricalResult5: number;
  electricalResultNote6: string;
  electricalResult6: number;
  electricalResultNote7: string;
  electricalResult7: number;
  electricalResultNote8: string;
  electricalResult8: number;
  electricalResultNote9: string;
  electricalResult9: number;
  electricalResultNote10: string;
  electricalResult10: number;
  electricalResultNote11: string;
  electricalResult11: number;
  electricalResultNote12: string;
  electricalResult12: number;
  electricalResultNote13: string;
  electricalResult13: number;
  insulationElectricalResistanceTestId: number;

  clear() {
    this.id = 0;
    this.insulationResultNote1 = '';
    this.insulationResult1 = null;
    this.insulationResultNote2 = '';
    this.insulationResult2 = null;
    this.insulationResultNote3 = '';
    this.insulationResult3 = null;
    this.insulationResultNote4 = '';
    this.insulationResult4 = null;
    this.insulationResultNote5 = '';
    this.insulationResult5 = null;
    this.insulationResultNote6 = '';
    this.insulationResult6 = null;
    this.insulationResultNote7 = '';
    this.insulationResult7 = null;
    this.insulationResultNote8 = '';
    this.insulationResult8 = null;
    this.insulationResultNote9 = '';
    this.insulationResult9 = null;
    this.electricalResultNote1 = '';
    this.electricalResult1 = null;
    this.electricalResultNote2 = '';
    this.electricalResult2 = null;
    this.electricalResultNote3 = '';
    this.electricalResult3 = null;
    this.electricalResultNote4 = '';
    this.electricalResult4 = null;
    this.electricalResultNote5 = '';
    this.electricalResult5 = null;
    this.electricalResultNote6 = '';
    this.electricalResult6 = null;
    this.electricalResultNote7 = '';
    this.electricalResult7 = null;
    this.electricalResultNote8 = '';
    this.electricalResult8 = null;
    this.electricalResultNote9 = '';
    this.electricalResult9 = null;
    this.electricalResultNote10 = '';
    this.electricalResult10 = null;
    this.electricalResultNote11 = '';
    this.electricalResult11 = null;
    this.insulationElectricalResistanceTestId = 0;
  }
}

export class HumidityTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  humidityTestLabTools: Array<HumidityTestLabToolModel> = [];
  humidityTestEquipments: Array<HumidityTestEquipmentModel> = [];
  humidityTestInprocess: HumidityTestInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  humidityTestTestConditionOthers: Array<HumidityTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  humidityTestResult: HumidityTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.humidityTestLabTools = [];
    this.humidityTestEquipments = [];
    this.humidityTestInprocess = new HumidityTestInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.humidityTestTestConditionOthers = [];
    this.testImageFile = '';
    this.humidityTestResult = new HumidityTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class HumidityTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  humidityTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.humidityTestId = 0;
  }
}

export class HumidityTestEquipmentModel {
  humidityTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.humidityTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class HumidityTestInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  value3: string;
  value4: string;
  value5: string;
  humidityTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.value3 = '';
    this.value4 = '';
    this.value5 = '';
    this.humidityTestId = 0;
  }
}

export class HumidityTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  humidityTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.humidityTestId = 0;
  }
}

export class HumidityTestResultModel {
  id: number;
  humidity48Hrs: string;
  temperatureChamber: string;
  humidityChamber: string;
  curingTimeHrs: string;
  startTestDateTime: string;
  endTestDateTime: string;
  vacTest: number;
  insulationResistance: string;
  vacResult: string;
  vacTouchCurrent: string;
  protectiveConductor: number;
  humidityTestId: number;

  clear() {
    this.id = 0;
    this.humidity48Hrs = '';
    this.temperatureChamber = '';
    this.humidityChamber = '';
    this.curingTimeHrs = '';
    this.startTestDateTime = '';
    this.endTestDateTime = '';
    this.vacTest = null;
    this.insulationResistance = '';
    this.vacResult = null;
    this.vacTouchCurrent = null;
    this.protectiveConductor = null;
    this.humidityTestId = 0;
  }
}

export class CurrentTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  currentTestLabTools: Array<CurrentTestLabToolModel> = [];
  currentTestEquipments: Array<CurrentTestEquipmentModel> = [];
  currentTestInprocess: CurrentTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  currentTestTestConditionOthers: Array<CurrentTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  currentTestResult: CurrentTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.currentTestLabTools = [];
    this.currentTestEquipments = [];
    this.currentTestInprocess = new CurrentTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.currentTestTestConditionOthers = [];
    this.testImageFile = '';
    this.currentTestResult = new CurrentTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class CurrentTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  currentTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.currentTestId = 0;
  }
}

export class CurrentTestEquipmentModel {
  currentTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.currentTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class CurrentTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value4: string;
  value5: string;
  value6: string;
  currentTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.currentTestId = 0;
  }
}

export class CurrentTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  humidityTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.humidityTestId = 0;
  }
}

export class CurrentTestResultModel {
  id: number;
  isElectricLampAll: boolean;
  isElectricLampLess16: boolean;
  isElectricLampThan16: boolean;
  voltageResult: string;
  voltageSample: string;
  isMetal: boolean;
  metalL: string;
  metalN: string;
  isMetalFlame: boolean;
  metalFlameL: string;
  metalFlameN: string;
  isElectricLampLLess32A: boolean;
  isElectricLampLLess32ACurrentLess4: boolean;
  isElectricLampLLess32ACurrent4To10: boolean;
  isElectricLampLLess32ACurrentThan10: boolean;
  isElectricLampLPermanent: boolean;
  isElectricLampLPermanentCurrentLess7: boolean;
  isElectricLampLPermanentCurrent7To20: boolean;
  isElectricLampLPermanentACurrentThan20: boolean;
  isDefenseMetal: boolean;
  defenseMetalL: string;
  defenseMetalN: string;
  isDefenseMetalFlame: boolean;
  defenseMetalFlameL: string;
  defenseMetalFlameN: string;
  currentTestId: number;

  clear() {
    this.id = 0;
    this.isElectricLampAll = false;
    this.isElectricLampLess16 = false;
    this.isElectricLampThan16 = false;
    this.voltageResult = '';
    this.voltageSample = '';
    this.isMetal = false;
    this.metalL = '';
    this.metalN = '';
    this.isMetalFlame = false;
    this.metalFlameL = '';
    this.metalFlameN = '';
    this.isElectricLampLLess32A = false;
    this.isElectricLampLLess32ACurrentLess4 = false;
    this.isElectricLampLLess32ACurrent4To10 = false;
    this.isElectricLampLLess32ACurrentThan10 = false;
    this.isElectricLampLPermanent = false;
    this.isElectricLampLPermanentCurrentLess7 = false;
    this.isElectricLampLPermanentCurrent7To20 = false;
    this.isElectricLampLPermanentACurrentThan20 = false;
    this.isDefenseMetal = false;
    this.defenseMetalL = '';
    this.defenseMetalN = '';
    this.isDefenseMetalFlame = false;
    this.defenseMetalFlameL = '';
    this.defenseMetalFlameN = '';
    this.currentTestId = 0;
  }
}

export class DurabilityTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  durabilityTestLabTools: Array<DurabilityTestLabToolModel> = [];
  durabilityTestEquipments: Array<DurabilityTestEquipmentModel> = [];
  durabilityTestsInproces: DurabilityTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  durabilityTestTestConditionOthers: Array<DurabilityTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  durabilityTestResult: DurabilityTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.durabilityTestLabTools = [];
    this.durabilityTestEquipments = [];
    this.durabilityTestsInproces = new DurabilityTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.durabilityTestTestConditionOthers = [];
    this.testImageFile = '';
    this.durabilityTestResult = new DurabilityTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class DurabilityTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  durabilityTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.durabilityTestId = 0;
  }
}

export class DurabilityTestEquipmentModel {
  durabilityTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.durabilityTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class DurabilityTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  value3: string;
  value4: string;
  value5: string;
  durabilityTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.value3 = '';
    this.value4 = '';
    this.value5 = '';
    this.durabilityTestId = 0;
  }
}

export class DurabilityTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  durabilityTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.durabilityTestId = 0;
  }
}

export class DurabilityTestResultModel {
  id: number;
  resultNote1: string;
  result1: number;
  resultNote2: string;
  result2: number;
  resultNote3: string;
  result3: number;
  resultNote4: string;
  result4: number;
  resultNote5: string;
  result5: number;
  resultNote6: string;
  result6: number;
  resultNote7: string;
  result7: number;
  resultNote8: string;
  result8: number;
  resultNote9: string;
  result9: number;
  resultNote10: string;
  result10: number;
  resultNote11: string;
  result11: number;
  currentTestId: number;

  clear() {
    this.id = 0;
    this.resultNote1 = '';
    this.result1 = null;
    this.resultNote2 = '';
    this.result2 = null;
    this.resultNote3 = '';
    this.result3 = null;
    this.resultNote4 = '';
    this.result4 = null;
    this.resultNote5 = '';
    this.result5 = null;
    this.resultNote6 = '';
    this.result6 = null;
    this.resultNote7 = '';
    this.result7 = null;
    this.resultNote8 = '';
    this.result8 = null;
    this.resultNote9 = '';
    this.result9 = null;
    this.resultNote10 = '';
    this.result10 = null;
    this.resultNote11 = '';
    this.result11 = null;
    this.currentTestId = 0;
  }
}

export class HeatResistanceTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  heatResistanceTestLabTools: Array<HeatResistanceTestLabToolModel> = [];
  heatResistanceTestEquipments: Array<HeatResistanceTestEquipmentModel> = [];
  heatResistanceTestInprocess: HeatResistanceTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  heatResistanceTestTestConditionOthers: Array<HeatResistanceTestTestConditionOtherModel> =
    [];
  testFile: File;
  testImageFile: string;
  heatResistanceTestResult: HeatResistanceTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.heatResistanceTestLabTools = [];
    this.heatResistanceTestEquipments = [];
    this.heatResistanceTestInprocess = new HeatResistanceTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.heatResistanceTestTestConditionOthers = [];
    this.testImageFile = '';
    this.heatResistanceTestResult = new HeatResistanceTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class HeatResistanceTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  heatResistanceTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.heatResistanceTestId = 0;
  }
}

export class HeatResistanceTestEquipmentModel {
  heatResistanceTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.heatResistanceTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class HeatResistanceTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value4: string;
  value5: string;
  value6: string;
  durabilityTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.durabilityTestId = 0;
  }
}

export class HeatResistanceTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  heatResistanceTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.heatResistanceTestId = 0;
  }
}

export class HeatResistanceTestResultModel {
  id: number;
  lightsource: number;
  ledThermocouple: string;
  ledThermocoupleNo: string;
  ledNormalSpecifiedMaxValue: string;
  ledNormalMeasuredVaule: string;
  ledAbnormalSpecifiedMaxValue: string;
  ledAbnormalMeasuredVaule: string;
  driverBallastTransformerThermocouple: string;
  driverBallastTransformerThermocoupleNo: string;
  driverBallastTransformerNormalSpecifiedMaxValue: string;
  driverBallastTransformerNormalMeasuredVaule: string;
  driverBallastTransformerAbnormalSpecifiedMaxValue: string;
  driverBallastTransformerAbnormalMeasuredVaule: string;
  lampCapThermocouple: string;
  lampCapThermocoupleNo: string;
  lampCapNormalSpecifiedMaxValue: string;
  lampCapNormalMeasuredVaule: string;
  lampCapAbnormalSpecifiedMaxValue: string;
  lampCapAbnormalMeasuredVaule: string;
  lampHolderThermocouple: string;
  lampHolderThermocoupleNo: string;
  lampHolderNormalSpecifiedMaxValue: string;
  lampHolderNormalMeasuredVaule: string;
  lampHolderAbnormalSpecifiedMaxValue: string;
  lampHolderAbnormalMeasuredVaule: string;
  capacitorThermocouple: string;
  capacitorThermocoupleNo: string;
  capacitorNormalSpecifiedMaxValue: string;
  capacitorNormalMeasuredVaule: string;
  capacitorAbnormalSpecifiedMaxValue: string;
  capacitorAbnormalMeasuredVaule: string;
  ignitorThermocouple: string;
  ignitorThermocoupleNo: string;
  ignitorNormalSpecifiedMaxValue: string;
  ignitorNormalMeasuredVaule: string;
  ignitorAbnormalSpecifiedMaxValue: string;
  ignitorAbnormalMeasuredVaule: string;
  internalWiringThermocouple: string;
  internalWiringThermocoupleNo: string;
  internalWiringNormalSpecifiedMaxValue: string;
  internalWiringNormalMeasuredVaule: string;
  internalWiringAbnormalSpecifiedMaxValue: string;
  internalWiringAbnormalMeasuredVaule: string;
  externalWiringThermocouple: string;
  externalWiringThermocoupleNo: string;
  externalWiringNormalSpecifiedMaxValue: string;
  externalWiringNormalMeasuredVaule: string;
  externalWiringAbnormalSpecifiedMaxValue: string;
  externalWiringAbnormalMeasuredVaule: string;
  heatSinkThermocouple: string;
  heatSinkThermocoupleNo: string;
  heatSinkNormalSpecifiedMaxValue: string;
  heatSinkNormalMeasuredVaule: string;
  heatSinkAbnormalSpecifiedMaxValue: string;
  heatSinkAbnormalMeasuredVaule: string;
  terminalThermocouple: string;
  terminalThermocoupleNo: string;
  terminalNormalSpecifiedMaxValue: string;
  terminalNormalMeasuredVaule: string;
  terminalAbnormalSpecifiedMaxValue: string;
  terminalAbnormalMeasuredVaule: string;
  bodyThermocouple: string;
  bodyThermocoupleNo: string;
  bodyNormalSpecifiedMaxValue: string;
  bodyNormalMeasuredVaule: string;
  bodyAbnormalSpecifiedMaxValue: string;
  bodyAbnormalMeasuredVaule: string;
  diffuserThermocouple: string;
  diffuserThermocoupleNo: string;
  diffuserNormalSpecifiedMaxValue: string;
  diffuserNormalMeasuredVaule: string;
  diffuserAbnormalSpecifiedMaxValue: string;
  diffuserAbnormalMeasuredVaule: string;
  reflectorThermocouple: string;
  reflectorThermocoupleNo: string;
  reflectorNormalSpecifiedMaxValue: string;
  reflectorNormalMeasuredVaule: string;
  reflectorAbnormalSpecifiedMaxValue: string;
  reflectorAbnormalMeasuredVaule: string;
  coolingFanThermocouple: string;
  coolingFanThermocoupleNo: string;
  coolingFanNormalSpecifiedMaxValue: string;
  coolingFanNormalMeasuredVaule: string;
  coolingFanAbnormalSpecifiedMaxValue: string;
  coolingFanAbnormalMeasuredVaule: string;
  socketPlugThermocouple: string;
  socketPlugThermocoupleNo: string;
  socketPlugNormalSpecifiedMaxValue: string;
  socketPlugNormalMeasuredVaule: string;
  socketPlugAbnormalSpecifiedMaxValue: string;
  socketPlugAbnormalMeasuredVaule: string;
  insideLuminaireThermocouple: string;
  insideLuminaireThermocoupleNo: string;
  insideLuminaireNormalSpecifiedMaxValue: string;
  insideLuminaireNormalMeasuredVaule: string;
  insideLuminaireAbnormalSpecifiedMaxValue: string;
  insideLuminaireAbnormalMeasuredVaule: string;
  ambientThermocouple: string;
  ambientThermocoupleNo: string;
  ambientNormalSpecifiedMaxValue: string;
  ambientNormalMeasuredVaule: string;
  ambientAbnormalSpecifiedMaxValue: string;
  ambientAbnormalMeasuredVaule: string;
  other1Name: string;
  other1ThermocoupleNo: string;
  other1NormalSpecifiedMaxValue: string;
  other1NormalMeasuredVaule: string;
  other1AbnormalSpecifiedMaxValue: string;
  other1AbnormalMeasuredVaule: string;
  other2Name: string;
  other2ThermocoupleNo: string;
  other2NormalSpecifiedMaxValue: string;
  other2NormalMeasuredVaule: string;
  other2AbnormalSpecifiedMaxValue: string;
  other2AbnormalMeasuredVaule: string;
  other3Name: string;
  other3ThermocoupleNo: string;
  other3NormalSpecifiedMaxValue: string;
  other3NormalMeasuredVaule: string;
  other3AbnormalSpecifiedMaxValue: string;
  other3AbnormalMeasuredVaule: string;
  other4Name: string;
  other4ThermocoupleNo: string;
  other4NormalSpecifiedMaxValue: string;
  other4NormalMeasuredVaule: string;
  other4AbnormalSpecifiedMaxValue: string;
  other4AbnormalMeasuredVaule: string;
  other5Name: string;
  other5ThermocoupleNo: string;
  other5NormalSpecifiedMaxValue: string;
  other5NormalMeasuredVaule: string;
  other5AbnormalSpecifiedMaxValue: string;
  other5AbnormalMeasuredVaule: string;
  other6Name: string;
  other6ThermocoupleNo: string;
  other6NormalSpecifiedMaxValue: string;
  other6NormalMeasuredVaule: string;
  other6AbnormalSpecifiedMaxValue: string;
  other6AbnormalMeasuredVaule: string;
  other7Name: string;
  other7ThermocoupleNo: string;
  other7NormalSpecifiedMaxValue: string;
  other7NormalMeasuredVaule: string;
  other7AbnormalSpecifiedMaxValue: string;
  other7AbnormalMeasuredVaule: string;
  heatResistanceTestId: number;

  clear() {
    this.id = 0;
    this.lightsource = null;
    this.ledThermocouple = '';
    this.ledThermocoupleNo = '';
    this.ledNormalSpecifiedMaxValue = '';
    this.ledNormalMeasuredVaule = '';
    this.ledAbnormalSpecifiedMaxValue = '';
    this.ledAbnormalMeasuredVaule = '';
    this.driverBallastTransformerThermocouple = '';
    this.driverBallastTransformerThermocoupleNo = '';
    this.driverBallastTransformerNormalSpecifiedMaxValue = '';
    this.driverBallastTransformerNormalMeasuredVaule = '';
    this.driverBallastTransformerAbnormalSpecifiedMaxValue = '';
    this.driverBallastTransformerAbnormalMeasuredVaule = '';
    this.lampCapThermocouple = '';
    this.lampCapThermocoupleNo = '';
    this.lampCapNormalSpecifiedMaxValue = '';
    this.lampCapNormalMeasuredVaule = '';
    this.lampCapAbnormalSpecifiedMaxValue = '';
    this.lampCapAbnormalMeasuredVaule = '';
    this.lampHolderThermocouple = '';
    this.lampHolderThermocoupleNo = '';
    this.lampHolderNormalSpecifiedMaxValue = '';
    this.lampHolderNormalMeasuredVaule = '';
    this.lampHolderAbnormalSpecifiedMaxValue = '';
    this.lampHolderAbnormalMeasuredVaule = '';
    this.capacitorThermocouple = '';
    this.capacitorThermocoupleNo = '';
    this.capacitorNormalSpecifiedMaxValue = '';
    this.capacitorNormalMeasuredVaule = '';
    this.capacitorAbnormalSpecifiedMaxValue = '';
    this.capacitorAbnormalMeasuredVaule = '';
    this.ignitorThermocouple = '';
    this.ignitorThermocoupleNo = '';
    this.ignitorNormalSpecifiedMaxValue = '';
    this.ignitorNormalMeasuredVaule = '';
    this.ignitorAbnormalSpecifiedMaxValue = '';
    this.ignitorAbnormalMeasuredVaule = '';
    this.internalWiringThermocouple = '';
    this.internalWiringThermocoupleNo = '';
    this.internalWiringNormalSpecifiedMaxValue = '';
    this.internalWiringNormalMeasuredVaule = '';
    this.internalWiringAbnormalSpecifiedMaxValue = '';
    this.internalWiringAbnormalMeasuredVaule = '';
    this.externalWiringThermocouple = '';
    this.externalWiringThermocoupleNo = '';
    this.externalWiringNormalSpecifiedMaxValue = '';
    this.externalWiringNormalMeasuredVaule = '';
    this.externalWiringAbnormalSpecifiedMaxValue = '';
    this.externalWiringAbnormalMeasuredVaule = '';
    this.heatSinkThermocouple = '';
    this.heatSinkThermocoupleNo = '';
    this.heatSinkNormalSpecifiedMaxValue = '';
    this.heatSinkNormalMeasuredVaule = '';
    this.heatSinkAbnormalSpecifiedMaxValue = '';
    this.heatSinkAbnormalMeasuredVaule = '';
    this.terminalThermocouple = '';
    this.terminalThermocoupleNo = '';
    this.terminalNormalSpecifiedMaxValue = '';
    this.terminalNormalMeasuredVaule = '';
    this.terminalAbnormalSpecifiedMaxValue = '';
    this.terminalAbnormalMeasuredVaule = '';
    this.bodyThermocouple = '';
    this.bodyThermocoupleNo = '';
    this.bodyNormalSpecifiedMaxValue = '';
    this.bodyNormalMeasuredVaule = '';
    this.bodyAbnormalSpecifiedMaxValue = '';
    this.bodyAbnormalMeasuredVaule = '';
    this.diffuserThermocouple = '';
    this.diffuserThermocoupleNo = '';
    this.diffuserNormalSpecifiedMaxValue = '';
    this.diffuserNormalMeasuredVaule = '';
    this.diffuserAbnormalSpecifiedMaxValue = '';
    this.diffuserAbnormalMeasuredVaule = '';
    this.reflectorThermocouple = '';
    this.reflectorThermocoupleNo = '';
    this.reflectorNormalSpecifiedMaxValue = '';
    this.reflectorNormalMeasuredVaule = '';
    this.reflectorAbnormalSpecifiedMaxValue = '';
    this.reflectorAbnormalMeasuredVaule = '';
    this.coolingFanThermocouple = '';
    this.coolingFanThermocoupleNo = '';
    this.coolingFanNormalSpecifiedMaxValue = '';
    this.coolingFanNormalMeasuredVaule = '';
    this.coolingFanAbnormalSpecifiedMaxValue = '';
    this.coolingFanAbnormalMeasuredVaule = '';
    this.socketPlugThermocouple = '';
    this.socketPlugThermocoupleNo = '';
    this.socketPlugNormalSpecifiedMaxValue = '';
    this.socketPlugNormalMeasuredVaule = '';
    this.socketPlugAbnormalSpecifiedMaxValue = '';
    this.socketPlugAbnormalMeasuredVaule = '';
    this.insideLuminaireThermocouple = '';
    this.insideLuminaireThermocoupleNo = '';
    this.insideLuminaireNormalSpecifiedMaxValue = '';
    this.insideLuminaireNormalMeasuredVaule = '';
    this.insideLuminaireAbnormalSpecifiedMaxValue = '';
    this.insideLuminaireAbnormalMeasuredVaule = '';
    this.ambientThermocouple = '';
    this.ambientThermocoupleNo = '';
    this.ambientNormalSpecifiedMaxValue = '';
    this.ambientNormalMeasuredVaule = '';
    this.ambientAbnormalSpecifiedMaxValue = '';
    this.ambientAbnormalMeasuredVaule = '';
    this.other1ThermocoupleNo = '';
    this.other1NormalSpecifiedMaxValue = '';
    this.other1NormalMeasuredVaule = '';
    this.other1AbnormalSpecifiedMaxValue = '';
    this.other1AbnormalMeasuredVaule = '';
    this.other2ThermocoupleNo = '';
    this.other2NormalSpecifiedMaxValue = '';
    this.other2NormalMeasuredVaule = '';
    this.other2AbnormalSpecifiedMaxValue = '';
    this.other2AbnormalMeasuredVaule = '';
    this.other3ThermocoupleNo = '';
    this.other3NormalSpecifiedMaxValue = '';
    this.other3NormalMeasuredVaule = '';
    this.other3AbnormalSpecifiedMaxValue = '';
    this.other3AbnormalMeasuredVaule = '';
    this.other4ThermocoupleNo = '';
    this.other4NormalSpecifiedMaxValue = '';
    this.other4NormalMeasuredVaule = '';
    this.other4AbnormalSpecifiedMaxValue = '';
    this.other4AbnormalMeasuredVaule = '';
    this.other5ThermocoupleNo = '';
    this.other5NormalSpecifiedMaxValue = '';
    this.other5NormalMeasuredVaule = '';
    this.other5AbnormalSpecifiedMaxValue = '';
    this.other5AbnormalMeasuredVaule = '';
    this.other6ThermocoupleNo = '';
    this.other6NormalSpecifiedMaxValue = '';
    this.other6NormalMeasuredVaule = '';
    this.other6AbnormalSpecifiedMaxValue = '';
    this.other6AbnormalMeasuredVaule = '';
    this.other7ThermocoupleNo = '';
    this.other7NormalSpecifiedMaxValue = '';
    this.other7NormalMeasuredVaule = '';
    this.other7AbnormalSpecifiedMaxValue = '';
    this.other7AbnormalMeasuredVaule = '';
    this.heatResistanceTestId = 0;
  }
}

export class IstmtTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  istmtTestLabTools: Array<IstmtTestLabToolModel> = [];
  istmtTestEquipments: Array<IstmtTestEquipmentModel> = [];
  istmtTestInprocess: IstmtTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  istmtTestTestConditionOthers: Array<IstmtTestTestConditionOtherModel> = [];
  testFile: File;
  testImageFile: string;
  istmtTestResult: IstmtTestResultModel;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.istmtTestLabTools = [];
    this.istmtTestEquipments = [];
    this.istmtTestInprocess = new IstmtTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.istmtTestTestConditionOthers = [];
    this.testImageFile = '';
    this.istmtTestResult = new IstmtTestResultModel();
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class IstmtTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  istmtTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.istmtTestId = 0;
  }
}

export class IstmtTestEquipmentModel {
  istmtTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.istmtTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class IstmtTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value4: string;
  value5: string;
  value6: string;
  istmtTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.istmtTestId = 0;
  }
}

export class IstmtTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  istmtTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.istmtTestId = 0;
  }
}

export class IstmtTestResultModel {
  id: number;
  inResultNote1: string;
  inResultNote2: string;
  inResultNote3: string;
  inResultNote4: string;
  inResultNote5: string;
  outResultNote1: string;
  outResultNote2: string;
  outResultNote3: string;
  outResultNote4: string;
  outResultNote5: string;
  resultNote6: string;
  resultNote7: string;
  resultNote8: string;
  resultNote9: string;
  resultNote10: string;
  resultNote11: string;
  resultNote12: string;
  resultNote13: string;
  resultNote14: string;
  resultNote15: string;
  resultNote16: string;
  resultNote17: string;
  resultK17: string;
  resultNote18: string;
  resultK18: string;
  istmtTestId: number;

  clear() {
    this.id = 0;
    this.inResultNote1 = '';
    this.inResultNote2 = '';
    this.inResultNote3 = '';
    this.inResultNote4 = '';
    this.inResultNote5 = '';
    this.outResultNote1 = '';
    this.outResultNote2 = '';
    this.outResultNote3 = '';
    this.outResultNote4 = '';
    this.outResultNote5 = '';
    this.resultNote6 = '';
    this.resultNote7 = '';
    this.resultNote8 = '';
    this.resultNote9 = '';
    this.resultNote10 = '';
    this.resultNote11 = '';
    this.resultNote12 = '';
    this.resultNote13 = '';
    this.resultNote14 = '';
    this.resultNote15 = '';
    this.resultNote16 = '';
    this.resultNote17 = '';
    this.resultK17 = '';
    this.resultNote18 = '';
    this.resultK18 = '';
    this.istmtTestId = 0;
  }
}

export class RainTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  rainTestLabTools: Array<RainTestLabToolModel> = [];
  rainTestEquipments: Array<RainTestEquipmentModel> = [];
  rainTestInprocess: RainTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  rainTestTestConditionOthers: Array<RainTestTestConditionOtherModel> = [];
  testFile: File;
  testImageFile: string;
  isRainTest: boolean;
  isSprinklerTest: boolean;
  isDielectricTest: boolean;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  waterTestWILAB2061Speed: string;
  waterTestWILAB2061Pressure: string;
  waterTestWILAB2061SampleTemp: string;
  waterTestWILAB2061Temp: string;
  waterTestWILAB2061SprayDistant: string;
  waterTestWILAB2061Time: number;
  waterTestWILAB2061Condition: string;
  waterTestWILAB2062Speed: string;
  waterTestWILAB2062Pressure: string;
  waterTestWILAB2062SampleTemp: string;
  waterTestWILAB2062Temp: string;
  waterTestWILAB2062SprayDistant: string;
  waterTestWILAB2062Time: number;
  waterTestWILAB2062Condition: string;
  waterTestIWILAB2063SampleType: number;
  waterTestIWILAB2063SampleVoltage: string;
  waterTestIWILAB2063Voltage: string;
  waterTestIWILAB2063Result: number;
  waterTestIWILAB2063Comment: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.rainTestLabTools = [];
    this.rainTestEquipments = [];
    this.rainTestInprocess = new RainTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.rainTestTestConditionOthers = [];
    this.testImageFile = '';
    this.isRainTest = false;
    this.isSprinklerTest = false;
    this.isDielectricTest = false;
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';

    this.waterTestWILAB2061Speed = '';
    this.waterTestWILAB2061Pressure = '';
    this.waterTestWILAB2061SampleTemp = '';
    this.waterTestWILAB2061Temp = '';
    this.waterTestWILAB2061SprayDistant = '';
    this.waterTestWILAB2061Time = null;
    this.waterTestWILAB2061Condition = '';

    this.waterTestWILAB2062Speed = '';
    this.waterTestWILAB2062Pressure = '';
    this.waterTestWILAB2062SampleTemp = '';
    this.waterTestWILAB2062Temp = '';
    this.waterTestWILAB2062SprayDistant = '';
    this.waterTestWILAB2062Time = null;
    this.waterTestWILAB2062Condition = '';

    this.waterTestIWILAB2063SampleType = null;
    this.waterTestIWILAB2063SampleVoltage = '';
    this.waterTestIWILAB2063Voltage = '';
    this.waterTestIWILAB2063Result = null;
    this.waterTestIWILAB2063Comment = '';
  }
}

export class RainTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  rainTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.rainTestId = 0;
  }
}

export class RainTestEquipmentModel {
  rainTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.rainTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class RainTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value4: string;
  value5: string;
  value6: string;
  rainTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.rainTestId = 0;
  }
}

export class RainTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  rainTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.rainTestId = 0;
  }
}

export class OtherTestModel {
  id: number;
  inprocessLaboratoryId: number;
  isStart: boolean;
  tabNumber: number;
  otherTestLabTools: Array<OtherTestLabToolModel> = [];
  otherTestEquipments: Array<OtherTestEquipmentModel> = [];
  otherTestInprocess: OtherTestsInprocessModel;
  preRoomTemperature: string;
  preRoomHumidity: string;
  otherTestTestConditionOthers: Array<OtherTestTestConditionOtherModel> = [];
  testFile: File;
  testImageFile: string;
  attachFile1: string;
  isRainTest: boolean;
  postRoomTemperature: string;
  postRoomHumidity: string;
  resultStatus: number;
  resultDetail: string;
  resultFile: File;
  resultImageFile: string;
  imageFile: File;
  imageAttachFile: string;

  clear() {
    this.id = 0;
    this.inprocessLaboratoryId = 0;
    this.isStart = false;
    this.tabNumber = null;
    this.otherTestLabTools = [];
    this.otherTestEquipments = [];
    this.otherTestInprocess = new OtherTestsInprocessModel();
    this.preRoomTemperature = '';
    this.preRoomHumidity = '';
    this.otherTestTestConditionOthers = [];
    this.testImageFile = '';
    this.isRainTest = false;
    this.postRoomTemperature = '';
    this.postRoomHumidity = '';
    this.resultStatus = null;
    this.resultDetail = '';
    this.resultImageFile = '';
    this.imageFile = null;
    this.imageAttachFile = '';
  }
}

export class OtherTestLabToolModel {
  id: number;
  qty: number;
  row: number;
  topic: string;
  no: string;
  otherTestId: number;

  clear() {
    this.id = 0;
    this.topic = '';
    this.row = 0;
    this.no = '';
    this.qty = null;
    this.otherTestId = 0;
  }
}

export class OtherTestEquipmentModel {
  otherTestId: number;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;

  clear() {
    this.otherTestId = 0;
    this.equipmentId = 0;
    this.equipment = new EquipmentModel();
    this.name = '';
    this.checkDate = '';
    this.editText = '';
    this.isUsing = false;
    this.isOther = false;
  }
}

export class OtherTestsInprocessModel {
  id: number;
  isNormal1: boolean;
  isNormal2: boolean;
  isNormal3: boolean;
  isNormal4: boolean;
  isNormal5: boolean;
  isNormal6: boolean;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  otherTestId: number;

  clear() {
    this.id = 0;
    this.isNormal1 = false;
    this.isNormal2 = false;
    this.isNormal3 = false;
    this.isNormal4 = false;
    this.isNormal5 = false;
    this.isNormal6 = false;
    this.value1 = '';
    this.value2 = '';
    this.value3 = '';
    this.value4 = '';
    this.value5 = '';
    this.value6 = '';
    this.otherTestId = 0;
  }
}

export class OtherTestTestConditionOtherModel {
  id: number;
  value: string;
  name: string;
  criteria: string;
  isNormal: boolean;
  isOther: boolean;
  isPre: boolean;
  otherTestId: number;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.criteria = '';
    this.isNormal = false;
    this.isOther = false;
    this.isPre = false;
    this.otherTestId = 0;
  }
}

export class InprocessServiceOtherModel {
  id: number;
  serviceId: number;
  detail: string;
  patent: string;
  workScore1: number;
  workScore2: number;
  workScore3: number;
  workScore4: number;
  workScore5: number;
  data1: string;
  data2: string;
  data3: string;
  data4: string;
  attachFileImage: string;
  fileImage: File;
  attachFile: string;
  file: File;

  clear() {
    this.id = 0;
    this.serviceId = 0;
    this.detail = '';
    this.patent = '';
    this.workScore1 = null;
    this.workScore2 = null;
    this.workScore3 = null;
    this.workScore4 = null;
    this.workScore5 = null;
    this.data1 = '';
    this.data2 = '';
    this.data3 = '';
    this.data4 = '';
    this.attachFileImage = '';
    this.fileImage = null;
    this.attachFile = '';
    this.file = null;
  }
}
