import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { RapidPrototypeModel } from '../index';

const API_SERVICE_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class RapidPrototypeService {
  constructor(private http: HttpClient) {}

  // READ
  getAllRapidPrototys(): Observable<RapidPrototypeModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/RapidPrototy`;
    return this.http.get<RapidPrototypeModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getRapidPrototyById(id: number): Observable<RapidPrototypeModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/RapidPrototy/${id}`;
    return this.http.get<RapidPrototypeModel>(_url, { headers: httpHeaders });
  }

  // READ
  getRapidPrototyOldById(id: number): Observable<RapidPrototypeModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/RapidPrototyOld/${id}`;
    return this.http.get<RapidPrototypeModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createRapidPrototy(
    model: RapidPrototypeModel
  ): Observable<RapidPrototypeModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'rapidPrototyTopicId',
      model.rapidPrototyTopicId == null
        ? null
        : model.rapidPrototyTopicId.toString()
    );
    formData.append('modelName', model.modelName ? model.modelName: '');

    formData.append('part1', model.part1 ? model.part1: '');
    formData.append('partLSize1', model.partLSize1 ? model.partLSize1: '');
    formData.append('partWSize1', model.partWSize1 ? model.partWSize1: '');
    formData.append('partHSize1', model.partHSize1 ? model.partHSize1: '');
    formData.append('partDSize1', model.partDSize1 ? model.partDSize1: '');
    formData.append('partQuantity1', model.partQuantity1 ? model.partQuantity1: '');
    formData.append(
      'materialResolution1Id',
      model.materialResolution1Id == null
        ? null
        : model.materialResolution1Id.toString()
    );
    formData.append('part2', model.part2 ? model.part2: '');
    formData.append('partLSize2', model.partLSize2 ? model.partLSize2: '');
    formData.append('partWSize2', model.partWSize2 ? model.partWSize2: '');
    formData.append('partHSize2', model.partHSize2 ? model.partHSize2: '');
    formData.append('partDSize2', model.partDSize2 ? model.partDSize2: '');
    formData.append('partQuantity2', model.partQuantity2 ? model.partQuantity2: '');
    formData.append(
      'materialResolution2Id',
      model.materialResolution2Id == null
        ? null
        : model.materialResolution2Id.toString()
    );
    formData.append('part3', model.part3 ? model.part3: '');
    formData.append('partLSize3', model.partLSize3 ? model.partLSize3: '');
    formData.append('partWSize3', model.partWSize3 ? model.partWSize3: '');
    formData.append('partHSize3', model.partHSize3 ? model.partHSize3: '');
    formData.append('partDSize3', model.partDSize3 ? model.partDSize3: '');
    formData.append('partQuantity3', model.partQuantity3 ? model.partQuantity3: '');
    formData.append(
      'materialResolution3Id',
      model.materialResolution3Id == null
        ? null
        : model.materialResolution3Id.toString()
    );
    formData.append('part4', model.part4 ? model.part4: '');
    formData.append('partLSize4', model.partLSize4 ? model.partLSize4: '');
    formData.append('partWSize4', model.partWSize4 ? model.partWSize4: '');
    formData.append('partHSize4', model.partHSize4 ? model.partHSize4: '');
    formData.append('partDSize4', model.partDSize4 ? model.partDSize4: '');
    formData.append('partQuantity4', model.partQuantity4 ? model.partQuantity4: '');
    formData.append(
      'materialResolution4Id',
      model.materialResolution4Id == null
        ? null
        : model.materialResolution4Id.toString()
    );
    formData.append('part5', model.part5 ? model.part5: '');
    formData.append('partLSize5', model.partLSize5 ? model.partLSize5: '');
    formData.append('partWSize5', model.partWSize5 ? model.partWSize5: '');
    formData.append('partHSize5', model.partHSize5 ? model.partHSize5: '');
    formData.append('partDSize5', model.partDSize5 ? model.partDSize5: '');
    formData.append('partQuantity5', model.partQuantity5 ? model.partQuantity5: '');
    formData.append(
      'materialResolution5Id',
      model.materialResolution5Id == null
        ? null
        : model.materialResolution5Id.toString()
    );

    formData.append('otherDetails', model.otherDetails ? model.otherDetails: '');
    formData.append('reason', model.reason ? model.reason: '');
    formData.append('file1', model.file1);
    // formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    // formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    // formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    // formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    // formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/RapidPrototy`;
    return this.http.post<RapidPrototypeModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateRapidPrototy(
    model: RapidPrototypeModel
  ): Observable<RapidPrototypeModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'rapidPrototyTopicId',
      model.rapidPrototyTopicId == null
        ? null
        : model.rapidPrototyTopicId.toString()
    );
    formData.append('modelName', model.modelName ? model.modelName: '');

    formData.append('part1', model.part1 ? model.part1: '');
    formData.append('partLSize1', model.partLSize1 ? model.partLSize1: '');
    formData.append('partWSize1', model.partWSize1 ? model.partWSize1: '');
    formData.append('partHSize1', model.partHSize1 ? model.partHSize1: '');
    formData.append('partDSize1', model.partDSize1 ? model.partDSize1: '');
    formData.append('partQuantity1', model.partQuantity1 ? model.partQuantity1: '');
    formData.append('partResolution1', model.partResolution1);
    formData.append(
      'materialResolution1Id',
      model.materialResolution1Id == null
        ? null
        : model.materialResolution1Id.toString()
    );
    formData.append('part2', model.part2 ? model.part2: '');
    formData.append('partLSize2', model.partLSize2 ? model.partLSize2: '');
    formData.append('partWSize2', model.partWSize2 ? model.partWSize2: '');
    formData.append('partHSize2', model.partHSize2 ? model.partHSize2: '');
    formData.append('partDSize2', model.partDSize2 ? model.partDSize2: '');
    formData.append('partQuantity2', model.partQuantity2 ? model.partQuantity2: '');
    formData.append('partResolution2', model.partResolution2);
    formData.append(
      'materialResolution2Id',
      model.materialResolution2Id == null
        ? null
        : model.materialResolution2Id.toString()
    );
    formData.append('part3', model.part3 ? model.part3: '');
    formData.append('partLSize3', model.partLSize3 ? model.partLSize3: '');
    formData.append('partWSize3', model.partWSize3 ? model.partWSize3: '');
    formData.append('partHSize3', model.partHSize3 ? model.partHSize3: '');
    formData.append('partDSize3', model.partDSize3 ? model.partDSize3: '');
    formData.append('partQuantity3', model.partQuantity3 ? model.partQuantity3: '');
    formData.append('partResolution3', model.partResolution3);
    formData.append(
      'materialResolution3Id',
      model.materialResolution3Id == null
        ? null
        : model.materialResolution3Id.toString()
    );
    formData.append('part4', model.part4 ? model.part4: '');
    formData.append('partLSize4', model.partLSize4 ? model.partLSize4: '');
    formData.append('partWSize4', model.partWSize4 ? model.partWSize4: '');
    formData.append('partHSize4', model.partHSize4 ? model.partHSize4: '');
    formData.append('partDSize4', model.partDSize4 ? model.partDSize4: '');
    formData.append('partQuantity4', model.partQuantity4 ? model.partQuantity4: '');
    formData.append('partResolution4', model.partResolution4);
    formData.append(
      'materialResolution4Id',
      model.materialResolution4Id == null
        ? null
        : model.materialResolution4Id.toString()
    );
    formData.append('part5', model.part5 ? model.part5: '');
    formData.append('partLSize5', model.partLSize5 ? model.partLSize5: '');
    formData.append('partWSize5', model.partWSize5 ? model.partWSize5: '');
    formData.append('partHSize5', model.partHSize5 ? model.partHSize5: '');
    formData.append('partDSize5', model.partDSize5 ? model.partDSize5: '');
    formData.append('partQuantity5', model.partQuantity5 ? model.partQuantity5: '');
    formData.append('partResolution5', model.partResolution5);
    formData.append(
      'materialResolution5Id',
      model.materialResolution5Id == null
        ? null
        : model.materialResolution5Id.toString()
    );

    formData.append('otherDetails', model.otherDetails ? model.otherDetails: '');
    formData.append('reason', model.reason ? model.reason: '');
    model.file1 ? null : formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    model.file2 ? null : formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    model.file3 ? null : formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    model.file4 ? null : formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    model.file5 ? null : formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append(
      'isConfirm',
      model.isConfirm == undefined ? 'false' : model.isConfirm.toString()
    );

    const _url = API_SERVICE_URL + `/RapidPrototy`;
    return this.http.put<RapidPrototypeModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteRapidPrototy(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_SERVICE_URL + `/RapidPrototy/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // READ
  updateInProcessRecord(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url =
      API_SERVICE_URL + `/InProcessRecord/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }
}
