<div class="register-container">
  <a href="#"><i class="fas fa-home home-icon"></i></a>
  <div class="row justify-content-center mt-3">
    <div class="sameLine col-md-4">
      <img src="/assets/images/logoNav.png" class="d-inline-block align-top sizeLogo"><br>
      <h4 class=" topicText ">Welcome to LIC <br>
        <span class=" justify-content-center header-thai-font">ยินดีต้อนรับสู่ LIC</span>
      </h4>


      <form #login="ngForm" (ngSubmit)="onSubmit(login)" class="inputForm" novalidate>
        <!-- (ngSubmit)="submit(login)" -->
        <div>
          <div>
            <label class="textColor label-inline topic-to-left">Email<br /><span class="nav-thai-font">อีเมล</span></label>
          </div>
          <input required email type="email" id="defaultLoginFormEmail" class="form-control mb-4 " name="email" placeholder="email@address.com" #email="ngModel" ngModel>
          <!-- <div *ngIf ="email.touched && email.invalid" class="alert-error">Please input your email</div> -->

          <div>
            <label class="textColor label-inline topic-to-left">Password <br>
              <span class="nav-thai-font" style="display: block;">รหัสผ่าน</span></label>
          </div>
          <!-- <input required minlength="5" type="password" id="defaultLoginFormPassword"
            class="form-control mb-4" name="password" placeholder="minimum at 5" #password="ngModel" ngModel> -->
          <div class="input-icons">
            <input required minlength="5" [type]="isHidePass ? 'password': 'text'" id="defaultLoginFormPassword" class="form-control" name="password" placeholder="minimum at 5" #password="ngModel" ngModel>
            <i class="fa icon cursor__point" style="color: black;" [ngClass]="isHidePass ? 'fa-eye' : 'fa-eye-slash'" (click)="isShow()"></i>
          </div>
        </div>
        <div>
          <a href="/forgot" class="rightSide text-align-right">Forgot password
            <br>
            <span class="nav-thai-font">ลืมรหัสผ่าน</span>
          </a>
        </div>
        <br>
        <div class="row gap-comfirm-checkbox">
          <div class="col">
            <button type="submit" [disabled]="!login.valid" class="btn btn-primary  gap-between-button btn-regis1">Sign
              in<br><span class="nav-thai-font">เข้าสู่ระบบ</span></button>
            <!-- (click)="login()" -->
          </div>
          <!-- <div class="col">
            <a type="button" class="btn btn-primary  gap-between-button btn-regis2" href="/signUp">Register<br><span
                class="nav-thai-font">ลงทะเบียน</span></a>
          </div> -->
        </div>
        <div>
          <p>ยังไม่เป็นสมาชิก ? &nbsp;<a style="color: rgb(22 22 212); margin-bottom: 4px;" href="/signUp">ลงทะเบียน</a></p>
          <p>Do you have an account? &nbsp;<a style="color: rgb(22 22 212);" href="/signUp">Register</a></p>
        </div>
      </form>
    </div>
    <div class="innerDiv">
      <div class="circle c1"></div>
      <div class="circle c2"></div>
      <div class="circle c3"></div>
      <div class="circle c4"></div>
    </div>

  </div>

</div>

<notifier-container></notifier-container>
