export class BannerModel {
  id: number;
  file: File;
  pathName: string;
  startDate: string;
  endDate: string;
  isShow: boolean;
  isAdmin: boolean;
  creationDateTime: string;
  lastUpdateDateTime: string;

  clear() {
    this.id = undefined;
    this.file = null;
    this.pathName = '';
    this.startDate = '2021-01-01T00:00:00';
    this.endDate = '2021-01-01T00:00:00';
    this.isShow = false;
    this.isAdmin = false;
    this.creationDateTime = '2021-01-01T00:00:00';
    this.lastUpdateDateTime = '2021-01-01T00:00:00';
  }
}
