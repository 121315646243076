export class ContentModel {
  id: number;
  mainContent: string;
  adminContent: string;
  detailContent: string;
  fileImageBlock1: File;
  imageBlock1: string;
  titleBlock1: string;
  detailBlock1: string;
  fileImageBlock2: File;
  imageBlock2: string;
  titleBlock2: string;
  detailBlock2: string;
  fileImageBlock3: File;
  imageBlock3: string;
  titleBlock3: string;
  detailBlock3: string;
  consent: string;
  fileDownload: File;
  pathDownload: string;
  startDate: string;
  endDate: string;
  isShow: boolean;
  isAdmin: boolean;
  creationDateTime: string;
  lastUpdateDateTime: string;

  clear() {
    this.id = undefined;
    this.mainContent = '';
    this.detailContent = '';
    this.fileImageBlock1 = null;
    this.imageBlock1 = '';
    this.titleBlock1 = '';
    this.detailBlock1 = '';
    this.fileImageBlock2 = null;
    this.imageBlock2 = '';
    this.titleBlock2 = '';
    this.detailBlock2 = '';
    this.fileImageBlock3 = null;
    this.imageBlock3 = '';
    this.titleBlock3 = '';
    this.detailBlock3 = '';
    this.consent = '';
    this.fileDownload = null;
    this.pathDownload = '';
    this.startDate = '2021-01-01T00:00:00';
    this.endDate = '2021-01-01T00:00:00';
    this.isShow = false;
    this.isAdmin = false;
    this.creationDateTime = '2021-01-01T00:00:00';
    this.lastUpdateDateTime = '2021-01-01T00:00:00';
  }
}
