import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  InprocessLaboratoryModel,
  InprocessServiceOtherModel,
  LaboratoryModel,
} from '../index';
import { EquipmentModel } from '../../management-system';

const API_INPROCESS_URL = environment.API_BASE_URL + '/api/Inprocess';
const API_Service_URL = environment.API_BASE_URL + '/api/Servcice';

@Injectable({
  providedIn: 'root',
})
export class InprocessLaboratoryService {
  constructor(private http: HttpClient) {}

  // READ
  getInprocessLaboratoryById(
    laboratoryId: number
  ): Observable<LaboratoryModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_INPROCESS_URL + `/Laboratory/${laboratoryId}`;
    return this.http.get<LaboratoryModel>(_url, { headers: httpHeaders });
  }

  // READ
  getInprocessFinishs(): Observable<LaboratoryModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_INPROCESS_URL + `/Inprocess/Finishs`;
    return this.http.get<LaboratoryModel>(_url, { headers: httpHeaders });
  }

  // READ
  getInprocessLaboratoryEquipmentById(
    laboratoryId: number
  ): Observable<EquipmentModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_INPROCESS_URL + `/Laboratory/Equipment/${laboratoryId}`;
    return this.http.get<EquipmentModel[]>(_url, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createInprocess(
    model: InprocessLaboratoryModel
  ): Observable<InprocessLaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_INPROCESS_URL + `/Inprocess`;
    return this.http.post<InprocessLaboratoryModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // UPDATE => PUT: add a new to the server
  updateInprocessUploadImageElse(
    id: number,
    file: File
  ): Observable<InprocessLaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('file', file);

    const _url = API_INPROCESS_URL + `/UploadImageElse`;
    return this.http.put<InprocessLaboratoryModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE => PUT: add a new to the server
  updateInprocessUploadTestImages(
    ids: number[],
    fileTestImages: File[],
    fileResultImages: File[],
    fileImages: File[],
    typeName: string
  ): Observable<InprocessLaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];

      formData.append('ids', element.toString());
    }

    for (let index = 0; index < fileTestImages.length; index++) {
      const element = fileTestImages[index];
      formData.append('fileTestImages', element);
    }
    for (let index = 0; index < fileResultImages.length; index++) {
      const element = fileResultImages[index];
      formData.append('fileResultImages', element);
    }
    for (let index = 0; index < fileImages.length; index++) {
      const element = fileImages[index];
      formData.append('fileImages', element);
    }

    const _url = API_INPROCESS_URL + `/UploadTestImages/${typeName}`;
    return this.http.put<InprocessLaboratoryModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE => PUT: add a new to the server
  updateInprocessCIE121(
    model: InprocessLaboratoryModel
  ): Observable<InprocessLaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_INPROCESS_URL + `/InprocessCIE121`;
    return this.http.put<InprocessLaboratoryModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  updateInprocessFinish(
    inprocessId: number
  ): Observable<InprocessLaboratoryModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let model: InprocessLaboratoryModel = <InprocessLaboratoryModel>{};

    const _url = API_INPROCESS_URL + `/Inprocess/Finish/${inprocessId}`;
    return this.http.put<InprocessLaboratoryModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server /api/Inprocess/InProcessOther/{servicesId}/{id}
  updateInProcessOther(
    model: InprocessServiceOtherModel
  ): Observable<InprocessServiceOtherModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('detail', model.detail ? model.detail : '');
    formData.append('patent', model.patent ? model.patent : '');
    formData.append(
      'workScore1',
      model.workScore1 ? model.workScore1.toString() : ''
    );
    formData.append(
      'workScore2',
      model.workScore2 ? model.workScore2.toString() : ''
    );
    formData.append(
      'workScore3',
      model.workScore3 ? model.workScore3.toString() : ''
    );
    formData.append(
      'workScore4',
      model.workScore4 ? model.workScore4.toString() : ''
    );
    formData.append(
      'workScore5',
      model.workScore5 ? model.workScore5.toString() : ''
    );
    formData.append('data1', model.data1 ? model.data1 : '');
    formData.append('data2', model.data2 ? model.data2 : '');
    formData.append('data3', model.data3 ? model.data3 : '');
    formData.append('data4', model.data4 ? model.data4 : '');
    formData.append(
      'attachFileImage',
      model.attachFileImage ? model.attachFileImage : ''
    );
    formData.append('fileImage', model.fileImage);
    formData.append('attachFile', model.attachFile ? model.attachFile : '');
    formData.append('file', model.file);

    const _url =
      API_INPROCESS_URL + `/InProcessOther/${model.serviceId}/${model.id}`;
    return this.http.put<InprocessServiceOtherModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
