import { EquipmentModel } from './equipment.model';
import { UserModel } from '../../authen/_models/authen.model';
export interface VerificationRes {
  id: number;
  no: number;
  verificationDateTime: string;
  topic1: string;
  result: string;
  isComplete: boolean;
  operatorName: string;
  verificationDateTimeNext: string;
  status: string;
  lastUpdateDateTime: string;
}
export class VerificationInspectionIcModel {
  id: number;
  topic1: number;
  detail: string;
  topic2: string;
  topic3: string;
  ref1: number;
  verificationLastDateTime1: string;
  ref2: number;
  verificationLastDateTime2: string;
  ref3: number;
  verificationLastDateTime3: string;
  verificationDateTime: string;
  userVerificationId: number;
  verificationDateTimeNext: string;
  userVerification: UserModel;
  result: boolean;
  resultDetail: string;

  equipmentId: number;
  equipment: EquipmentModel;

  verificationLogs: Array<VerificationLogModel> = [];

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isComplete: boolean;
  completeDateTime: string;
  userCompleteId: number;
  userComplete: UserModel;
  userDeleteId: number;
  userDelete: UserModel;
  deleteDateTime: string;

  creationDateTime: string;
  lastUpdateDateTime: string;
  attachFile1: string;
  attachFile2: string;
  attachFile3: string;
  attachFile4: string;
  attachFile5: string;

  testId: number;
  testName: number;
  testDate: string;
  isApprove: boolean;

  clear() {
    this.id = 0;
    this.topic1 = 0;
    this.detail = '';
    this.topic2 = '';
    this.topic3 = '';
    this.ref1 = 0;
    this.verificationLastDateTime1 = '';
    this.ref2 = 0;
    this.verificationLastDateTime2 = '';
    this.ref3 = 0;
    this.verificationLastDateTime3 = '';
    this.verificationDateTime = '';
    this.userVerificationId = 0;
    this.userVerification = new UserModel();
    this.result = false;
    this.resultDetail = '';

    this.equipmentId = 0;
    this.equipment = new EquipmentModel();

    this.verificationLogs = [];

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isComplete = false;
    this.completeDateTime = '';
    this.userCompleteId = 0;
    this.userComplete = new UserModel();
    this.userDeleteId = 0;
    this.userDelete = new UserModel();
    this.deleteDateTime = '';

    this.creationDateTime = '';
    this.lastUpdateDateTime = '';
  }
}

export class VerificationLogModel {
  id: number;
  checkPoint: string;
  toolRef: number;
  value: number;
  error: string;
  mpe: string;
  result: string;
  comment: string;
  verificationId: number;

  clear() {
    this.id = 0;
    this.checkPoint = '';
    this.error = '';
    this.mpe = '';
    this.result = '';
    this.comment = '';
    this.verificationId = 0;
  }
}
