import { PaymentProjectModel } from './task.model';
import { ReviewProjectModel, SubmissionLabModel } from '../_models/task.model';
import { UserModel } from '../../authen';
import {
  ProjectBudgetModel,
  ProjectPlanTimelineModel,
  ProjectRiskAssessmentModel,
  ProjectSummaryModel,
  ProjectTeamMemberRoleModel,
} from '../../innovation-project';

export class ProjectManagementModel {
  id: number;
  operationNo: string;
  no: string;
  // projectName: string;
  // projectInitials: string;
  // briefDescription: string;
  // projectLeader: string;
  // pdRepresentative: string;
  // projectAdviser: string;
  requestAction: string;

  projectName: string;
  projectInitials: string;
  proposedDate: string;
  proposedBy: string;
  briefDescription: string;
  projectLeader: string;
  projectManager: string;
  pdRepresentative: string;
  projectAdviser: string;
  decision: number;
  decisionDescription: string;

  creationDateTime: string;
  lastUpdateDateTime: string;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isStart: boolean;
  userStartId: number;
  userStart: UserModel;
  StartDateTime: string;
  isConfirm: boolean;
  isApplyReview: boolean;
  applyReviewDateTime: string;
  isInProcessRecord: boolean;
  inProcessRecordDateTime: string;
  isTestCertificate: boolean;
  testCertificateDateTime: string;
  isTaskSubmission: boolean;
  taskSubmissionDateTime: string;
  isPayment: boolean;
  paymentDateTime: string;

  paymentProjects: PaymentProjectModel[];

  projectManagementOldId: number;

  userApplyReviewId: number;
  userApplyReview: UserModel;
  userInProcessRecordId: number;
  userInProcessRecord: UserModel;
  userTestCertificateId: number;
  userTestCertificate: UserModel;
  userTaskSubmissionId: number;
  userTaskSubmission: UserModel;
  userPaymentId: number;
  userPayment: UserModel;

  reviewProject: ReviewProjectModel;
  submission: SubmissionLabModel;

  projectTeamMemberRole: ProjectTeamMemberRoleModel;
  projectPlanTimeline: ProjectPlanTimelineModel;
  projectRiskAssessment: ProjectRiskAssessmentModel;
  projectBudget: ProjectBudgetModel;
  projectSummary: ProjectSummaryModel;

  propertiesTotal: number;
  propertiesReturned: number;
  propertiesDiscard: number;
  propertiesFReturned: number;
  propertiesFDiscard: number;

  clear() {
    this.id = 0;
    this.operationNo = '';
    this.no = '';
    this.projectName = '';
    this.projectInitials = '';
    this.briefDescription = '';
    this.projectLeader = '';
    this.pdRepresentative = '';
    this.projectAdviser = '';
    this.requestAction = '';

    this.creationDateTime = '2021-01-01';
    this.lastUpdateDateTime = '2021-01-01';

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isConfirm = false;
    this.isApplyReview = false;
    this.applyReviewDateTime = '';
    this.isInProcessRecord = false;
    this.inProcessRecordDateTime = '';
    this.isTestCertificate = false;
    this.testCertificateDateTime = '';
    this.isTaskSubmission = false;
    this.taskSubmissionDateTime = '';
    this.isPayment = false;
    this.paymentDateTime = '';

    this.projectManagementOldId = 0;

    this.userApplyReviewId = 0;
    this.userApplyReview = new UserModel();
    this.userInProcessRecordId = 0;
    this.userInProcessRecord = new UserModel();
    this.userTestCertificateId = 0;
    this.userTestCertificate = new UserModel();
    this.userTaskSubmissionId = 0;
    this.userTaskSubmission = new UserModel();
    this.userPaymentId = 0;
    this.userPayment = new UserModel();

    this.reviewProject = new ReviewProjectModel();
    this.submission = new SubmissionLabModel();

    this.propertiesTotal = null;
    this.propertiesReturned = null;
    this.propertiesDiscard = null;
    this.propertiesFReturned = null;
    this.propertiesFDiscard = null;
  }
}

export class ProjectManagementServiceModel {
  id: number;
  operationNo: string;
  no: string;
  servicesId: number;
  servicesName: string;
  topic: string;
  modelName: string;
  status: string;
  payment: string;
  customer: string;
  company: string;
  operator: string;

  serviceType: ProjectManagementModel;

  creationDateTime: string;
  lastUpdateDateTime: string;
  lastActivity: string;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isConfirm: boolean;
  isApplyReview: boolean;
  applyReviewDateTime: string;
  isInProcessRecord: boolean;
  inProcessRecordDateTime: string;
  isTestCertificate: boolean;
  testCertificateDateTime: string;
  isTaskSubmission: boolean;
  taskSubmissionDateTime: string;
  isPayment: boolean;
  paymentDateTime: string;

  clear() {
    this.id = 0;
    this.operationNo = '';
    this.no = '';
    this.servicesId = 0;
    this.servicesName = '';
    this.topic = '';
    this.modelName = '';
    this.status = '';
    this.payment = '';
    this.customer = '';
    this.company = '';
    this.operator = '';

    this.serviceType = new ProjectManagementModel();

    this.creationDateTime = '2021-01-01';
    this.lastUpdateDateTime = '2021-01-01';
    this.lastActivity = '';

    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();

    this.isConfirm = false;
    this.isApplyReview = false;
    this.applyReviewDateTime = '';
    this.isInProcessRecord = false;
    this.inProcessRecordDateTime = '';
    this.isTestCertificate = false;
    this.testCertificateDateTime = '';
    this.isTaskSubmission = false;
    this.taskSubmissionDateTime = '';
    this.isPayment = false;
    this.paymentDateTime = '';
  }
}
