import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-alert-auth',
  templateUrl: './edit-alert-auth.component.html',
  styleUrls: ['./edit-alert-auth.component.scss'],
})
export class EditAlertAuthComponent implements OnInit {
  isHidePass: boolean = true;
  isInputCode: string = '';
  isNotPass: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  /**
   * on submit
   */
  onSubmit() {
    if (this.isInputCode.trim() == environment.authorizationCode.trim()) {
      this.activeModal.close(true);
    } else {
      this.isNotPass = true;
    }
  }
  /**
   * is show password to text
   */
  isShow() {
    this.isHidePass = !this.isHidePass;
  }

  /**
   * on change input
   */
  onChangeInput() {
    this.isNotPass = false;
  }

  /**
   * close dialog
   */
  dismissModal(type: string) {
    this.activeModal.dismiss(type);
  }
}
