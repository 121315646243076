import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  VerificationInspectionIcModel,
  VerificationRes,
} from '../_models/verification-inspection-ic.model';
import { VerificationUploadFile } from '../_models/calibration.model';

const API_RQUIPMENT_URL = environment.API_BASE_URL + '/api/Equipments';

@Injectable({
  providedIn: 'root',
})
export class VerificationInspectionIcService {
  constructor(private http: HttpClient) {}

  // READ
  getAllVerificationByEquipmentId(
    equipmentId: number
  ): Observable<VerificationRes[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url =
      API_RQUIPMENT_URL + `/VerificationByEquipmentId/${equipmentId}`;
    return this.http.get<VerificationRes[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getVerificationById(id: number): Observable<VerificationInspectionIcModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Verification/${id}`;
    return this.http.get<VerificationInspectionIcModel>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  createVerification(
    model: VerificationInspectionIcModel
  ): Observable<VerificationInspectionIcModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Verification`;
    return this.http.post<VerificationInspectionIcModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateVerification(
    model: VerificationInspectionIcModel
  ): Observable<VerificationInspectionIcModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_RQUIPMENT_URL + `/Verification`;
    return this.http.put<VerificationInspectionIcModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteVerification(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_RQUIPMENT_URL + `/Verification/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // UPDATE =>  PUT: add a new to the server
  completeVerification(
    verificationId: number
  ): Observable<VerificationInspectionIcModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = new VerificationInspectionIcModel();

    const _url = API_RQUIPMENT_URL + `/Verification/Complete/${verificationId}`;
    return this.http.put<VerificationInspectionIcModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
  uploadVerification(
    model: VerificationUploadFile
  ): Observable<VerificationInspectionIcModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());

    formData.append('fileManual1', model.fileManual1);
    formData.append('fileManual2', model.fileManual2);
    formData.append('fileManual3', model.fileManual3);
    formData.append('fileManual4', model.fileManual4);
    formData.append('fileManual5', model.fileManual5);

    const _url = API_RQUIPMENT_URL + `/Verification/Upload`;
    return this.http.post<VerificationInspectionIcModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
  // UPDATE =>  PUT: add a new to the server
  approveVerification(
    MaintenanceRepairRecordId: number,
    model: VerificationInspectionIcModel
  ): Observable<VerificationInspectionIcModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const formData = model;

    const _url =
      API_RQUIPMENT_URL + `/Verifications/approve/${MaintenanceRepairRecordId}`;
    return this.http.put<VerificationInspectionIcModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
