import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { DesignSimulationModel } from '../index';

const API_SERVICE_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class DesignSimulationService {
  constructor(private http: HttpClient) {}

  // READ
  getAllDesignSimulations(): Observable<DesignSimulationModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/DesignSimulation`;
    return this.http.get<DesignSimulationModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getDesignSimulationById(id: number): Observable<DesignSimulationModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/DesignSimulation/${id}`;
    return this.http.get<DesignSimulationModel>(_url, { headers: httpHeaders });
  }

  // READ
  getDesignSimulationOldById(id: number): Observable<DesignSimulationModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/DesignSimulationOld/${id}`;
    return this.http.get<DesignSimulationModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  createDesignSimulation(
    model: DesignSimulationModel
  ): Observable<DesignSimulationModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'designSimulationTopicId',
      model.designSimulationTopicId == null
        ? null
        : model.designSimulationTopicId.toString()
    );
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/DesignSimulation`;
    return this.http.post<DesignSimulationModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateDesignSimulation(
    model: DesignSimulationModel
  ): Observable<DesignSimulationModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'designSimulationTopicId',
      model.designSimulationTopicId == null
        ? null
        : model.designSimulationTopicId.toString()
    );
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);

    formData.append('reason', model.reason);
    model.file1 ? null : formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    model.file2 ? null : formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    model.file3 ? null : formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    model.file4 ? null : formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    model.file5 ? null : formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    // formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/DesignSimulation`;
    return this.http.put<DesignSimulationModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deleteDesignSimulation(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_SERVICE_URL + `/DesignSimulation/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // READ
  updateInProcessRecord(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url =
      API_SERVICE_URL + `/InProcessRecord/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }
}
