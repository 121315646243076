<div class="background-blue gap-between-button ">
  <div class="marginContainer">
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-color-white">Equipment<br />
          <p class="text-color-white margin-text-sub font-size-22 ">เครื่องมือ</p>
        </h1>
      </div>
      <div class="col text-align-right margin-top-28px">
        <h5 class="text-color-white font-size-15rem">Equipment Plan<br /><span class="nav-thai-font text-color-white font-size-18">แผนการจัดการเครื่องมือ</span></h5>
      </div>
    </div>
  </div>
</div>


<div class="marginContainer">
  <div class="outerDiv row justify-content-center mt-3 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th cope="col">ID No.</th>
          <th scope="col">Equipment
          </th>
          <th scope="col" style="max-width: 55px;">Site</th>
          <th scope="col" style="max-width: 55px;">C-Period (Month)</th>
          <th scope="col" style="max-width: 55px;">V-Period (Month)</th>
          <th scope="col" style="max-width: 55px;">IC-Period (Month)
          </th>
          <th scope="col" style="max-width: 55px;">C-Next Due
          </th>
          <th scope="col" style="max-width: 55px;">V-Next Due</th>
          <th scope="col" style="max-width: 55px;">IC-Next Due</th>
          <th scope="col" style="max-width: 55px;">MA-Next Due</th>
          <th scope="col">Last used</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of equipmentsSearch">
          <th scope="row">
            <a class="cursor__point">
              {{ element.no }}
            </a>
          </th>
          <td>{{ element.name }}</td>
          <td>{{ element.workArea ? element.workArea.name : "" }}</td>
          <td>{{ element.c }}</td>
          <td>{{ element.v }}</td>
          <td>{{ element.ic }}</td>
          <td>{{ element.calibrationRecords.length > 0 ? (element.calibrationNextDateTime| date: "dd-MM-yyyy"):"-" }}</td>
          <td>{{ element.verifications.length > 0 &&element.vNextDateTime? (element.vNextDateTime| date: "dd-MM-yyyy"):"-" }}</td>
          <td>{{ element.verifications.length > 0 &&element.icNextDateTime? (element.icNextDateTime| date: "dd-MM-yyyy"):"-" }}</td>
          <td>{{ element.maintenanceRepairRecords.length > 0&&element.maNextDateTime ? (element.maNextDateTime| date: "dd-MM-yyyy"):"-" }}</td>
          <td>{{ element.lastUpdateDateTime | date: "dd-MM-yyyy" }}</td>
        </tr>
        <!-- <tr>
          <th scope="row"><a href="pages/admin/customer-properties/arrangement-record">2106001</a></th>
          <td>2021-06-21 12:05:02</td>
          <td>Laboratory</td>
          <td>68-00270	</td>
          <td>DO21/PD1234</td>
          <td>Direk</td>
          <td>L&E</td>
          <td>2021-07-05</td>
          <td>In Process</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr> -->


      </tbody>
    </table>
  </div>
</div>
