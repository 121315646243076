import { PaymentProjectModel } from 'src/app/core/laboratory';
import { UserModel } from 'src/app/core/authen';
import {
  InprocessLaboratoryModel,
  ReviewProjectModel,
  SubmissionLabModel,
  TestCertificateModel,
} from '../index';
import {
  ControlGearTypeModel,
  LightsourceTypeModel,
  SampleTypeModel,
  TestStandardModel,
  TestTopicModel,
} from '../_models/option.model';

export class LaboratoryModel {
  id: number;
  operationNo: string;
  no: string;
  servicesId: number;
  testStandardId: number;
  testStandard: TestStandardModel;
  testStandardOthers: string;
  testTopicId: number;
  testTopic: TestTopicModel;
  sampleTypeId: number;
  sampleTypeOthers: string;
  sampleType: SampleTypeModel;
  trademark: string;
  model: string;
  quantity: string;
  inputVoltage: string;
  acdc: string;
  frequency: string;
  power: string;
  current: string;
  productCode: string;
  lightsourceTypeId: number;
  lightsourceType: LightsourceTypeModel;
  lightsourceTypeOthers: string;
  lightsourceTrademark: string;
  lightsourceModel: string;
  lightsourceDONo: string;
  lightsourceQuantity: number;
  lightsourceProductCode: string;
  controlGearTypeId: number;
  controlGearType: ControlGearTypeModel;
  controlGearTypeOthers: string;
  controlGearTrademark: string;
  controlGearModel: string;
  controlGearDONo: string;
  controlGearQuantity: number;
  controlGearProductCode: string;
  othersDescription: string;
  othersTrademark: string;
  othersModel: string;
  othersDONo: string;
  othersQuantity: number;
  othersProductCode: string;
  wantReport: boolean;
  reportLang: boolean;
  uncertainty: boolean;
  decisionRule: string;
  wantReturned: boolean;
  receiveTestResult: string;
  doNumber: string;
  otherDetails: string;
  reason: string;

  file1: File;
  attachFile1: string;
  file2: File;
  attachFile2: string;
  file3: File;
  attachFile3: string;
  file4: File;
  attachFile4: string;
  file5: File;
  attachFile5: string;

  isStart: boolean;
  userStartId: number;
  userStart: UserModel;
  startDateTime: string;
  isInProcessRecord: boolean;
  inProcessRecordDateTime: string;
  isTestCertificate: boolean;
  testCertificateDateTime: string;
  isTaskSubmission: boolean;
  taskSubmissionDateTime: string;
  reviewProject: ReviewProjectModel;

  paymentProjects: PaymentProjectModel[];

  inprocessLaboratory: InprocessLaboratoryModel;
  labOperatorElseRefId: number;
  testCertificate: TestCertificateModel;
  submission: SubmissionLabModel;

  propertiesTotal: number;
  propertiesReturned: number;
  propertiesDiscard: number;
  propertiesFReturned: number;
  propertiesFDiscard: number;

  isConfirm: boolean;
  isApplyReview: boolean;
  applyReviewDateTime: string;
  isPayment: boolean;
  paymentDateTime: string;
  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;
  laboratoryOldId: number;
  creationDateTime: string;
  lastUpdateDateTime: string;

  userApplyReviewId: number;
  userApplyReview: UserModel;
  userInProcessRecordId: number;
  userInProcessRecord: UserModel;
  userTestCertificateId: number;
  userTestCertificate: UserModel;
  userTaskSubmissionId: number;
  userTaskSubmission: UserModel;
  userPaymentId: number;
  userPayment: UserModel;

  file_arr: Array<File> = [];

  clear() {
    this.id = 0;
    this.servicesId = 0;
    this.testStandardId = 0;
    this.testStandard = new TestStandardModel();
    this.testStandardOthers = '';
    this.testTopicId = 0;
    this.testTopic = new TestTopicModel();
    this.sampleTypeId = 0;
    this.sampleTypeOthers = '';
    this.sampleType = new SampleTypeModel();
    this.trademark = '';
    this.model = '';
    this.quantity = '';
    this.inputVoltage = '';
    this.acdc = '';
    this.frequency = '';
    this.power = '';
    this.current = '';
    this.productCode = '';
    this.lightsourceTypeId = 0;
    this.lightsourceType = new LightsourceTypeModel();
    this.lightsourceTypeOthers = '';
    this.lightsourceTrademark = '';
    this.lightsourceModel = '';
    this.lightsourceDONo = '';
    this.lightsourceQuantity = 0;
    this.lightsourceProductCode = '';
    this.controlGearTypeId = 0;
    this.controlGearType = new ControlGearTypeModel();
    this.controlGearTypeOthers = '';
    this.controlGearTrademark = '';
    this.controlGearModel = '';
    this.controlGearDONo = '';
    this.controlGearQuantity = 0;
    this.controlGearProductCode = '';
    this.othersDescription = '';
    this.othersTrademark = '';
    this.othersModel = '';
    this.othersDONo = '';
    this.othersQuantity = 0;
    this.othersProductCode = '';
    this.wantReport = false;
    this.reportLang = false;
    this.uncertainty = false;
    this.decisionRule = '';
    this.wantReturned = false;
    this.receiveTestResult = '';
    this.doNumber = '';
    this.otherDetails = '';

    this.file1 = null;
    this.attachFile1 = '';
    this.file2 = null;
    this.attachFile2 = '';
    this.file3 = null;
    this.attachFile3 = '';
    this.file4 = null;
    this.attachFile4 = '';
    this.file5 = null;
    this.attachFile5 = '';

    this.isConfirm = false;
    this.isApplyReview = false;
    this.userCreateId = 0;
    this.userCreate = new UserModel();
    this.userEditId = 0;
    this.userEdit = new UserModel();
    this.laboratoryOldId = 0;
    this.creationDateTime = '';
    this.lastUpdateDateTime = '';

    this.operationNo = '';
    this.no = '';

    this.inprocessLaboratory = new InprocessLaboratoryModel();
    this.testCertificate = new TestCertificateModel();
    this.submission = new SubmissionLabModel();

    this.propertiesTotal = null;
    this.propertiesReturned = null;
    this.propertiesDiscard = null;
    this.propertiesFReturned = null;
    this.propertiesFDiscard = null;
  }
}
