import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import { PatentsTrademarkModel } from '../index';

const API_SERVICE_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class PatentsTrademarkService {
  constructor(private http: HttpClient) {}

  // READ
  getAllPatentTrademarks(): Observable<PatentsTrademarkModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PatentTrademark`;
    return this.http.get<PatentsTrademarkModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getPatentTrademarkById(id: number): Observable<PatentsTrademarkModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PatentTrademark/${id}`;
    return this.http.get<PatentsTrademarkModel>(_url, { headers: httpHeaders });
  }

  // READ
  getPatentTrademarkOldById(id: number): Observable<PatentsTrademarkModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PatentTrademarkOld/${id}`;
    return this.http.get<PatentsTrademarkModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  createPatentTrademark(
    model: PatentsTrademarkModel
  ): Observable<PatentsTrademarkModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'patentTrademarkTopicId',
      model.patentTrademarkTopicId == null
        ? null
        : model.patentTrademarkTopicId.toString()
    );
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);
    formData.append('topicOthers', model.topicOthers);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/PatentTrademark`;
    return this.http.post<PatentsTrademarkModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updatePatentTrademark(
    model: PatentsTrademarkModel
  ): Observable<PatentsTrademarkModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append(
      'patentTrademarkTopicId',
      model.patentTrademarkTopicId == null
        ? null
        : model.patentTrademarkTopicId.toString()
    );
    formData.append('modelName', model.modelName);
    formData.append('otherDetails', model.otherDetails);
    formData.append('topicOthers', model.topicOthers);

    formData.append('reason', model.reason);
    formData.append('file1', model.file1);
    formData.append('attachFile1', model.attachFile1);
    formData.append('file2', model.file2);
    formData.append('attachFile2', model.attachFile2);
    formData.append('file3', model.file3);
    formData.append('attachFile3', model.attachFile3);
    formData.append('file4', model.file4);
    formData.append('attachFile4', model.attachFile4);
    formData.append('file5', model.file5);
    formData.append('attachFile5', model.attachFile5);

    formData.append('isConfirm', model.isConfirm.toString());
    // formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_SERVICE_URL + `/PatentTrademark`;
    return this.http.put<PatentsTrademarkModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // DELETE => delete from the server
  deletePatentTrademark(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_SERVICE_URL + `/PatentTrademark/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }

  // READ
  updateInProcessRecord(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url =
      API_SERVICE_URL + `/InProcessRecord/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }
}
