import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
// import 'rxjs/add/operator/map';
// import { Observable } from 'rxjs/Observable';

// Models
import { AuthenModel, AuthenService, UserModel } from 'src/app/core/authen';
import { ContentModel, ContentService } from 'src/app/core/admin';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public urlApi: string =
    'https://jsonplaceholder.typicode.com/posts?fbclid=IwAR19xkKNN1KoqMBCajut1bxZe_ZfLoTtPMSurIfDQvT8-6aVj2SnXAXsvrM';
  public responseValue: any;

  reactiveForm: FormGroup;
  submitted: boolean = true;
  display = 'none';

  isAlready: boolean = false;

  content: ContentModel = new ContentModel();
  isHidePass: boolean = true;
  isHideConPass: boolean = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private contentService: ContentService,
    private authenService: AuthenService,
    private datePipe: DatePipe,
    private notifier: NotifierService
  ) {
    this.reactiveForm = this.formBuilder.group(
      {
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        confirm_email: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        company_name: new FormControl(''),
        phone_number: new FormControl('', [Validators.required]),
        company_address: new FormControl('', [Validators.required]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
        ]),
        confirm_password: new FormControl('', [Validators.required]),
      },
      {
        validators: this.mustMatch('password', 'confirm_password'),
      }
    );

    this.validateAllFormFields(this.reactiveForm);
  }

  ngOnInit(): void {
    this.contentService.getContents().subscribe((res) => {
      if (res) {
        this.content = res;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get f() {
    return this.reactiveForm.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  matchEmail(controlEmail: string, matchingControlEmail: string) {
    return (formGroup: FormGroup) => {
      const email1 = formGroup.controls[controlEmail];
      const email2 = formGroup.controls[matchingControlEmail];
      if (email1.errors && !email2.errors.matchEmail) {
        return;
      }
      if (email1.value !== email2.value) {
        email2.setErrors({ matchEmail: true });
      } else {
        email2.setErrors(null);
      }
    };
  }

  SignUp(regisData: any) {
    let data = regisData.value;
    const _user = this.prepareForm(data);

    if (_user != null) {
      this.authenService.signUp(_user).subscribe(
        (res: UserModel) => {
          this.responseValue = res;
          this.authenService.signIn(_user).subscribe((res: UserModel) => {
            localStorage.setItem(environment.authTokenKey, res.token);
            this.notifier.notify('success', 'Register succesfully.');
            this.router.navigate(['/pages']);
          });
        },
        (err: HttpErrorResponse) => {
          if (err.error.value == 'already email') {
            this.isAlready = true;
            this.notifier.notify(
              'error',
              'This email has already been taken.'
            );
          } else {
            this.notifier.notify(
              'error',
              'an error occurred.'
            );
          }


          // กรณี error
          if (err.error instanceof Error) {
            // กรณี error ฝั่งผู้ใช้งาน หรือ การเชื่อมต่อเกิด error ขึ้น
            console.log('An error occurred:', err.error.message);
          } else {
            // กรณี error ฝั่ง server ไม่พบไฟล์ ,server error
            console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            );
          }
        }
      );
    }
  }

  /**
   * is show password to text
   */
  isShow(type: string = 'isHidePass' || 'isHideConPass') {
    if (type == 'isHidePass') {
      this.isHidePass = !this.isHidePass;
    } else if (type == 'isHideConPass') {
      this.isHideConPass = !this.isHideConPass;
    }
  }

  // prepare form
  prepareForm(data: any): UserModel {
    const _user = new UserModel();
    _user.clear();
    _user.id = undefined;
    _user.fName = data.first_name;
    _user.lName = data.last_name;
    _user.email = data.email;
    _user.confirm_email = data.confirm_email;
    _user.username = data.email;
    _user.company = data.company_name;
    _user.phoneNumber = data.phone_number;
    _user.companyAddress = data.company_address;
    _user.role = 5;
    _user.userUserGroups = null;
    _user.password = data.password;
    _user.confirm_password = data.confirm_password;
    _user.creationDateTime = this.datePipe.transform(
      Date.now(),
      'yyyy-MM-ddTHH:mm:ss'
    );
    _user.lastUpdateDateTime = this.datePipe.transform(
      Date.now(),
      'yyyy-MM-ddTHH:mm:ss'
    );

    return _user;
  }

  login() {
    this.display = 'block';
  }
  closeHandled(accept: boolean) {
    this.display = 'none';
    if (accept) {
      this.SignUp(this.reactiveForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
}
