import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkAreaModel, StandardModel } from 'src/app/core/laboratory';
import {
  EquipmentModel,
  EquipmentService,
} from 'src/app/core/management-system';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbdSortableHeader, SortEvent } from '../../admin/task/task.component';

const compare = (v1: any, v2: any) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
@Component({
  selector: 'app-print-e-plan',
  templateUrl: './print-e-plan.component.html',
  styleUrls: ['./print-e-plan.component.css'],
})
export class PrintEPlanComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private equipmentService: EquipmentService
  ) {}

  equipmentsTotal: EquipmentModel[] = [];
  equipments: EquipmentModel[] = [];
  equipmentsSearch: EquipmentModel[] = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  collectionSize = 4;
  filter = '';
  sortColumn = '';
  sortDirection = '';
  customerTypeCompany = '';
  /** option */
  workAreas: WorkAreaModel[] = [];
  standards: StandardModel[] = [];
  ngOnInit(): void {
    this.sortColumn =
      this.activatedRoute.snapshot.queryParamMap.get('sortColumn');
    this.sortDirection =
      this.activatedRoute.snapshot.queryParamMap.get('sortDirection');
    this.filter = this.activatedRoute.snapshot.queryParamMap.has('s')
      ? this.activatedRoute.snapshot.queryParamMap.get('s')
      : '';
    this.customerTypeCompany = this.activatedRoute.snapshot.queryParamMap.has(
      's2'
    )
      ? this.activatedRoute.snapshot.queryParamMap.get('s2')
      : '';

    this.getDataTable();
  }
  getDataTable() {
    this.equipmentService
      .getAllEquipments()
      .subscribe((res: EquipmentModel[]) => {
        console.log('test');
        this.equipmentsTotal = res;
        this.equipments = res;
        this.equipmentsSearch = res;

        this.collectionSize = res.length;
        if (this.sortColumn != undefined) {
          setTimeout(() => {
            this.onSort({
              column: this.sortColumn,
              direction: this.sortDirection == 'desc' ? 'desc' : 'asc',
            });
            setTimeout(() => {
              this.somethingChanged2();
            }, 1000);
          }, 1000);
        }
        if (this.filter != '') {
          setTimeout(() => {
            this.somethingChanged2();
          }, 1000);
        }
        if (this.customerTypeCompany != '') {
          setTimeout(() => {
            this.somethingChanged2();
          }, 1000);
        }
        console.log(this.customerTypeCompany);
        setTimeout(() => {
          this.print();
        }, 4000);
      });
  }
  onSort({ column, direction }: SortEvent) {
    this.sortColumn = column.toString();
    this.sortDirection = direction;
    console.log('sort');
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.equipmentsSearch = this.equipmentsSearch;
    } else {
      // this.equipmentsSearch = [...this.equipmentsTotal].sort((a, b) => {
      //   const res = compare(a[column], b[column]);
      //   return direction === 'asc' ? res : -res;
      // });
      if (column.toString().includes('date')) {
        this.equipmentsSearch = [...this.equipmentsSearch].sort((a, b) => {
          let ca = new Date(a[column]).getTime();
          let cb = new Date(b[column]).getTime();
          const res = compare(ca, cb);
          return direction === 'asc' ? res : -res;
        });
      } else {
        this.equipmentsSearch = [...this.equipmentsSearch].sort((a, b) => {
          var nameA = a[column] ? a[column].toLowerCase() : '',
            nameB = b[column] ? b[column].toLowerCase() : '';
          let res = -1;
          if (nameA < nameB)
            //sort string ascending
            res = -1;
          if (nameA > nameB) res = 1;
          return direction === 'asc' ? res : -res;
        });
      }
    }
  }

  somethingChanged() {
    this.equipmentsSearch = this.equipmentsTotal.filter((t) => {
      return (
        t.workArea.name == this.customerTypeCompany ||
        this.customerTypeCompany == ''
      );
    });
    this.equipmentsSearch = this.equipmentsSearch.filter((t) => {
      const term = this.filter.toLowerCase();

      let name = t.name ? t.name : '';
      let division = t.division ? t.division : '';
      let divisionFName = t.responsiblePerson ? t.responsiblePerson.fName : '';
      let divisionLName = t.responsiblePerson ? t.responsiblePerson.lName : '';

      return (
        t.no.toLowerCase().trim().includes(term) ||
        name.toLowerCase().trim().includes(term) ||
        division.toLowerCase().trim().includes(term) ||
        divisionFName.toLowerCase().trim().includes(term) ||
        divisionLName.toLowerCase().trim().includes(term)
      );
    });
    this.collectionSize = this.equipmentsSearch.length;
  }
  onChangeOption() {}
  somethingChanged2() {
    this.equipmentsSearch = this.equipmentsSearch.filter((t) => {
      return (
        t.workArea.name == this.customerTypeCompany ||
        this.customerTypeCompany == ''
      );
    });
    this.equipmentsSearch = this.equipmentsSearch.filter((t) => {
      const term = this.filter.toLowerCase();

      let name = t.name ? t.name : '';
      let division = t.division ? t.division : '';
      let divisionFName = t.responsiblePerson ? t.responsiblePerson.fName : '';
      let divisionLName = t.responsiblePerson ? t.responsiblePerson.lName : '';

      return (
        t.no.toLowerCase().trim().includes(term) ||
        name.toLowerCase().trim().includes(term) ||
        division.toLowerCase().trim().includes(term) ||
        divisionFName.toLowerCase().trim().includes(term) ||
        divisionLName.toLowerCase().trim().includes(term)
      );
    });
    this.collectionSize = this.equipmentsSearch.length;
  }
  print() {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    style.appendChild(
      document.createTextNode('@page { size:  landscape; margin: 0in;}')
    );

    head.appendChild(style);
    window.print();
  }
}
