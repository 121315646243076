import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Component
import { EditAlertAuthComponent } from 'src/app/pages/partials';


@Injectable()
export class LayoutUtilsService {

  constructor(
    private modalService: NgbModal,
  ) { }

  /**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
	editAuthElement() {
    return this.modalService.open(EditAlertAuthComponent,
      {
        ariaLabelledBy: 'modal-basic-title'
      });

    // modalRef.result.then(
    //   (result) => {
    //     if (result) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    //   (reason) => { }
    // );
	}

}
