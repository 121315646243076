import { BooleanLiteral } from "typescript";

export class AuthenModel {
  email: string;
  username: string;
  password: string;

  clear() {
    this.email = '';
    this.username = '';
    this.password = '';
  }
}

export class UserModel {
  id: number;
  fName: string;
  lName: string;
  email: string;
  confirm_email: string;
  username: string;
  empoyleeNo: string;
  company: string;
  phoneNumber: string;
  companyAddress: string;
  password: string;
  confirm_password: string;
  role: number;
  userUserGroups: Array<UserOfUserGroupModel> = [];
  creationDateTime: string;
  lastUpdateDateTime: string;
  isDisable: Boolean;

  token: string;

  clear() {
    this.id = undefined;
    this.fName = '';
    this.lName = '';
    this.email = '';
    this.confirm_email = '';
    this.username = '';
    this.empoyleeNo = '';
    this.company = '';
    this.phoneNumber = '';
    this.companyAddress = '';
    this.password = '';
    this.confirm_password = '';
    this.role = undefined;
    this.userUserGroups = [];
    this.creationDateTime = '2021-01-01T00:00:00';
    this.lastUpdateDateTime = '2021-01-01T00:00:00';
    this.isDisable = false;

    this.token = '';
  }
}

export class UserGroupModel {
  id: number;
  value: string;
  name: string;
  creationDateTime: string;
  lastUpdateDateTime: string;

  clear() {
    this.id = undefined;
    this.value = '';
    this.name = '';
    this.creationDateTime = '2021-01-01T00:00:00';
    this.lastUpdateDateTime = '2021-01-01T00:00:00';
  }
}

export class UserOfUserGroupModel {
  userGroupId: number;
  userGroup: UserGroupModel;
  userId: number;
  user: UserModel;

  clear() {
    this.userGroupId = undefined;
    this.userGroup = new UserGroupModel();
    this.userId = undefined;
    this.user = new UserModel();
  }
}
