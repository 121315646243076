import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';

// Models
import { AuthenModel, AuthenService, UserModel } from 'src/app/core/authen';
import { LayoutUtilsService } from 'src/app/core/utils';
import { NotifierOptionsComponent } from 'src/app/pages/partials';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  public loginState: boolean = false;

  // submit(login){
  //   console.log("form submitted", login)

  isHidePass: boolean = true;
  private notifier: NotifierService;

  // }
  public urlApi: string =
    'https://jsonplaceholder.typicode.com/posts?fbclid=IwAR19xkKNN1KoqMBCajut1bxZe_ZfLoTtPMSurIfDQvT8-6aVj2SnXAXsvrM';
  // public urlApi:string = "http://localhost/api/login";
  public responseValue: any;
  // check status
  LoginStatus$ = new BehaviorSubject<boolean>(null);

  public constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenService: AuthenService,
    private layoutUtilsService: LayoutUtilsService,
    notifier: NotifierService
  ) {
    this.notifier = notifier;
  }

  ngOnInit() {}

  onSubmit(f: any) {
    let data = f.value;
    const _auth = this.prepareForm(data);

    if (_auth != null) {
      this.authenService.signIn(_auth).subscribe(
        (res: UserModel) => {
          this.responseValue = res;

          if (res.hasOwnProperty('id')) {
            localStorage.setItem(environment.authTokenKey, res.token);
            let entries = Object.entries(res);
            this.loginState = true;
            this.router.navigate(['/pages']);
            this.notifier.notify('success', 'Welcome LICThailand');
          } else {
            this.loginState = false;
            this.notifier.notify('error', 'Email or Password is not correct');
          }
        },
        (err: HttpErrorResponse) => {
          this.notifier.notify('error', 'Email or Password is not correct');

          // กรณี error
          if (err.error instanceof Error) {
            // กรณี error ฝั่งผู้ใช้งาน หรือ การเชื่อมต่อเกิด error ขึ้น
            console.log('An error occurred:', err.error.message);
          } else {
            // กรณี error ฝั่ง server ไม่พบไฟล์ ,server error
            console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            );
          }
        }
      );
    }
  }

  // prepare form
  prepareForm(data: any): AuthenModel {
    const _auth = new AuthenModel();
    _auth.email = data.email;
    _auth.password = data.password;

    return _auth;
  }

  /**
   * is show password to text
   */
  isShow() {
    this.isHidePass = !this.isHidePass;
  }

}
