<div class="divFooter">

  <div class="page__content">
    <div class="header__bar">
      <div class="row">
        <div class="col-4" style="padding-left: 70px;">
          <div class="main__logo__flex">
            <img class="main__logo" src="assets/images/lic-logo02.jpg" style="border-right: 2px solid #000;" />
            <img class="main__logo" src="assets/images/LE-lighting-logo.png" style="height: 60px; margin-left: 20px;" />
          </div>
        </div>

        <div class="col-8" style="padding-left: 85px;">
          <div class="address__top">
            <label class="label__text">ศูนย์นวัตกรรมผลิตภัณฑ์ไฟฟ้าแสงสว่าง</label>
            <label class="label__text">บริษัท แอล แอนด์ อี แมนูแฟคเจอริ่ง จำกัด</label>
            <label class="label__text">เลขที่ 85/3 ซอยประชุมพร ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210</label>
            <label class="label__text">โทรศัพท์ : 02-5734787, 088-2278710 เลขประจำตัวผู้เสียภาษี : 0105535006784</label>
            <label class="label__text">E-mail : Lab-lem@lighting.co.th , Lab.lighting@gmail.com , lic@lighting.co.th</label>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="title__header__content">
        <label class="font__title__header">ใบแจ้งราคา / ใบเสนอราคา</label>
        <label class="font__title__header">PROFORMA INV. / QUOTATION</label>
      </div>

      <table>
        <tbody>
          <tr>
            <td scope="col" style="width: 25%;">To / เรียน</td>
            <td scope="col" style="width: 0.5%;" class="padding__none__dot">:</td>
            <td scope="col" style="width: 54.5%;" class="text__normal">คุณ{{ quotationForm.to }}</td>
            <td scope="col" style="width: 20%;" rowspan="2" class="end__col">
              <div class="end__col__detail">
                <label style="margin-bottom: 4px">Quotation No.</label>
                <label style="margin-bottom: 4px">เลขที่ใบเสนอราคา</label>
                <label style="margin-bottom: 4px" class="text__normal">{{ quotationForm.quotationNo }}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Company Name / ชื่อบริษัท</td>
            <td class="padding__none__dot">:</td>
            <td class="text__normal">{{ quotationForm.companyName }}</td>
          </tr>
          <tr>
            <td>Company Address / ที่อยู่บริษัท</td>
            <td class="padding__none__dot">:</td>
            <td class="text__normal">{{ quotationForm.companyAddress }}</td>
            <td rowspan="2" class="end__col">
              <div class="end__col__detail">
                <label style="margin-bottom: 4px">Request No.</label>
                <label style="margin-bottom: 4px">หมายเลขขอรับบริการ</label>
                <label style="margin-bottom: 4px" class="text__normal">{{ getProductNo(quotationForm) }}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Phone No. / เบอร์โทรศัพท์</td>
            <td class="padding__none__dot">:</td>
            <td class="text__normal">{{ quotationForm.phoneNumber }}</td>
          </tr>
          <tr>
            <td>E-mail / อีเมล</td>
            <td class="padding__none__dot">:</td>
            <td class="text__normal">{{ quotationForm.email }}</td>
            <td rowspan="2" class="end__col">
              <div class="end__col__detail">
                <label style="margin-bottom: 4px">Date / วันที่</label>
                <label style="margin-bottom: 4px" class="text__normal">{{ getProjectDate(quotationForm) | date:'yyyy-MM-dd' }}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Subject / เรื่อง</td>
            <td class="padding__none__dot">:</td>
            <td class="text__normal">{{ quotationForm.subject }}</td>
          </tr>
        </tbody>
      </table>

      <div class="totle__mid__content">
        <label class="font__title__mid">ทางห้องปฏิบัติการมีความยินดีที่จะเสนอราคาและรายละเอียดของค่าบริการดังต่อไปนี้</label>
        <label class="font__title__mid">We are pleased to offer our quotation for your consideration as indicated below</label>
      </div>

      <table>
        <thead>
          <tr>
            <th scope="col" style="width: 7%;">
              <div class="title__header__table">
                <label>Item</label>
                <label>รายการที่</label>
              </div>
            </th>
            <th scope="col" style="width: 7%;">
              <div class="title__header__table">
                <label>Quantity</label>
                <label>จำนวน</label>
              </div>
            </th>
            <th scope="col" style="width: 7%;">
              <div class="title__header__table">
                <label>Unit</label>
                <label>หน่วย</label>
              </div>
            </th>
            <th scope="col" colspan="2" style="width: 34%;">
              <div class="title__header__table">
                <label>Decription</label>
                <label>รายละเอียด</label>
              </div>
            </th>
            <th scope="col" style="width: 15%;">
              <div class="title__header__table">
                <label>Operation</label>
                <label>หมายเลขดำเนินการ</label>
              </div>
            </th>
            <th scope="col" style="width: 15%;">
              <div class="title__header__table">
                <label>Unit Price (Baht)</label>
                <label>ราคา/หน่วย (บาท)</label>
              </div>
            </th>
            <th scope="col" style="width: 15%;">
              <div class="title__header__table">
                <label>Net Amount (Baht)</label>
                <label>ราคารวม (บาท)</label>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="mid__table">
          <tr *ngFor="let element of quotationForm.paymentDetails">
            <td rowspan="1" class="text__normal text__center">
              {{ element.no }}
            </td>
            <td rowspan="1" class="text__normal text__center">
              {{ element.quantity }}
            </td>
            <td rowspan="1" class="text__normal text__center">
              {{ element.unit }}
            </td>
            <td rowspan="1" colspan="2" class="text__normal text__start">
              {{ element.description }}
            </td>
            <td rowspan="1" class="text__normal text__center">
              {{ element.operationNo }}
            </td>
            <td rowspan="1" class="text__normal text__end">
              {{ element.unitPrice | number:'1.2-2' }}
            </td>
            <td rowspan="1" class="text__normal text__end">
              {{ element.amount | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 7%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 7%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 7%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 16%; border-right: 1px solid #fff !important;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 16%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 15%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 15%;"></td>
            <td rowspan="1" style="border-top: 1px solid #fff; width: 15%; border-left: 1px solid #fff;"></td>
          </tr>
        </tbody>

        <tfoot class="mid__table">
          <tr>
            <td colspan="4">
              Total / จำนวนงานรวม
            </td>
            <td class="text__normal text__center">
              {{ quotationForm.totalQuantity }}
            </td>
            <td colspan="2">
              Subtotal (Baht) / ค่าดำเนินการรวม (บาท)
            </td>
            <td colspan="1" class="text__normal">
              {{ quotationForm.subtotal | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td colspan="4">
              Term of Payment / เงื่อนไขการชำระเงิน
            </td>
            <td class="text__normal text__center" [innerHTML]="quotationForm.termOfPayment"></td>
            <td colspan="2">
              Discount (Baht) / ส่วนลด (บาท)
            </td>
            <td colspan="1" class="text__normal">
              {{ quotationForm.discount | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td colspan="4">
              Price Validity / กำนดการยืนราคา (วัน)
            </td>
            <td class="text__normal text__center">
              {{ quotationForm.priceValidity }}
            </td>
            <td colspan="2">
              Total (Baht) / ยอดรวมก่อนหักภาษีมูลค่าเพิ่ม (บาท)
            </td>
            <td colspan="1" class="text__normal">
              {{ quotationForm.total | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td colspan="5" rowspan="2">
              <div class="remark__text">
                <label>Remark / หมายเหตุ : {{ quotationForm.remark }}</label>
              </div>
            </td>
            <td colspan="2">
              VAT 7% / ภาษีมูลค่าเพิ่ม 7 %
            </td>
            <td colspan="1" class="text__normal">
              {{ quotationForm.vat | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              Grand Total (Baht) / ยอดรวมสุทธิ (บาท)
            </td>
            <td colspan="1" class="text__normal">
              {{ quotationForm.grandTotal | number:'1.2-2' }}
            </td>
          </tr>
        </tfoot>
      </table>

      <div class="totle__footer__content">
        <label class="font__title__footer">Thank you for your inquiry, We look forward to give you our best service.</label>
        <label class="font__title__footer">เราหวังเป็นอย่างยิ่งว่าจะได้บริการท่านในเร็ววันนี้</label>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="cert__footer__prename">
            <div style="margin: 18px 0px;">
              <div class="row">
                <div class="col-6">
                  <label>Proposed by / ผู้เสนอราคา</label>
                </div>
                <div class="col-6">
                  <div class="licent__name">
                    <img src="/assets/images/b.png" height="65px">
                    <!-- <label>: ...............................................</label> -->
                    <label [innerHTML]="getProposedName()"></label>
                    <label>{{ dateNow | date:'yyyy-MM-dd' }}</label>
                  </div>
                </div>
              </div>
            </div>


            <div style="margin: 18px 0px;">
              <div class="row">
                <div class="col-6">
                  <label>Authorized by / ผู้ทบทวน</label>
                </div>
                <div class="col-6">
                  <div class="licent__name">
                    <img src="/assets/images/a.png" height="60px">
                    <!-- <label>: ...............................................</label> -->
                    <label [innerHTML]="getAuthorizedName()"></label>
                    <label>{{ dateNow | date:'yyyy-MM-dd' }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 footer__accept">
          <div style="margin-bottom: 60px;">
            <label>Accepted quotation / ยืนยันใบเสนอราคา :</label>
          </div>

          <div class="licent__footer__accept">
            <label>.....................................................</label>
            <label [innerHTML]="dot()">

            </label>
            <label>................./................./.................</label>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
