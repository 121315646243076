import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  TestTopicModel,
  TestStandardModel,
  SampleTypeModel,
  LightsourceTypeModel,
  ControlGearTypeModel
} from '../index';
import {
  DesignSimulationTopicModel,
  EquipmentMaintenanceRepairModel,
  MaintenanceModel,
  MaterialResolutionModel,
  PatentTrademarkTopicModel,
  ProductRegistrationSupportTopicModel,
  RapidPrototypingTopicModel,
  ReviewTestMethodTopicModel,
  StandardModel,
  WorkAreaModel
} from '../_models/option.model';

const API_OPTION_URL = environment.API_BASE_URL + '/api/Option';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor(
    private http: HttpClient,
  ) { }

  // READ
  getAllTestStandards(): Observable<TestStandardModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestStandard`;
    return this.http.get<TestStandardModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getTestStandardByid(id: number): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestStandard/${id}`;
    return this.http.get<TestStandardModel>(_url, { headers: httpHeaders });
  }

  // READ
  createTestStandard(model: TestStandardModel): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestStandard`;
    return this.http.post<TestStandardModel>(_url, model, { headers: httpHeaders });
  }

  // READ
  updateTestStandard(model: TestStandardModel): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestStandard/${model.id}`;
    return this.http.put<TestStandardModel>(_url, model, { headers: httpHeaders });
  }

  // READ
  deleteTestStandard(id: number): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestStandard/${id}`;
    return this.http.delete<TestStandardModel>(_url, { headers: httpHeaders });
  }

  // READ
  updateTestStandardComplete(id: number): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_OPTION_URL + `/TestStandard/Complete/${id}`;
    return this.http.put<TestStandardModel>(_url, body, { headers: httpHeaders });
  }

  // READ
  updateTestStandardDisable(id: number): Observable<TestStandardModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_OPTION_URL + `/TestStandard/isDisable/${id}`;
    return this.http.put<TestStandardModel>(_url, body, { headers: httpHeaders });
  }

  // READ
  getTestTopicById(id: number): Observable<TestTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/TestTopic/${id}`;
    return this.http.get<TestTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllSampleTypes(): Observable<SampleTypeModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/SampleType`;
    return this.http.get<SampleTypeModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllLightsourceTypes(): Observable<LightsourceTypeModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/LightsourceType`;
    return this.http.get<LightsourceTypeModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllControlGearTypes(): Observable<ControlGearTypeModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/ControlGearType`;
    return this.http.get<ControlGearTypeModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllDesignSimulationTopics(): Observable<DesignSimulationTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/DesignSimulationTopic`;
    return this.http.get<DesignSimulationTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllPatentTrademarkTopics(): Observable<PatentTrademarkTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/PatentTrademarkTopic`;
    return this.http.get<PatentTrademarkTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllProductRegistrationSupportTopics(): Observable<ProductRegistrationSupportTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/ProductRegistrationSupportTopic`;
    return this.http.get<ProductRegistrationSupportTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllRapidPrototypingTopics(): Observable<RapidPrototypingTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/RapidPrototypingTopic`;
    return this.http.get<RapidPrototypingTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllMaterialResolutions(): Observable<MaterialResolutionModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/MaterialResolution`;
    return this.http.get<MaterialResolutionModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllMaterials(): Observable<MaintenanceModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/Maintenance`;
    return this.http.get<MaintenanceModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllReviewTestMethodTopics(): Observable<ReviewTestMethodTopicModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/ReviewTestMethodTopic`;
    return this.http.get<ReviewTestMethodTopicModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllEquipmentMaintenanceRepairs(): Observable<EquipmentMaintenanceRepairModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/EquipmentMaintenanceRepair`;
    return this.http.get<EquipmentMaintenanceRepairModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllWorkAreas(): Observable<WorkAreaModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/WorkArea`;
    return this.http.get<WorkAreaModel[]>(_url, { headers: httpHeaders });
  }

  // READ
  getAllStandards(): Observable<StandardModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_OPTION_URL + `/Standard`;
    return this.http.get<StandardModel[]>(_url, { headers: httpHeaders });
  }

}
