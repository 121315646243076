import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel, UserService } from 'src/app/core/authen';
import { PaymentProjectModel, ServicesService } from 'src/app/core/laboratory';

@Component({
  selector: 'app-payment-project',
  templateUrl: './payment-project.component.html',
  styleUrls: ['./payment-project.component.scss']
})
export class PaymentProjectComponent implements OnInit {

  projectId: number = 0;
  serviceId: number = 0;

  quotationForm: PaymentProjectModel = <PaymentProjectModel>{};
  users: Array<UserModel> = [];

  dateNow: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private servicesService: ServicesService,
    private datePipe: DatePipe,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {
    this.dateNow = datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.quotationForm = new PaymentProjectModel();
    this.quotationForm.clear();

    this.activatedRoute.params.subscribe((params) => {
      const _serviceId = params['serviceId'];
      const _projectId = params['projectId'];
      if (_projectId && _serviceId) {
        this.serviceId = Number(_serviceId);
        this.projectId = Number(_projectId);
      }
    });

    this.getDataOption();
  }

  /**
   * get data option
   */
  getDataOption() {
    this.userService
      .getAllUserByGroupId(8)
      .subscribe((res: UserModel[]) => {
        if (res) {
          this.users = res;
          this.getDataTable();
        }
      })
  }

  /**
   * get data table
   */
  getDataTable() {
    this.servicesService
      .getPaymentProjectById(this.serviceId, this.projectId)
      .subscribe((res: PaymentProjectModel) => {
        if (res) {
          this.quotationForm = res;

          this.cd.detectChanges();
          setTimeout(function () { window.print(); }, 500);
        }
      })
  }

  /**
   * method name
   */
  getMethodName(id: number): string {
    let name = '';

    if (id == 1) {
      name = 'PO&nbsp;&nbsp;ใบสั่งซื้อ'
    } else if (id == 2) {
      name = 'Cash&nbsp;&nbsp;เงินสด'
    } else if (id == 3) {
      name = 'Transfer&nbsp;&nbsp;โอนเงิน'
    }

    return name;
  }

  /**
   * get proposed name
   */
  getProposedName() {
    let user = this.users.find(e => e.id == this.quotationForm.proposedId);

    let name = user ? '(&nbsp;'+user.fName+'&nbsp;&nbsp;'+user.lName+'&nbsp;)': '';

    return name;
  }

  /**
   * get authorized name
   */
  getAuthorizedName() {
    let user = this.users.find(e => e.id == this.quotationForm.authorizedId);

    let name = user ? '(&nbsp;'+user.fName+'&nbsp;&nbsp;'+user.lName+'&nbsp;)': '';

    return name;
  }

  /**
   * get product type
   */
  getProductType(element: PaymentProjectModel) {
    if (element.laboratory) {
      return element.laboratory;
    } else if (element.rapidPrototy) {
      return element.rapidPrototy;
    } else if (element.designSimulation) {
      return element.designSimulation;
    } else if (element.patentTrademark) {
      return element.patentTrademark;
    } else if (element.productRegistrationSupport) {
      return element.productRegistrationSupport;
    } else if (element.otherService) {
      return element.otherService;
    } else if (element.projectManagement) {
      return element.projectManagement;
    }
  }

  /**
   * get product no name
   */
  getProductNo(element: PaymentProjectModel): string {
    let projectType = this.getProductType(element);
    let result = projectType.no;
    return result;
  }

  /**
   * customer name
   */
  getProjectDate(element: PaymentProjectModel): string {
    let projectType = this.getProductType(element);
    let result = projectType ? projectType.creationDateTime: '';
    return result;
  }

  dot(): string {
    let result = '(';

    for (let i = 0; i < 55; i++) {
      if (i == 54) {
        result = result+')';
      } else {
        result = result+'&nbsp;';
      }
    }

    return result;
  }

}
