import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  ProjectManagementModel,
  ProjectManagementServiceModel,
} from '../index';

const API_PROJECT_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class ProjectManagementService {
  constructor(private http: HttpClient) {}

  // READ
  getAllProjectManagements(): Observable<ProjectManagementServiceModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_PROJECT_URL + `/ProjectManagement`;
    return this.http.get<ProjectManagementServiceModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getProjectManagementById(id: number): Observable<ProjectManagementModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_PROJECT_URL + `/ProjectManagement/${id}`;
    return this.http.get<ProjectManagementModel>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getProjectManagementOldById(
    id: number
  ): Observable<ProjectManagementModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_PROJECT_URL + `/ProjectManagementOld/${id}`;
    return this.http.get<ProjectManagementModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  createProjectManagement(
    model: ProjectManagementModel
  ): Observable<ProjectManagementModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());
    formData.append('projectName', model.projectName);
    formData.append('projectInitials', model.projectInitials);
    formData.append('projectLeader', model.projectLeader);
    formData.append('briefDescription', model.briefDescription);
    formData.append('pdRepresentative', model.pdRepresentative);
    formData.append('projectAdviser', model.projectAdviser);
    formData.append('requestAction', model.requestAction);

    formData.append('isConfirm', model.isConfirm.toString());
    formData.append('isApplyReview', model.isApplyReview.toString());

    const _url = API_PROJECT_URL + `/ProjectManagement`;
    return this.http.post<ProjectManagementModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // UPDATE =>  PUT: add a new to the server
  updateProjectManagement(
    model: ProjectManagementModel
  ): Observable<ProjectManagementModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    // const httpHeaders = new HttpHeaders({
    //   Authorization: `Bearer ${userToken}`
    // });
    // httpHeaders.set('accept', '*/*');
    // httpHeaders.set('Content-Type', 'multipart/form-data');

    // const formData = new FormData();
    // formData.append('id', model.id.toString());
    // formData.append('projectName', model.projectName);
    // formData.append('projectInitials', model.projectInitials);
    // formData.append('projectLeader', model.projectLeader);
    // formData.append('briefDescription', model.briefDescription);
    // formData.append('pdRepresentative', model.pdRepresentative);
    // formData.append('projectAdviser', model.projectAdviser);
    // formData.append('requestAction', model.requestAction);

    // formData.append('isConfirm', model.isConfirm.toString());
    // formData.append('isApplyReview', model.isApplyReview.toString());
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    model.decision = model.decision ? model.decision : 0;
    const _url = API_PROJECT_URL + `/ProjectManagement`;
    return this.http.put<ProjectManagementModel>(_url, model, {
      headers: httpHeaders,
    });
    // const _url = API_PROJECT_URL + `/ProjectManagement`;
    // return this.http.put<ProjectManagementModel>(_url, formData, { headers: httpHeaders });
  }

  // DELETE => delete from the server
  deleteProjectManagement(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const url = API_PROJECT_URL + `/ProjectManagement/${id}`;
    return this.http.delete<any>(url, { headers: httpHeaders });
  }
}
