<div class="register-container">
  <a href="#"><i class="fas fa-home home-icon"></i></a>
  <div class="row justify-content-center mt-3">
    <div class="sameLine col-md-4">
      <h4 class=" topicText ">Forgot Password <br>
        <span class=" justify-content-center header-thai-font">ลืมรหัสผ่าน</span>
      </h4>


      <div class="inputForm" novalidate>
        <!-- (ngSubmit)="submit(login)" -->
        <div>
          <div>
            <div *ngIf="!status" class="alert-error" style="color: red;">Please check your email and phone number</div>
            <label class="textColor label-inline topic-to-left">Email<br /><span class="nav-thai-font">อีเมล</span></label>
          </div>
          <input required email type="email" id="defaultLoginFormEmail" class="form-control mb-4 " name="email" placeholder="email@address.com" [(ngModel)]="email">
          <!-- <div *ngIf ="email.touched && email.invalid" class="alert-error">Please input your email</div> -->
        </div>
        <div>
          <div>
            <label class="textColor label-inline topic-to-left">Phone Number<br /><span class="nav-thai-font">เบอร์โทร</span></label>
          </div>
          <input required type="phone" class="form-control mb-4 " name="email" placeholder="0xxxxxxxx" [(ngModel)]="phone">
          <!-- <div *ngIf ="email.touched && email.invalid" class="alert-error">Please input your email</div> -->
        </div>
        <div *ngIf="user">
          <div>
            <label class="textColor label-inline topic-to-left">New Password<br /><span class="nav-thai-font">รหัสผ่านใหม่</span></label>
          </div>
          <input class="form-control mb-4 " type="password" [(ngModel)]="user.password">
        </div>
        <br>
        <div class="row gap-comfirm-checkbox">
          <div class="col">
            <button (click)="onSubmit()" class="btn btn-primary  gap-between-button btn-regis1" *ngIf="!user">
              Confirm<br><span class="nav-thai-font">ยืนยัน</span></button>
            <button (click)="onSubmitPassword()" class="btn btn-primary  gap-between-button btn-regis1" *ngIf="user">
              Confirm<br><span class="nav-thai-font">ยืนยัน</span></button>
            <!-- (click)="login()" -->
          </div>
          <!-- <div class="col">
            <a type="button" class="btn btn-primary  gap-between-button btn-regis2" href="/signUp">Register<br><span
                class="nav-thai-font">ลงทะเบียน</span></a>
          </div> -->
        </div>
        <div>
          <p>ยังไม่เป็นสมาชิก ? &nbsp;<a style="color: rgb(22 22 212);" href="/signUp">Register (ลงทะเบียน)</a></p>
          <p>Do not have an Account ? &nbsp;<a style="color: rgb(22 22 212);" href="/signUp">Register (ลงทะเบียน)</a></p>
        </div>
      </div>
    </div>
    <div class="innerDiv">
      <div class="circle c1"></div>
      <div class="circle c2"></div>
      <div class="circle c3"></div>
      <div class="circle c4"></div>
    </div>

  </div>

</div>

<notifier-container></notifier-container>
