import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './authen/sign-in/sign-in.component';
import { SignUpComponent } from './authen/sign-up/sign-up.component';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

// Module
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TheNavbarComponent } from './components/the-navbar/the-navbar.component';
import { ForgotPasswordComponent } from './authen/forgot-password/forgot-password.component';

// UI
import { PartialsModule } from 'src/app/pages/partials/partials.module';
// Layout
import { LayoutUtilsService } from 'src/app/core/utils';
import {
  EditAlertAuthComponent,
  NotifierOptionsComponent,
} from 'src/app/pages/partials';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SortDirective } from './directive/sort.directive';
import { TwoDigitDecimaNumberDirective } from './directive/two-digit-decima-number-directive ';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    // SortDirective,
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PartialsModule,
    NotifierModule.withConfig(customNotifierOptions),
  ],
  providers: [
    DatePipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    LayoutUtilsService,
    ,
  ],
  entryComponents: [EditAlertAuthComponent, NotifierOptionsComponent],
  // exports: [SortDirective],
  bootstrap: [AppComponent],
})
export class AppModule {}
