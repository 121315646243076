<div class="register-container">
  <a href="#"><i class="fas fa-home home-icon"></i></a>
  <div class="outerDiv row justify-content-center mt-3">
    <div class="sameLine">
      <img src="/assets/images/logoNav.png" class="d-inline-block align-top sizeLogo"><br>
      <h4 class=" topicText ">Register <br>
        <span class=" justify-content-center header-thai-font">ลงทะเบียนผู้ใช้ใหม่</span>
      </h4>

      <form class="inputForm" [formGroup]="reactiveForm" novalidate>
        <div class="row">
          <div class="col-sm ">
            <label class="textColor label-inline topic-to-left">First Name *<br /><span class="nav-thai-font">ชื่อ</span></label>
            <input type="text" class="form-control mb-4 " placeholder="First Name" autocomplete="off" required name="firstName" formControlName="first_name">
          </div>
          <div class="col-sm ">
            <label class="textColor label-inline topic-to-left">Last Name *<br /><span class="nav-thai-font">นามสกุล</span></label>
            <input type="text" class="form-control mb-4 " placeholder="Last Name" autocomplete="off" required name="LastName" formControlName="last_name">
          </div>
          <!-- pattern="^[a-aZ-z]+$" -->
        </div>
        <div class="row">
          <label class="textColor label-margin topic-to-left">Email *<br /><span class="nav-thai-font">อีเมล</span></label>
          <input type="email" class="form-control mb-4 setMargin" autocomplete="off" placeholder="Email" required email formControlName="email" [class.is-invalid]="reactiveForm.get('email').invalid && reactiveForm.get('email').touched">
          <div *ngIf="(reactiveForm.get('email').invalid && reactiveForm.get('email').touched) || reactiveForm.get('email').dirty">
            <small *ngIf="f.email.errors?.required" class="text-err margin-text-err">Primary email is required</small>
            <small *ngIf="isAlready && !f.email.errors?.required" class="text-err margin-text-err">This email has already been taken.
            </small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="f.email.errors?.pattern && !isAlready" class="text-err margin-text-err">Please provide a valid email address
            </small>
          </div>
        </div>
        <div class="row">
          <label class="textColor box-inner label-margin topic-to-left">Confirm Email *<br /><span class="nav-thai-font">ตรวจสอบอีเมล</span></label>
          <input type="email" class="form-control mb-4 setMargin" placeholder="Confirm Email" required email formControlName="confirm_email">
          <div *ngIf="(reactiveForm.get('confirm_email').invalid && reactiveForm.get('confirm_email').touched) || reactiveForm.get('confirm_email').dirty">
            <small *ngIf="f.confirm_email.errors?.required" class="text-err margin-text-err">Primary email is
              required</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="f.confirm_email.errors?.matchEmail" class="text-err margin-text-err">Comfirm email didn't
              match
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <label class="textColor label-inline topic-to-left">Company Name (If Any)<br /><span class="nav-thai-font">ชื่อบริษัท</span></label>
            <input type="text" class="form-control mb-4 " placeholder="Company Name" autocomplete="off" name="companyName" formControlName="company_name">
          </div>
          <div class="col-sm">
            <label class="textColor label-inline topic-to-left">Phone *<br /><span class="nav-thai-font">เบอร์โทรศัพท์</span></label>
            <input type="text" (keypress)="numberOnly($event)" class="form-control mb-4 " autocomplete="off" placeholder="Phone" required name="phone" formControlName="phone_number">
          </div>
        </div>
        <div class="row">
          <label class="textColor label-margin topic-to-left">Address *<br /><span class="nav-thai-font">ที่อยู่บริษัท</span></label>
          <input type="text" class="form-control mb-4 setMargin" placeholder="Company Address" autocomplete="off" required name="companyAddress" formControlName="company_address">
        </div>
        <div class="row">
          <div class="col-sm">
            <label class="textColor label-inline topic-to-left">Password *<br /><span class="nav-thai-font">กำหนดรหัสผ่าน</span></label>
            <div class="input-icons">
              <input [type]="isHidePass ? 'password': 'text'" class="form-control mb-4 " placeholder="Password" formControlName="password" required>
              <i class="fa icon cursor__point" style="color: black;" [ngClass]="isHidePass ? 'fa-eye' : 'fa-eye-slash'" (click)="isShow('isHidePass')"></i>
            </div>
            <div class="alert-error" *ngIf="submitted && f.password.errors">
              <small *ngIf="f.password.errors.required" class="text-err">Password is required</small>
              <small *ngIf="f.password.errors.minlength" class="text-err">Password at least 5</small>
            </div>
          </div>
          <div class="col-sm">
            <label class="textColor label-inline topic-to-left">Confirm Password *<br /><span class="nav-thai-font">ยืนยันรหัสผ่าน</span></label>
            <div class="input-icons">
              <input [type]="isHideConPass ? 'password': 'text'" class="form-control mb-4 " placeholder="Confirm Password" formControlName="confirm_password" required>
              <i class="fa icon cursor__point" style="color: black;" [ngClass]="isHideConPass ? 'fa-eye' : 'fa-eye-slash'" (click)="isShow('isHideConPass')"></i>
            </div>
            <div class="alert-error" *ngIf="submitted && f.password.errors">
              <small *ngIf="f.confirm_password.errors.required" class="text-err">Confirm password is required</small>
              <small *ngIf="f.confirm_password.errors.mustMatch" class="text-err">Confirm password didn't match</small>
            </div>
          </div>
        </div>
        <div class="top-section row">
          <div class="col">
            <button type="submit" class="btn btn-primary gap-between-button btn-regis1" [disabled]="reactiveForm.invalid" (click)="login()">Register<br><span class="nav-thai-font">ลงทะเบียนผู้ใช้ใหม่</span></button>

          </div>
        </div>
        <div>
          <p>เป็นสมาชิกอยู่แล้ว ? &nbsp;<a style="color: rgb(22 22 212);" href="/signIn">เข้าสู่ระบบ</a></p>
          <p>Already have as account? &nbsp;<a style="color: rgb(22 22 212);" href="/signIn">Sign in</a></p>
        </div>
      </form>
    </div>

    <div class="innerDiv">
      <div class="circle c1"></div>
      <div class="circle c2"></div>
      <div class="circle c3"></div>
      <div class="circle c4"></div>
      <div class="circle c5"></div>
    </div>

  </div>
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content" style="width: 800px;">
      <div class="modal-header">
        <!-- <h6 class="div-center">หนังสือให้ความยินยอมในการเก็บรวบรวม/ใช้/เปิดเผยข้อมูลส่วนบุคคล</h6> -->
        <button type="button" class="close" aria-label="Close" (click)="closeHandled(false)"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">

        <div [innerHTML]="content.consent"></div>
      </div>
      <div class="modal-footer">
        <div style="    display: block; margin-left: auto; margin-right: auto;">
          <button type="button" class="btn btn-dark" (click)="closeHandled(false)" style="color: white; margin: 0.25rem;">ปฏิเสธทั้งหมด</button>
          <button type="button" class="btn btn-blue" (click)="closeHandled(true)" style="color: white;">ยอมรับทั้งหมด</button>
        </div>

      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
