import { DatePipe } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService, TaskModel } from 'src/app/core/laboratory';
import { SearchModel } from 'src/app/core/laboratory/_models/task.model';
export type SortColumn = keyof any | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

const compare = (v1: any, v2: any) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
@Component({
  selector: 'app-print-task-planning-list',
  templateUrl: './print-task-planning-list.component.html',
  styleUrls: ['./print-task-planning-list.component.scss'],
})
export class PrintTaskPlanningListComponent implements OnInit {
  display = 'none';

  servicesTotal: Array<TaskModel> = [];
  services: Array<TaskModel> = [];
  servicesSearch: Array<TaskModel> = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 4;
  filter = '';
  sortColumn = '';
  sortDirection = '';
  plannedDate = '';
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  plannedDateStart = '';
  plannedDateEnd = '';
  // plannedDate = '';

  constructor(
    private servicesService: ServicesService,
    private router: Router,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar
  ) {
    this.fromDate = calendar.getNext(calendar.getToday(), 'y', -1);
    this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.sortColumn =
      this.activatedRoute.snapshot.queryParamMap.get('sortColumn');
    this.sortDirection =
      this.activatedRoute.snapshot.queryParamMap.get('sortDirection');
    this.filter = this.activatedRoute.snapshot.queryParamMap.has('s')
      ? this.activatedRoute.snapshot.queryParamMap.get('s')
      : '';
    this.plannedDate = this.activatedRoute.snapshot.queryParamMap.has('s2')
      ? this.activatedRoute.snapshot.queryParamMap.get('s2')
      : '';
    this.plannedDate = this.activatedRoute.snapshot.queryParamMap.has('s2')
      ? this.activatedRoute.snapshot.queryParamMap.get('s2')
      : '';
    if (this.plannedDate != '') {
      const pDate = this.plannedDate.split('|');
      this.plannedDateStart = pDate[0];
      this.plannedDateEnd = pDate[1];
    } else {
      this.plannedDateStart = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.plannedDateEnd = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');
    this.getDataTable();
  }

  /**
   * get data table
   */
  getDataTable() {
    // const startDate = this.fromDate
    //   ? this.datePipe.transform(
    //       `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
    //       'yyyy-MM-dd'
    //     )
    //   : null;
    // const endDate = this.toDate
    //   ? this.datePipe.transform(
    //       `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`,
    //       'yyyy-MM-dd'
    //     )
    //   : null;
    const model: SearchModel = new SearchModel();
    model.search = this.filter;
    model.startDate = this.plannedDateEnd;
    model.endDate = this.plannedDateStart;
    this.servicesService
      .getAllServicesPlan(model)
      .subscribe((res: TaskModel[]) => {
        if (res) {
          this.servicesTotal = res;
          this.services = res;
          this.servicesSearch = res;

          this.collectionSize = res.length;
          if (this.sortColumn != undefined) {
            setTimeout(() => {
              this.page =
                +this.activatedRoute.snapshot.queryParamMap.get('page');

              this.onSort({
                column: this.sortColumn,
                direction: this.sortDirection == 'desc' ? 'desc' : 'asc',
              });
            }, 1000);
          } else {
            this.onSort({ column: 'plannedDate', direction: 'desc' });
          }
          // if (this.plannedDate != '') {
          //   setTimeout(() => {
          //     this.page =
          //       +this.activatedRoute.snapshot.queryParamMap.get('page');
          //     this.somethingChanged2();
          //     console.log(this.page);
          //   }, 1000);
          // }

          setTimeout(() => {
            this.print();
          }, 4000);
        }
      });
  }

  somethingChanged2() {
    // this.servicesSearch = this.servicesSearch.filter((t) => {
    //   return (
    //     t.plannedDate.toLowerCase().trim().includes(this.plannedDate) ||
    //     this.plannedDate == ''
    //   );
    // });
    this.servicesSearch = this.servicesSearch.filter((t) => {
      const term = this.filter.toLowerCase();

      return (
        t.no.toLowerCase().trim().includes(term) ||
        t.modelName.toLowerCase().trim().includes(term) ||
        t.operationNo.toString().toLowerCase().trim().includes(term) ||
        this.filter == ''
      );
    });
    this.collectionSize = this.servicesSearch.length;
  }
  somethingChanged() {
    // if (this.plannedDate != '') {
    //   const dS = this.plannedDate.split('-');
    //   this.plannedDate = dS[2] + '-' + dS[1] + '-' + dS[0];
    //   console.log(this.plannedDate);
    // }
    this.servicesSearch = this.servicesTotal.filter((t) => {
      return (
        t.plannedDate.toLowerCase().trim().includes(this.plannedDate) ||
        this.plannedDate == ''
      );
    });
    this.servicesSearch = this.servicesSearch.filter((t) => {
      const term = this.filter.toLowerCase();

      return (
        t.no.toLowerCase().trim().includes(term) ||
        t.modelName.toLowerCase().trim().includes(term) ||
        t.operationNo.toString().toLowerCase().trim().includes(term) ||
        this.filter == ''
      );
    });
    this.collectionSize = this.servicesSearch.length;
    this.onSort({
      column: this.sortColumn,
      direction: this.sortDirection == 'desc' ? 'desc' : 'asc',
    });
  }

  onSort({ column, direction }: SortEvent) {
    console.log('sort');
    this.sortColumn = column.toString();
    this.sortDirection = direction;
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.servicesSearch = this.servicesSearch;
    } else {
      this.servicesSearch = [...this.servicesSearch].sort((a, b) => {
        if (column.toString().includes('date')) {
          let ca = new Date(a[column]);
          let cb = new Date(b[column]);
          const res = compare(ca, cb);
          return direction === 'asc' ? res : -res;
        } else {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        }
      });
      // this.tasks2 = this.tasks;
    }
  }

  openModal() {
    this.display = 'block';
  }

  onCloseHandled() {
    this.display = 'none';
  }
  print() {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    style.appendChild(
      document.createTextNode('@page { size:  landscape; margin: 0in;}')
    );

    head.appendChild(style);
    window.print();
  }
}
