import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  ReviewCustomerPropertyModel,
  ReviewProjectModel,
  ServicesService,
  TaskModel,
} from 'src/app/core/laboratory';
import {
  NgbdSortableHeader,
  SortEvent,
} from '../print-task-planning-list/print-task-planning-list.component';

const compare = (v1: any, v2: any) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
@Component({
  selector: 'app-print-customer-properties-list',
  templateUrl: './print-customer-properties-list.component.html',
  styleUrls: ['./print-customer-properties-list.component.scss'],
})
export class PrintCustomerPropertiesListComponent implements OnInit {
  reviewCustomerPropertysTotal: Array<ReviewCustomerPropertyModel> = [];
  reviewCustomerPropertys: Array<ReviewCustomerPropertyModel> = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  reviewCustomerPropertysSearch: Array<ReviewCustomerPropertyModel> = [];

  page = 1;
  pageSize = 10;
  collectionSize = 4;
  filter = '';
  sortColumn = '';
  sortDirection = '';
  constructor(
    private servicesService: ServicesService,
    private datePipe: DatePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.sortColumn =
      this.activatedRoute.snapshot.queryParamMap.get('sortColumn');
    this.sortDirection =
      this.activatedRoute.snapshot.queryParamMap.get('sortDirection');
    //   this.filter = this.activatedRoute.snapshot.queryParamMap.has('s')

    this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');

    this.getDataTable();
  }

  /**
   * get data table
   */
  getDataTable() {
    this.servicesService
      .getAllReviewCustomerProperties()
      .subscribe((res: ReviewCustomerPropertyModel[]) => {
        if (res) {
          this.reviewCustomerPropertysTotal = res;
          this.reviewCustomerPropertys = res;
          this.reviewCustomerPropertysSearch = res;

          this.collectionSize = res.length;
          console.log(this.page);
          const id = this.activatedRoute.snapshot.queryParams['id'];
          const s = this.activatedRoute.snapshot.queryParams['s'];
          if (this.sortColumn != undefined) {
            setTimeout(() => {
              this.onSort({
                column: this.sortColumn,
                direction: this.sortDirection == 'desc' ? 'desc' : 'asc',
              });
              setTimeout(() => {
                this.page =
                  +this.activatedRoute.snapshot.queryParamMap.get('page');
                this.somethingChanged2();
                console.log(this.page);
              }, 1000);
            }, 1000);
          }
          if (id) {
            setTimeout(() => {
              this.filter = id;
              this.somethingChanged2();
            }, 1000);
          } else if (s) {
            setTimeout(() => {
              this.filter = s;
              this.somethingChanged2();
            }, 1000);
          }
          this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');
        }
        setTimeout(() => {
          this.print();
        }, 4000);
      });
  }

  onSort({ column, direction }: SortEvent) {
    this.sortColumn = column.toString();
    this.sortDirection = direction;
    console.log('sort');
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.reviewCustomerPropertysSearch = this.reviewCustomerPropertysSearch;
    } else {
      // this.reviewCustomerPropertysSearch = [...this.equipmentsTotal].sort((a, b) => {
      //   const res = compare(a[column], b[column]);
      //   return direction === 'asc' ? res : -res;
      // });
      if (column.toString().includes('date')) {
        this.reviewCustomerPropertysSearch = [
          ...this.reviewCustomerPropertysSearch,
        ].sort((a, b) => {
          let ca = new Date(a[column]).getTime();
          let cb = new Date(b[column]).getTime();
          const res = compare(ca, cb);
          return direction === 'asc' ? res : -res;
        });
      } else {
        this.reviewCustomerPropertysSearch = [
          ...this.reviewCustomerPropertysSearch,
        ].sort((a, b) => {
          var nameA = a[column] ? a[column].toLowerCase() : '',
            nameB = b[column] ? b[column].toLowerCase() : '';
          let res = -1;
          if (nameA < nameB)
            //sort string ascending
            res = -1;
          if (nameA > nameB) res = 1;
          return direction === 'asc' ? res : -res;
        });
      }
    }
  }
  somethingChanged2() {
    if (this.filter != '') {
      this.reviewCustomerPropertysSearch =
        this.reviewCustomerPropertysSearch.filter((t) => {
          const term = this.filter.toLowerCase();

          let no = t.no;
          let doNo = t.doNo ? t.doNo : '';
          let grnNo = t.grnNo ? t.grnNo : '';

          return (
            no.toLowerCase().trim().includes(term) ||
            doNo.toLowerCase().trim().includes(term) ||
            grnNo.toLowerCase().trim().includes(term)
          );
        });
      this.collectionSize = this.reviewCustomerPropertysSearch.length;
    }
  }
  somethingChanged() {
    if (this.filter != '') {
      this.reviewCustomerPropertysSearch =
        this.reviewCustomerPropertysTotal.filter((t) => {
          const term = this.filter.toLowerCase();

          let no = t.no;
          let doNo = t.doNo ? t.doNo : '';
          let grnNo = t.grnNo ? t.grnNo : '';

          return (
            no.toLowerCase().trim().includes(term) ||
            doNo.toLowerCase().trim().includes(term) ||
            grnNo.toLowerCase().trim().includes(term)
          );
        });
      this.collectionSize = this.reviewCustomerPropertysSearch.length;
    }
  }

  getArrangement(id: number): string {
    let name = '';

    if (id == 1) {
      name = 'Return ส่งคืน';
    } else if (id == 2) {
      name = 'Discard จำหน่าย';
    }

    return name;
  }
  print() {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    style.appendChild(
      document.createTextNode('@page { size:  landscape; margin: 0in;}')
    );

    head.appendChild(style);
    window.print();
  }
}
