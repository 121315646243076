<div class="divFooter">

  <div class="page__content" *ngIf="lab">
    <div class="grid__container">

      <div class="row">
        <div class="col-6">
          <div class="main__left">
            <div class="logo__main__left">
              <img class="logo__main" src="assets/images/lic-logo02.jpg" style="border-right: 2px solid #000;" />
              <img class="logo__main" src="assets/images/LE-lighting-logo.png" style="height: 40px; margin-left: 14px;" />
            </div>

            <div class="top__address">
              <label class="label__text">Lighting Innovation Center Laboratory</label>
              <label class="label__text">L&E Manufacturing Co., Ltd.</label>
              <label class="label__text">85/3 Soi Prachumporn, Chaengwattana Rd.,</label>
              <label class="label__text">Thungsonghong, Laksi,</label>
              <label class="label__text">Bangkok 10210 Thailand.</label>
              <label class="label__text">Tel : +662 573 4787 Ext. 300, +668 8 227 8710</label>
              <label class="label__text">E-mail : lab-lem@lighting.co.th, lab.lighting@gmail.com</label>
              <label class="label__text">Website : www.licthailand.com</label>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="lab.testStandardId == 11 ||lab.reviewProject.accreditation == 1">
          <div class="main__rigth">
            <div class="logo__main__rigth">
              <img class="logo__main" src="assets/images/S__4686093.jpg" />
              <!-- <img class="logo__main" src="assets/images/NC-testing-222.png" /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">Test Certificate</label>
            <hr>
            <label class="label__text">
              {{ certificateLab.certifierName }}
            </label>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">Certificate No.</label>
            <hr>
            <label class="label__text">
              {{ certificateLab.certNo }}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">Customer Information</label>
            <hr>
            <label class="label__text" [innerHTML]="certificateLab.serviceApplicant"></label>
            <!-- <label class="label__text">16-17 Floor, Gypsum Metropolitan Tower</label>
            <label class="label__text">539/2 Sri-Ayudhya Rd., Rajthevee,</label>
            <label class="label__text">Bangkok. 10400, Thailand.</label>
            <label class="label__text">Tel : +662 248-8133, Fax : +662 248-8144</label> -->
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">Test Sample</label>
            <hr>
            <div class="top__address" [innerHTML]="certificateLab.certDetail"></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">Sample Received Date</label>
            <hr>
            <label class="label__text">{{ certificateLab.pickupDateTime | date:'dd MMMM yyyy' }}</label>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">Test Standard</label>
            <hr>
            <label class="label__text">{{ certificateLab.certMethod }}</label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">Test Date</label>
            <hr>
            <label class="label__text">{{ certificateLab.testDateTime  }}</label>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">Sample characteristic and condition</label>
            <hr>
            <label class="label__text">{{ certificateLab.certCondition }}</label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">Issue Date</label>
            <hr>
            <label class="label__text">{{ certificateLab.reportDateTime | date:'dd MMMM yyyy' }}</label>
          </div>

          <div class="row__content">
            <label class="title__content licent__title">Tested by :</label>
            <img [src]="nameTested(certificateLab.certTest )" *ngIf="nameTested(certificateLab.certTest) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;bottom: 80px;">
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.certTest }} )</label>
              <label class="label__text">
                ( {{certificateLab.certTestDateString}} )
              </label>
            </div>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">Test Result</label>
            <hr>
            <label class="label__text">
              {{ certificateLab.certResult }}
            </label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content licent__title">Approved by :</label>
            <img [src]="nameTested(certificateLab.testName )" *ngIf="nameTested(certificateLab.testName) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;top: -20px;">
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.testName }} )</label>
              <label class="label__text">
                ( {{certificateLab.testNameDateString}} )
              </label>
            </div>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content licent__title">Certified by :</label>
            <img [src]="nameTested(certificateLab.inspectorName )" *ngIf="nameTested(certificateLab.inspectorName) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;top: -20px;">
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.inspectorName }} )</label>
              <label class="label__text" style="margin-bottom: 16px;">{{ certificateLab.inspectorNamePosition}}</label>
              <label class="label__text">
                ( {{certificateLab.inspectorNameDateString}} )
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12 footter__content">
          <label class="title__footter">
            This certificate is certified only on the tested sample. Reproducing some part of the report without permission from LABORATORY is prohibited.
            <br />LABORATORY shall not be responsible for any errors originated from customer’s information.
          </label>
        </div>
      </div>

      <div class="footter__page">
        <div>
          <label class="label__text">
            RC-LAB7.8-00E / Rev.2 / 15/02/67
          </label>
        </div>
        <div class="display__page">
          <label class="label__text footter__page">
            Page
          </label>
          <label class="label__text footter__page">
            {{ certificateLab.page }}
          </label>
          <label class="label__text footter__page">
            of
          </label>
          <label class="label__text footter__page">
            {{ certificateLab.pageOf }}
          </label>
        </div>
      </div>

    </div>
  </div>

</div>
