import { ca } from 'date-fns/locale';

export class TestStandardModel {
  id: number;
  code: string;
  value: string;
  name: string;
  detail: string;
  usingDateTime: string;
  servicePrice: number;
  operationNo: number;
  testTopics: Array<TestTopicModel> = [];
  creationDateTime: string;
  disableDateTime: string;
  lastUpdateDateTime: string;
  isDisable: boolean;
  isComplete: boolean;
  disableComment: string;
  equipmentTestStandards: Array<EquipmentTestStandardModale> = [];

  clear() {
    this.id = 0;
    this.code = '';
    this.value = '';
    this.name = '';
    this.detail = '';
    this.usingDateTime = '';
    this.lastUpdateDateTime = '';
    this.disableDateTime = '';
    this.servicePrice = null;
    this.operationNo = null;
    this.testTopics = [];
    this.creationDateTime = '2021-01-01';
    this.isDisable = false;
    this.isComplete = false;
    this.disableComment = '';
    this.equipmentTestStandards = [];
  }
}

export class EquipmentTestStandardModale {
  equipmentId: number;
  testStandardId: number;

  claer() {
    this.equipmentId = 0;
    this.testStandardId = 0;
  }
}

export class TestTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  testStandardId: number;
  creationDateTime: string;
  testStandard: string;

  clear() {
    this.id = 0;
    this.value = '';
    this.name = '';
    this.detail = '';
    this.creationDateTime = '';
    this.testStandard = '';
  }
}

export class SampleTypeModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class LightsourceTypeModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class ControlGearTypeModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class DesignSimulationTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class PatentTrademarkTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class ProductRegistrationSupportTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class RapidPrototypingTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class MaterialResolutionModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class MaintenanceModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class ReviewTestMethodTopicModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class EquipmentMaintenanceRepairModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class WorkAreaModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}

export class StandardModel {
  id: number;
  value: string;
  name: string;
  detail: string;
  creationDateTime: string;
}
