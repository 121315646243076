import { VerificationInspectionIcModel } from 'src/app/core/management-system';
import { CalendarModule } from 'angular-calendar';
import { CalibrationModel, MaintenanceRepairModel } from '..';
import { UserModel } from './../../authen/_models/authen.model';
import {
  WorkAreaModel,
  StandardModel,
  TestStandardModel,
  MaintenanceModel,
} from './../../laboratory/_models/option.model';
export class LabEquipmentModel {
  labName: string;
  standardName: string;
  model: string;
  operationNo: string;
  operator: string;
  startDateString: string;
  endDateString: string;
  startDate: string;
  endDate: string;
  equipmentId: number;
  equipment: EquipmentModel;
  name: string;
  checkDate: string;
  editText: string;
  isUsing: boolean;
  isOther: boolean;
}
export class EquipmentModel {
  workArea: WorkAreaModel;
  responsiblePerson: UserModel;
  receiveDate: string;
  standard: StandardModel;
  equipmentTestStandards: Array<TestStandardModel> = [];
  equipmentMaintenances: Array<MaintenanceModel> = [];
  equipmentInprocessLaboratories: string;

  userCreateId: number;
  userCreate: UserModel;
  userEditId: number;
  userEdit: UserModel;

  isComplete: boolean;
  completeDateTime: string;
  userCompleteId: number;
  userComplete: UserModel;
  userDiscardedId: number;
  userDiscarded: UserModel;
  discardedDateTime: string;

  creationDateTime: string;
  lastUpdateDateTime: string;
  calibrationNextDateTime: string;
  calibrationDateTime: string;
  calibrationDateTimeString: string;
  lastCorrectionFactor: string;

  maintenanceRepairRecords: Array<MaintenanceRepairModel> = [];
  calibrationRecords: Array<CalibrationModel> = [];
  verifications: Array<VerificationInspectionIcModel> = [];

  id: number;
  no: string;
  name: string;
  model: string;
  distributor: string;
  sn: string;
  code: string;
  detail: string;
  skill: string;

  fileManual1: File;
  attachFileManual1: string;
  fileManual2: File;
  attachFileManual2: string;
  fileManual3: File;
  attachFileManual3: string;
  fileManual4: File;
  attachFileManual4: string;
  fileManual5: File;
  attachFileManual5: string;
  fileImage: File;
  attachFileImage: string;

  filePO1: File;
  attachFilePO1: string;
  filePO2: File;
  attachFilePO2: string;
  filePO3: File;
  attachFilePO3: string;
  filePO4: File;
  attachFilePO4: string;
  filePO5: File;
  attachFilePO5: string;

  workAreaId: number;
  division: string;
  responsiblePersonId: number;
  responsiblePersonName: string;
  action: number;
  standardId: number;
  c: number;
  v: number;
  ic: number;
  detail2: string;
  range1: string;
  range2: string;
  range3: string;
  range4: string;
  range5: string;
  range6: string;
  range7: string;
  range8: string;
  range9: string;
  range10: string;
  range11: string;
  range12: string;
  criterion1: string;
  criterion2: string;
  criterion3: string;
  criterion4: string;
  criterion5: string;
  criterion6: string;
  criterion7: string;
  criterion8: string;
  criterion9: string;
  criterion10: string;
  criterion11: string;
  criterion12: string;
  equipmentTestStandardIds: Array<number> = [];
  equipmentMaintenanceIds: Array<number> = [];
  maintenance1: string;
  maintenance2: string;
  maintenance3: string;
  maintenance4: string;
  maintenance5: string;
  maintenance6: string;
  maintenance7: string;
  maintenance8: string;
  maintenance9: string;
  maintenance10: string;
  maintenance11: string;
  maintenance12: string;
  maintenance13: string;
  maintenance14: string;
  maintenance15: string;
  maintenance16: string;
  maintenance17: string;
  maintenance18: string;
  maintenance19: string;
  maintenance20: string;
  maintenanceFrequency1: number;
  maintenanceFrequency2: number;
  maintenanceFrequency3: number;
  isDiscarded: boolean;
  discardedDetail: string;

  file_manual_arr: Array<File> = [];
  file_po_arr: Array<File> = [];
  maNextDateTime: string;
  icNextDateTime: string;
  vNextDateTime: string;

  // clear() {
  //   this.workArea = new WorkAreaModel();
  //   this.responsiblePerson = new UserModel();
  //   this.standard = new StandardModel();
  //   this.equipmentTestStandards = [];
  //   this.equipmentMaintenances = [];
  //   this.equipmentInprocessLaboratories = '';

  //   this.userCreateId = 0;
  //   this.userCreate = new UserModel();
  //   this.userEditId = 0;
  //   this.userEdit = new UserModel();

  //   this.isComplete = false;
  //   this.userCompleteId = 0;
  //   this.userComplete = new UserModel();
  //   this.completeDateTime = '';
  //   this.userDiscardedId = 0;
  //   this.userDiscarded = new UserModel();
  //   this.discardedDateTime = '';

  //   this.creationDateTime = '';
  //   this.lastUpdateDateTime = '';

  //   this.id = 0;
  //   this.no = '';
  //   this.name = '';
  //   this.model = '';
  //   this.distributor = '';
  //   this.detail = '';
  //   this.skill = '';
  //   this.fileManual1 = null;
  //   this.attachFileManual1 = '';
  //   this.fileManual2 = null;
  //   this.attachFileManual2 = '';
  //   this.fileManual3 = null;
  //   this.attachFileManual3 = '';
  //   this.fileManual4 = null;
  //   this.attachFileManual4 = '';
  //   this.fileManual5 = null;
  //   this.attachFileManual5 = '';
  //   this.filePO1 = null;
  //   this.attachFilePO1 = '';
  //   this.filePO2 = null;
  //   this.attachFilePO2 = '';
  //   this.filePO3 = null;
  //   this.attachFilePO3 = '';
  //   this.filePO4 = null;
  //   this.attachFilePO4 = '';
  //   this.filePO5 = null;
  //   this.attachFilePO5 = '';
  //   this.workAreaId = 0;
  //   this.division = '';
  //   this.responsiblePersonId = 0;
  //   this.action = 0;
  //   this.standardId = 0;
  //   this.c = 0;
  //   this.v = 0;
  //   this.ic = 0;
  //   this.detail2 = '';
  //   this.range1 = '';
  //   this.range2 = '';
  //   this.range3 = '';
  //   this.criterion1 = '';
  //   this.criterion2 = '';
  //   this.criterion3 = '';
  //   this.equipmentTestStandardIds = [];
  //   this.equipmentMaintenanceIds = [];
  //   this.maintenance1 = '';
  //   this.maintenance2 = '';
  //   this.maintenance3 = '';
  //   this.maintenanceFrequency1 = 0;
  //   this.maintenanceFrequency2 = 0;
  //   this.maintenanceFrequency3 = 0;
  //   this.isDiscarded = false;
  //   this.discardedDetail = '';
  // }
}
