<div class="divFooter">

  <div class="page__content" *ngIf="lab">
    <div class="grid__container">

      <div class="row">
        <div class="col-6">
          <div class="main__left">
            <div class="logo__main__left">
              <img class="logo__main" src="assets/images/lic-logo02.jpg" style="border-right: 2px solid #000;" />
              <img class="logo__main" src="assets/images/LE-lighting-logo.png" style="height: 40px; margin-left: 14px;" />
            </div>

            <div class="top__address">
              <label class="label__text">ห้องปฏิบัติการทดสอบ ศูนย์นวัตกรรมผลิตภัณฑ์ไฟฟ้าแสงสว่าง</label>
              <label class="label__text">บริษัท แอล แอนด์ อี แมนูแฟคเจอริ่ง จำกัด</label>
              <label class="label__text">85/3 ซอยประชุมพร ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง</label>
              <label class="label__text">เขตหลักสี่ กรุงเทพฯ 10210</label>
              <label class="label__text">โทรศัพท์ : +662 573 4787 ต่อ 300, +668 8 227 8710</label>
              <label class="label__text">อีเมล : lab-lem@lighting.co.th, lab.lighting@gmail.com</label>
              <label class="label__text">เว็บไซต์ : www.licthailand.com</label>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="lab.testStandardId == 11 ||lab.reviewProject.accreditation == 1">
          <div class="main__rigth">
            <div class="logo__main__rigth">
              <img class="logo__main" src="assets/images/S__4686093.jpg" />
              <!-- <img class="logo__main" src="assets/images/NC-testing-222.png" /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">ใบรับรองรายงานผลการทดสอบ</label>
            <hr>
            <label class="label__text">
              {{ certificateLab.certifierName }}
            </label>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">หมายเลขใบรับรอง</label>
            <hr>
            <label class="label__text">
              {{ certificateLab.certNo }}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">ชื่อ-ที่อยู่ผู้ขอรับบริการ</label>
            <hr>
            <label class="label__text" [innerHTML]="certificateLab.serviceApplicant"></label>
            <!-- <label class="label__text">16-17 Floor, Gypsum Metropolitan Tower</label>
            <label class="label__text">539/2 Sri-Ayudhya Rd., Rajthevee,</label>
            <label class="label__text">Bangkok. 10400, Thailand.</label>
            <label class="label__text">Tel : +662 248-8133, Fax : +662 248-8144</label> -->
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">รายละเอียดตัวอย่าง</label>
            <hr>
            <div class="top__address" [innerHTML]="certificateLab.certDetail"></div>
            <!-- <label class="label__text">{{lab.sampleType.name}}</label>
            <label class="label__text">{{lab.lightsourceType.name}}</label>
            <label class="label__text">Trademark : {{lab.trademark}}</label>
            <label class="label__text">Model : {{lab.model}}</label>
            <label class="label__text">{{lab.quantity}}</label> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">วันที่รับตัวอย่าง</label>
            <hr>
            <label class="label__text">{{ certificateLab.pickupDateTime | date:'dd' }} {{pickupDateTimeThaiMonth}} {{ this.pickupDateTimeThaiY }}</label>

          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">มาตรฐานทดสอบ</label>
            <hr>
            <label class="label__text">{{ certificateLab.certMethod }}</label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">วันที่ทดสอบ</label>
            <hr>
            <label class="label__text">{{ certificateLab.testDateTime }}</label>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">ลักษณะและสภาพตัวอย่างทดสอบ</label>
            <hr>
            <label class="label__text">{{ certificateLab.certCondition }}</label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content">วันที่ออกรายงานผลการทดสอบ</label>
            <img [src]="nameTested(certificateLab.certTest )" *ngIf="nameTested(certificateLab.certTest) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;bottom: 80px;">
            <hr>
            <label class="label__text">{{ certificateLab.reportDateTime | date:'dd' }} {{reportDateTimeThaiMonth}} {{ reportDateTimeThaiY }}</label>

          </div>

          <div class="row__content">
            <label class="title__content licent__title">ผู้ทดสอบ :</label>
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.certTest }} )</label>
              <label class="label__text">
                ( {{certificateLab.certTestDateString}} )
              </label>
            </div>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content">ผลการทดสอบ</label>

            <hr>
            <label class="label__text">
              {{ certificateLab.certResult }}
            </label>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-6 column__left">
          <div class="row__content">
            <label class="title__content licent__title">ผู้ตรวจสอบ :</label>
            <img [src]="nameTested(certificateLab.testName )" *ngIf="nameTested(certificateLab.testName) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;top: -20px;">
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.testName }} )</label>
              <label class="label__text">
                ( {{certificateLab.testNameDateString}} )
              </label>
            </div>
          </div>
        </div>

        <div class="col-6 column__right">
          <div class="row__content">
            <label class="title__content licent__title">ผู้รับรอง :</label>
            <img [src]="nameTested(certificateLab.inspectorName )" *ngIf="nameTested(certificateLab.inspectorName) != ''" height="55px" style=" position: absolute;    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;top: -20px;">
            <hr>
            <div class="licent">
              <label class="label__text__licent">( {{ certificateLab.inspectorName }} )</label>
              <label class="label__text" style="margin-bottom: 16px;">{{certificateLab.inspectorNamePosition}}</label>
              <label class="label__text">
                ( {{certificateLab.inspectorNameDateString}} )
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12 footter__content">
          <label class="title__footter">
            ใบรับรองฉบับนี้ รับรองผลเฉพาะตัวอย่างที่ได้รับการทดสอบเท่านั้น ห้ามคัดถ่ายผลการทดสอบเพียงบางส่วน โดยไม่ได้รับอนุญาตจากห้องปฏิบัติการทดสอบ
            <br />ห้องปฏิบัติการจะไม่รับผิดชอบต่อความผิดพลาดของผลการทดสอบที่อาจเกิดขึ้นโดยมีสาเหตุจากข้อมูลที่ได้มาจากลูกค้า
          </label>
        </div>
      </div>

      <div class="footter__page">
        <div>
          <label class="label__text">
            RC-LAB7.8-00T / Rev.2 / 15/02/67
          </label>
        </div>
        <div class="display__page">
          <label class="label__text footter__page">
            หน้าที่
          </label>
          <label class="label__text footter__page">
            {{ certificateLab.page }}
          </label>
          <label class="label__text footter__page">
            ของ
          </label>
          <label class="label__text footter__page">
            {{ certificateLab.pageOf }}
          </label>
        </div>
      </div>

    </div>
  </div>

</div>
