import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Model
import {
  LaboratoryModel,
  PaymentProjectModel,
  ReviewCustomerPropertyModel,
  ReviewProjectModel,
  SubmissionLabModel,
  TaskModel,
  TestCertificateModel,
} from '../index';
import { SearchModel } from '../_models/task.model';
import { EvaluationRecordUploadFile } from '../../management-system/_models/calibration.model';

const API_SERVICE_URL = environment.API_BASE_URL + '/api/Services';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  // READ
  getAllReviewCustomerProperties(): Observable<ReviewCustomerPropertyModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/ReviewCustomerProperties`;
    return this.http.get<ReviewCustomerPropertyModel[]>(_url, {
      headers: httpHeaders,
    });
  }

  // READ
  getAllReviewCustomerPropertyById(
    id: number
  ): Observable<ReviewCustomerPropertyModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/ReviewCustomerProperty/${id}`;
    return this.http.get<ReviewCustomerPropertyModel>(_url, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  updateReviewCustomerProperty(
    model: ReviewCustomerPropertyModel
  ): Observable<ReviewCustomerPropertyModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/ReviewCustomerProperty`;
    return this.http.put<ReviewCustomerPropertyModel>(_url, model, {
      headers: httpHeaders,
    });
  } // READ
  updateReviewCustomerPropertyFile(
    id: number,
    file: File
  ): Observable<ReviewCustomerPropertyModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('File', file ? file : '');

    const _url = API_SERVICE_URL + `/ReviewCustomerProperty/File/${id}`;
    return this.http.put<ReviewCustomerPropertyModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // READ
  updateReviewCustomerPropertyFinish(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/ReviewCustomerProperty/Finish/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // READ
  getAllServices(model: SearchModel): Observable<TaskModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL;
    return this.http.post<TaskModel[]>(_url, model, {
      headers: httpHeaders,
    });
  }

  getAllServicesPlan(model: SearchModel): Observable<TaskModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + '/AllServicesSearch';
    return this.http.post<TaskModel[]>(_url, model, {
      headers: httpHeaders,
    });
    // return this.http.get<TaskModel[]>(_url, { headers: httpHeaders });
  }
  // READ
  updateLaboratoryApply(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/Review/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // CREATE => POST: add a new to the server
  createReviewProject(
    model: ReviewProjectModel
  ): Observable<ReviewProjectModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/ReviewProject`;
    return this.http.post<ReviewProjectModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // CREATE => POST: add a new to the server
  updateReviewProject(
    model: ReviewProjectModel
  ): Observable<ReviewProjectModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/ReviewProject/${model.id}`;
    return this.http.put<ReviewProjectModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  getAllReviewProjectById(
    serviceId: number,
    laboratoryId: number
  ): Observable<ReviewProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url =
      API_SERVICE_URL + `/ReviewProject/${serviceId}/${laboratoryId}`;
    return this.http.get<ReviewProjectModel>(_url, { headers: httpHeaders });
  }

  // READ
  updateInProcessRecord(
    serviceId: number,
    laboratoryId: number
  ): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url =
      API_SERVICE_URL + `/InProcessRecord/${serviceId}/${laboratoryId}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // READ
  createTestCertificate(
    model: TestCertificateModel
  ): Observable<TestCertificateModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/TestCertificate`;
    return this.http.post<TestCertificateModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  updateTestCertificate(
    model: TestCertificateModel
  ): Observable<TestCertificateModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/TestCertificate`;
    return this.http.put<TestCertificateModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  createSubmission(model: SubmissionLabModel): Observable<SubmissionLabModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/Submission`;
    return this.http.post<SubmissionLabModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  updateSubmission(model: SubmissionLabModel): Observable<SubmissionLabModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/Submission/${model.id}`;
    return this.http.put<SubmissionLabModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  updateTaskSubmission(serviceId: number, id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/TaskSubmission/${serviceId}/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // READ
  updatePaymentProject(
    model: PaymentProjectModel
  ): Observable<PaymentProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PaymentProject/${model.id}`;
    return this.http.put<PaymentProjectModel>(_url, model, {
      headers: httpHeaders,
    });
  }

  // READ
  updatePaymentProjectMethod(
    model: PaymentProjectModel
  ): Observable<PaymentProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append(
      'grandTotal',
      model.grandTotal ? model.grandTotal.toString() : null
    );
    formData.append('recipient', model.recipient ? model.recipient : '');
    formData.append(
      'recipientDateTime',
      model.recipientDateTime ? model.recipientDateTime : ''
    );
    formData.append('method', model.method ? model.method.toString() : null);
    formData.append('methodNo', model.methodNo ? model.methodNo : '');
    formData.append('receviver', model.receviver ? model.receviver : '');
    formData.append(
      'methodAttachFile2',
      model.methodAttachFile2 ? model.methodAttachFile2 : ''
    );
    formData.append(
      'methodAttachFile1',
      model.methodAttachFile1 ? model.methodAttachFile1 : ''
    );
    formData.append(
      'methodAttachFile3',
      model.methodAttachFile3 ? model.methodAttachFile3 : ''
    );
    formData.append(
      'methodAttachFile4',
      model.methodAttachFile4 ? model.methodAttachFile4 : ''
    );
    formData.append(
      'methodAttachFile5',
      model.methodAttachFile5 ? model.methodAttachFile5 : ''
    );
    formData.append('invoiceNo', model.invoiceNo ? model.invoiceNo : '');
    formData.append('otherDetail', model.otherDetail ? model.otherDetail : '');
    formData.append('file1', model.file1 ? model.file1 : null);
    formData.append('file2', model.file2 ? model.file2 : null);
    formData.append('file3', model.file3 ? model.file3 : null);
    formData.append('file4', model.file4 ? model.file4 : null);
    formData.append('file5', model.file5 ? model.file5 : null);

    const _url = API_SERVICE_URL + `/PaymentProject/Method/${model.id}`;
    return this.http.put<PaymentProjectModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // READ
  updatePaymentProjectRecipient(
    model: PaymentProjectModel
  ): Observable<PaymentProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append(
      'grandTotal',
      model.grandTotal ? model.grandTotal.toString() : null
    );
    formData.append('recipient', model.recipient ? model.recipient : '');
    formData.append(
      'recipientDateTime',
      model.recipientDateTime ? model.recipientDateTime : ''
    );
    formData.append('file1', model.file1 ? model.file1 : null);
    formData.append('file2', model.file2 ? model.file2 : null);
    formData.append('file3', model.file3 ? model.file3 : null);
    formData.append('file4', model.file4 ? model.file4 : null);
    formData.append('file5', model.file5 ? model.file5 : null);

    const _url = API_SERVICE_URL + `/PaymentProject/Recipient/${model.id}`;
    return this.http.put<PaymentProjectModel>(_url, formData, {
      headers: httpHeaders,
    });
  }

  // READ
  getPaymentProjectById(
    serviceId: number,
    projectId: number
  ): Observable<PaymentProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PaymentProject/${serviceId}/${projectId}`;
    return this.http.get<PaymentProjectModel>(_url, { headers: httpHeaders });
  }
  // READ
  getPaymentProjectById2(id: number): Observable<PaymentProjectModel> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PaymentProject2/${id}`;
    return this.http.get<PaymentProjectModel>(_url, { headers: httpHeaders });
  }
  // READ
  editPaymentProject(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/editPaymentProject/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }
  // READ
  updatePaymentProjectRecipientConfirm(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/PaymentProject/RecipientConfirm/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // READ
  updatePaymentProjectFinishMethod(id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/PaymentProject/FinishMethod/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  }

  // READ
  getAllPaymentProject(): Observable<PaymentProjectModel[]> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    const _url = API_SERVICE_URL + `/PaymentProject`;
    return this.http.get<PaymentProjectModel[]>(_url, { headers: httpHeaders });
  }

  updateStart(serviceId: number, id: number): Observable<any> {
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'application/json');

    let body = [];

    const _url = API_SERVICE_URL + `/Start/${serviceId}/${id}`;
    return this.http.put<any>(_url, body, { headers: httpHeaders });
  } // CREATE => POST: add a new to the server
  approveSubmission1(
    model: SubmissionLabModel
  ): Observable<SubmissionLabModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());

    formData.append('File1', model.file1);
    formData.append('TestDate1', model.testDate1);
    formData.append('test1Id', model.test1Id.toString());

    const _url = API_SERVICE_URL + `/Submission/Approve1/${model.id}`;
    return this.http.put<SubmissionLabModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
  approveSubmission2(
    model: SubmissionLabModel
  ): Observable<SubmissionLabModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());

    formData.append('file2', model.file2);
    formData.append('TestDate2', model.testDate2);
    formData.append('test2Id', model.test2Id.toString());

    const _url = API_SERVICE_URL + `/Submission/Approve2/${model.id}`;
    return this.http.put<SubmissionLabModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
  approveCer1(testNameDate: string, id: number): Observable<any> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', id.toString());

    formData.append('File', null);
    formData.append('testNameDate', testNameDate);

    const _url = API_SERVICE_URL + `/Cer/Approve1/${id}`;
    return this.http.put<any>(_url, formData, {
      headers: httpHeaders,
    });
  }
  approveCer2(inspectorNameDate: string, id: number): Observable<any> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', id.toString());

    formData.append('File', null);
    formData.append('inspectorNameDate', inspectorNameDate);

    const _url = API_SERVICE_URL + `/Cer/Approve2/${id}`;
    return this.http.put<any>(_url, formData, {
      headers: httpHeaders,
    });
  }
  approveSubmissionUpload(
    model: SubmissionLabModel
  ): Observable<SubmissionLabModel> {
    // Note: Add headers if needed (tokens/bearer)
    const userToken = localStorage.getItem(environment.authTokenKey);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${userToken}`,
    });
    httpHeaders.set('accept', '*/*');
    httpHeaders.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('id', model.id.toString());

    formData.append('File1', model.file1);
    formData.append('File2', model.file2);
    formData.append('TestDate1', model.testDate1);
    formData.append('TestDate2', model.testDate2);
    if (model.test1Id) formData.append('Test1Id', model.test1Id.toString());
    if (model.test2Id) formData.append('Test2Id', model.test2Id.toString());

    const _url = API_SERVICE_URL + `/Submission/ApproveUpload/${model.id}`;
    return this.http.put<SubmissionLabModel>(_url, formData, {
      headers: httpHeaders,
    });
  }
}
