import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LaboratoryModel,
  LaboratoryService,
  TestCertificateModel,
} from 'src/app/core/laboratory';

@Component({
  selector: 'app-crtificate-lab-paper',
  templateUrl: './crtificate-lab-paper.component.html',
  styleUrls: ['./crtificate-lab-paper.component.scss'],
})
export class CrtificateLabPaperComponent implements OnInit {
  projectId: number = 0;
  dateNow: string = '00T/09/61';
  longThaiMonth = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];
  certificateLab: TestCertificateModel = <TestCertificateModel>{};
  // thaiMonth = '';
  reportDateTimeThaiMonth = '';
  pickupDateTimeThaiMonth = '';
  pickupDateTimeThaiY = '';
  // testDateTimeThaiY = '';
  reportDateTimeThaiY = '';
  lab: LaboratoryModel;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private laboratoryService: LaboratoryService,
    private cd: ChangeDetectorRef
  ) {
    // this.dateNow = this.datePipe.transform(new Date, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const _projectId = params['projectId'];
      if (_projectId) {
        this.projectId = Number(_projectId);
      }
    });

    this.certificateLab = new TestCertificateModel();
    this.certificateLab.clear();

    this.getDataTable();
  }

  /**
   * get data table
   */
  getDataTable() {
    this.laboratoryService
      .getLaboratoryById(this.projectId)
      .subscribe((res: LaboratoryModel) => {
        if (res) {
          this.lab = res;
          console.log(res);
          this.certificateLab = res.testCertificate;
          // this.thaiMonth =
          //   this.longThaiMonth[
          //     new Date(this.certificateLab.testDateTime).getMonth()
          //   ];
          this.reportDateTimeThaiMonth =
            this.longThaiMonth[
              new Date(this.certificateLab.reportDateTime).getMonth()
            ];
          this.pickupDateTimeThaiMonth =
            this.longThaiMonth[
              new Date(this.certificateLab.pickupDateTime).getMonth()
            ];

          this.pickupDateTimeThaiY =
            new Date(this.certificateLab.pickupDateTime).getFullYear() +
            543 +
            '';
          // this.testDateTimeThaiY =
          //   new Date(this.certificateLab.testDateTime).getFullYear() + 543 + '';
          this.reportDateTimeThaiY =
            new Date(this.certificateLab.reportDateTime).getFullYear() +
            543 +
            '';
          this.cd.detectChanges();
          setTimeout(function () {
            window.print();
          }, 500);
        }
      });
  }
  nameTested(name: string): string {
    console.log(name);
    if (name.toLocaleLowerCase().includes('บริมาสพร')) {
      return '/assets/images/updatesign/1. sign korn.png';
    } else if (name.toLocaleLowerCase().includes('แซ่หย่อง')) {
      return '/assets/images/updatesign/2. sign aew.png';
    } else if (name.toLocaleLowerCase().includes('แก้วหนองแสง')) {
      return '/assets/images/updatesign/3. sign suwichai.png';
    } else if (name.toLocaleLowerCase().includes('เครือเมือง')) {
      return '/assets/images/updatesign/4. sign panusak.png';
    } else if (name.toLocaleLowerCase().includes('ศรีแก้วไสย')) {
      return '/assets/images/updatesign/5. sign Mongkol.png';
    } else if (name.toLocaleLowerCase().includes('จันไชยยศ')) {
      return '/assets/images/updatesign/6. sign kwan.png';
    } else if (name.toLocaleLowerCase().includes('ช่างกลึงเหมาะ')) {
      return '/assets/images/updatesign/7. sign kNong.png';
    } else if (name.toLocaleLowerCase().includes('กะการดี')) {
      return '/assets/images/updatesign/8. sign pkig (ผู้เสนอราคา).png';
    } else if (name.toLocaleLowerCase().includes('จุลการ')) {
      return '/assets/images/updatesign/9. Sign Sithikorn (แทนหัวหน้าห้อง).png';
    } else {
      return '';
    }
  }
}
