import { DatePipe } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from 'src/app/core/authen';
// Models
import { ServicesService, TaskModel } from 'src/app/core/laboratory';
import { SearchModel } from 'src/app/core/laboratory/_models/task.model';

export type SortColumn = keyof any | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

const compare = (v1: any, v2: any) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
  display = 'none';
  tasksTotal: TaskModel[] = [];
  tasks: TaskModel[];
  tasks2: TaskModel[] = [];
  counter = Array;
  page = 1;
  pageSize = 10;
  collectionSize = 4;
  filter = '';
  sortColumn = '';
  sortDirection = '';
  month = 12;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  constructor(
    private servicesService: ServicesService,
    private router: Router,
    private calendar: NgbCalendar,
    private datePipe: DatePipe,
    public formatter: NgbDateParserFormatter,
    private activatedRoute: ActivatedRoute
  ) {
    this.fromDate = calendar.getNext(calendar.getToday(), 'm', -6);
    this.toDate = calendar.getToday();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  ngOnInit(): void {
    this.sortColumn =
      this.activatedRoute.snapshot.queryParamMap.get('sortColumn');
    this.sortDirection =
      this.activatedRoute.snapshot.queryParamMap.get('sortDirection');
    this.filter = this.activatedRoute.snapshot.queryParamMap.has('s')
      ? this.activatedRoute.snapshot.queryParamMap.get('s')
      : '';
    this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');

    this.getDataTable();
  }
  somethingChanged() {
    // this.tasks2 = this.tasksTotal.filter((t) => {
    //   const term = this.filter.toLowerCase();

    //   return (
    //     t.no.toLowerCase().trim().includes(term) ||
    //     t.modelName.toLowerCase().trim().includes(term) ||
    //     t.operationNo.toString().toLowerCase().trim().includes(term) ||
    //     t.customer.toString().toLowerCase().trim().includes(term)
    //   );
    // });
    this.tasks2 = this.tasksTotal;
    this.collectionSize = this.tasks2.length;
    this.refreshCountries();
  }
  somethingChanged2() {
    // this.tasks2 = this.tasks2.filter((t) => {
    //   const term = this.filter.toLowerCase();

    //   return (
    //     t.no.toLowerCase().trim().includes(term) ||
    //     t.modelName.toLowerCase().trim().includes(term) ||
    //     t.operationNo.toString().toLowerCase().trim().includes(term) ||
    //     t.customer.toString().toLowerCase().trim().includes(term)
    //   );
    // });
    this.tasks2 = this.tasks2;
    this.collectionSize = this.tasks2.length;
    this.refreshCountries();
  }

  refreshCountries() {
    this.tasks = this.tasks2
      .map((country, i) => country)
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  /**
   * get data table
   */
  getDataTable() {
    const startDate = this.fromDate
      ? this.datePipe.transform(
          `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
          'yyyy-MM-dd'
        )
      : null;
    const endDate = this.toDate
      ? this.datePipe.transform(
          `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`,
          'yyyy-MM-dd'
        )
      : null;
    const model: SearchModel = new SearchModel();
    model.search = this.filter;
    model.startDate = startDate;
    model.endDate = endDate;
    this.servicesService.getAllServices(model).subscribe((res: TaskModel[]) => {
      this.tasksTotal = res;
      this.tasks = res;
      this.tasks2 = res;

      this.collectionSize = res.length;
      this.refreshCountries();
      if (this.sortColumn != undefined) {
        setTimeout(() => {
          this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');

          this.onSort({
            column: this.sortColumn,
            direction: this.sortDirection == 'desc' ? 'desc' : 'asc',
          });
          setTimeout(() => {
            this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');
            this.somethingChanged2();
            console.log(this.page);
          }, 1500);
        }, 1500);
      } else {
        // this.onSort({ column: 'lastUpdateDateTime', direction: 'desc' });
      }
      if (this.filter != '') {
        setTimeout(() => {
          this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');
          this.somethingChanged2();
          console.log(this.page);
        }, 1500);
      }
      if (this.page != 0) {
        this.page = +this.activatedRoute.snapshot.queryParamMap.get('page');
      }
    });
  }
  onSort({ column, direction }: SortEvent) {
    console.log('sort');
    this.sortColumn = column.toString();
    this.sortDirection = direction;
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.tasks2 = this.tasks2;
    } else {
      this.tasks2 = [...this.tasks2].sort((a, b) => {
        if (column.toString().includes('date')) {
          let ca = new Date(a[column]);
          let cb = new Date(b[column]);
          const res = compare(ca, cb);
          return direction === 'asc' ? res : -res;
        } else {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        }
      });
      // this.tasks2 = this.tasks;
    }
    this.refreshCountries();
  }
  /**
   * route to review
   */
  reviewService(service: TaskModel) {
    console.log(service);
    if (service.servicesName.includes('Laboratory')) {
      const url = `/pages/admin/lab-job-detail-pending/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesName.includes('Rapid Prototype')) {
      const url = `/pages/admin/os-job-detail-rapid&prototyping/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesName.includes('Design Simulation')) {
      const url = `/pages/admin/os-job-detail-design&simulation/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesName.includes('Patent Trademark')) {
      const url = `/pages/admin/os-job-detail-patents&trademarks/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesName.includes('Product Registration Support')) {
      const url = `/pages/admin/os-job-detail-equipment/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesName.includes('Other Service')) {
      const url = `/pages/admin/os-job-detail-others/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * rouet to inprocess
   */
  routeInprocess(service: TaskModel) {
    console.log(service);
    if (service.servicesId == 1) {
      if (service.testStandardOperatorNo == 1) {
        const url = `/pages/admin/activity/inprocess-laboratory/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      } else if (service.testStandardOperatorNo == 2) {
        const url = `/pages/admin/activity/inprocess-laboratory/TIS503&IEC60259/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      } else if (service.testStandardOperatorNo == 3) {
        const url = `/pages/admin/activity/inprocess-laboratory/TIS902&IEC60598/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      } else if (service.testStandardOperatorNo == 4) {
        const url = `/pages/admin/activity/inprocess-laboratory/UL1598/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      } else if (service.testStandardOperatorNo == 6) {
        const url = `/pages/admin/activity/inprocess-laboratory/test-standard/amend/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      } else if (service.testStandardOperatorNo == 5) {
        const url = `/pages/admin/activity/inprocess-laboratory/test-standard/other/${service.servicesId}/${service.id}`;
        this.router.navigate([url], {
          queryParams: {
            page: this.page,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            s: this.filter,
            url: encodeURIComponent(this.router.url.split('?')[0]),
          },
          relativeTo: this.activatedRoute,
        });
      }
    } else if (service.servicesId == 2) {
      const url = `/pages/admin/activity/inprocess-laboratory/test-standard/rp/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesId == 3) {
      const url = `/pages/admin/activity/inprocess-laboratory/test-standard/dns/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesId == 4) {
      const url = `/pages/admin/activity/inprocess-laboratory/test-standard/pnt/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesId == 5) {
      const url = `/pages/admin/activity/inprocess-laboratory/test-standard/prs/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else if (service.servicesId == 7) {
      const url = `/pages/admin/activity/inprocess-laboratory/test-standard/other-service/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    }
    // const _topic: string = service.topic.replace(/\s/g, "");

    // if (_topic.toUpperCase().includes('TIS5') || _topic.toUpperCase().includes('IEC602')) {
    //   const url = `/pages/admin/activity/inprocess-laboratory/TIS503&IEC60259/${service.servicesId}/${service.id}`;
    //   this.router.navigate([url], {
    //   queryParams: {
    //     page: this.page,
    //     sortColumn: this.sortColumn,
    //     sortDirection: this.sortDirection,
    //   },
    //   relativeTo: this.activatedRoute,
    // });
    // } else if (_topic.toUpperCase().includes('TIS9') || _topic.toUpperCase().includes('IEC605')) {
    //   const url = `/pages/admin/activity/inprocess-laboratory/TIS902&IEC60598/${service.servicesId}/${service.id}`;
    //   this.router.navigate([url], {
    //   queryParams: {
    //     page: this.page,
    //     sortColumn: this.sortColumn,
    //     sortDirection: this.sortDirection,
    //   },
    //   relativeTo: this.activatedRoute,
    // });
    // } else if (_topic.toUpperCase().includes('UL1')) {
    //   const url = `/pages/admin/activity/inprocess-laboratory/UL1598/${service.servicesId}/${service.id}`;
    //   this.router.navigate([url], {
    //   queryParams: {
    //     page: this.page,
    //     sortColumn: this.sortColumn,
    //     sortDirection: this.sortDirection,
    //   },
    //   relativeTo: this.activatedRoute,
    // });
    // } else if (_topic.toUpperCase().includes('CIE121') || _topic.toUpperCase().includes('CIES025') || _topic.toUpperCase().includes('IESLM')) {
    //   const url = `/pages/admin/activity/inprocess-laboratory/${service.servicesId}/${service.id}`;
    //   this.router.navigate([url], {
    //   queryParams: {
    //     page: this.page,
    //     sortColumn: this.sortColumn,
    //     sortDirection: this.sortDirection,
    //   },
    //   relativeTo: this.activatedRoute,
    // });
    // }
  }

  /**
   * rouet to test certificate
   */
  routeTestCertificate(service: TaskModel) {
    if (!service.reportLang) {
      const url = `/pages/admin/activity/certificate-laboratory/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    } else {
      const url = `/pages/admin/activity/certificate-laboratory-eng/${service.servicesId}/${service.id}`;
      this.router.navigate([url], {
        queryParams: {
          page: this.page,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          s: this.filter,
          url: encodeURIComponent(this.router.url.split('?')[0]),
        },
        relativeTo: this.activatedRoute,
      });
    }
    // const url = `/pages/admin/activity/certificate-laboratory/${service.servicesId}/${service.id}`;
    // this.router.navigate([url], {
    //   queryParams: {
    //     page: this.page,
    //     sortColumn: this.sortColumn,
    //     sortDirection: this.sortDirection,
    //   },
    //   relativeTo: this.activatedRoute,
    // });
  }

  /**
   * rouet to submissionlab
   */
  routeSubmissionLab(service: TaskModel) {
    const url = `/pages/admin/activity/submission-lab/${service.servicesId}/${service.id}`;
    this.router.navigate([url], {
      queryParams: {
        page: this.page,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        s: this.filter,
        url: encodeURIComponent(this.router.url.split('?')[0]),
      },
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * check display menu
   */
  checkDisplayMenu(service: TaskModel): boolean {
    let _isShow: boolean = false;
    if (service.servicesName.includes('Laboratory')) {
      _isShow = true;
    }

    return _isShow;
  }

  openModal() {
    this.display = 'block';
  }

  onCloseHandled() {
    this.display = 'none';
  }
}
